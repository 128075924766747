import React, { useState, useEffect } from "react";
import Sidebar from "../common/Sidebar";
import {
  Link,
  useLocation,
} from "react-router-dom";
import { Modal, Button, Spinner } from "react-bootstrap";
import { getCouponApi } from "../../api/api";

const Coupons = () => {
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
    const [requestId, setRequestId] = useState(() => {
    var id =  localStorage.getItem("request_id")
    return id || null
  })
  const [coupanData, setCoupanData] = useState({});
  const [copySuccess, setCopySuccess] = useState(false);
  const [promoname, setPromoName] = useState();
  const [promocode, setPromoCode] = useState();
  const [thirdParty, setThirdParty] = useState();
  const [timeleft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = (data) => {
    setShowModal(true);
    setPromoName(data.promo_name);
    setPromoCode(data.promo_code);
    setThirdParty(data.third_party);
    setTimeLeft(data.time_left);
  };

  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });

  const hideModal = () => {
    setShowModal(false);
  };

  const copyLink = (promoCode) => {
    const textField = document.createElement("textarea");
    textField.innerText = promoCode;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };
  // let field = document.querySelector('.link_input');
  //     let input = document.querySelector('input');
  //     let copyBtn = document.querySelector('Button');
  //     input.select();
  //     if (document.execCommand("copy")) {
  //     input.classList.add('active');
  //     copyBtn.innerText = 'Copied!';
  //     setTimeout(() => {
  //     input.classList.remove('active');
  //     copyBtn.innerText = 'Copy';
  //     }, 3500)
  //     }
  //   };

  var image_paths = coupanData?.Promotions?.map((data) => {
    var image_path = [];
    image_path = data.coupon_image;
    return image_path
      ? image_path.replace(
          "https://roveimage.s3.me-south-1.amazonaws.com/images",
          "https://www.riderove.com/dev_rove/uploads"
        )
      : "";
  });

  var date = new Date();
  var today = date.getTime(); // Current timestamp

  var days_left = "";
  var hours_left = "";
  var time_left = "";
  coupanData?.Promotions?.map((data) => {
    var end_date = new Date(data.end_at.split(" ")[0]+"T23:59:00");
    // var end_date = new Date(data.end_at);
    console.log(end_date)
    var end = end_date.getTime(); // End timestamp

    var difference = end - today; // Difference in milliseconds
    var milliseconds_per_day = 1000 * 60 * 60 * 24; // Milliseconds in a day
    var milliseconds_per_hour = 1000 * 60 * 60; // Milliseconds in an hour

    days_left = Math.floor(difference / milliseconds_per_day); // Calculate days left
    hours_left = Math.floor(
      (difference % milliseconds_per_day) / milliseconds_per_hour
    ); // Calculate hours left

    time_left = days_left;
    data.time_left = time_left;
    // setNoofdays(time_left)

    // console.log('Time left for promotion:', time_left);
  });

  // console.log(time_left)

  useEffect(() => {
    if (userDetail) {
      CoupanApiHandler();
    }
  }, [userDetail]);

  const CoupanApiHandler = async () => {
    try {
      const data1 = {
        user_type: userDetail.user_type || "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id || "",
      };

      const form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("customer_id", data1.user_id);

      setIsLoading(true)
      const res = await getCouponApi(form);
      const data = res.data;
      setIsLoading(false)

      if (data.status == "1") {
        setCoupanData(data);
        console.log(data);
      } else {
        console.log("Error: get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="main">
      
      <section className="section mt-40 mb-100" id="saved-palces">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className="col-md-2 col-lg-2 mb-50">
              <Sidebar pathname={location.pathname}></Sidebar>
            </div>
            <div className="col-md-6 col-lg-6 px-5">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-between mb-30">
                  <h2 style={{ fontWeight: "600" }}>My Coupons</h2>
                </div>

                {/* {favPlace?.requests.length > 0 ? favPlace?.requests.map((data) => ( */}
               { isLoading ? (
                  <div className="d-flex justify-content-center align-items-center mt-5 ">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>) :( 
                    coupanData.Promotions?.map((data, index) => (
                      <div key={index} className="_css-imdnMt mb-5">
                        <div className=" col-md-12 d-flex justify-content-center mt-3">
                          <img
                            // style={{ height: '80%',width:'80%' }}
                            className="coupan_image"
                            src={image_paths[index]=="" ? "/assets/imgs/icons/Vector@3x.png" : image_paths[index]}
                          />
                        </div>
    
                        <div className="_css-cAseJQ d-flex justify-content-between">
                          <div className="d-flex w-70">
                            <div className="mx-3">
                              <h6 className="fw-bold">{data.promo_name}</h6>
                              <p className="fw-bold">
                                Expires in {data.time_left} days
                              </p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end w-30 mx-3">
                            <div>
                              <button
                                className="btn py-1 ms-3 "
                                onClick={() => handleOpenModal(data)}
                              >
                                <i className="bi bi-three-dots-vertical"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                {/* )) : <div>Add your favourite place</div>} */}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img
                // src="/assets/imgs/icons/my-trip-2.svg"
                src="/assets/imgs/bg/cars2.jpg"
                alt="my-trip-2"
                className="mb-3"
              />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                  to="/dashboard"
                >
                  Request a Ride
                </Link>
                          </div>
            {/* {requestId &&
              <div className="fixed-bottom">
                <div className="float-end mr-30 pb-30">
                  <Link to={"/ride-detail/" + requestId} className="btn btn-primary">Go to ride</Link>
                </div>
              </div>} */}
            </div>
          </div>
        </div>
      </section>
      <Modal show={showModal} onHide={hideModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* {coupanData.Promotions?.map((data) => ( */}
            <>
              <h5 className="fw-bold text-center">{promoname}</h5>
              <div className="row d-flex justify-content-center mt-4">
                <div
                  className="d-flex p-2 align-items-center justify-content-between"
                  style={{ border: "1px dotted gray", width: "90%" }}
                >
                  <h6 className="fw-bold text-blue">{promocode}</h6>
                  <h6>
                    {" "}
                    Expires in{" "}
                    <span className="fw-bold  text-blue">{timeleft}</span> days
                  </h6>
                </div>
                <ul className="col-md-11 mt-4 mb-4 ul-dot">
                  <li className="text-blue coupan_detail_text">{thirdParty}</li>
                </ul>
              </div>
              <input
                type="text"
                hidden
                className="link_input"
                readOnly
                defaultValue="test"
              />
              <Button
                className="btn btn-primary w-100"
                onClick={() => {
                  copyLink(promocode);
                }}
              >
                Copy
              </Button>
              {copySuccess && (
                <p className="text-success">Copied successfully!</p>
              )}
            </>

            {/* ))} */}
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Coupons;
