import "./App.css";
import { useState, useEffect, useContext } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import Header from "./components/common/Header";
import Homepage from "./Pages/Homepage";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import Footer from "./components/common/Footer";
import DrivePage from "./Pages/DrivePage";
import BusinessPage from "./Pages/BusinessPage";
import EatesPage from "./Pages/EatesPage";
import AboutPage from "./Pages/AboutPage";
import Otp from "./components/login/Otp";
import UserDetail from "./components/user-info/UserDetail";
import ContactInfo from "./components/user-info/ContactInfo";
import Terms from "./components/tearms/Tearms";
import Dashboard from "./components/Afterlogin/Dashboard";
import MyTrips from "./components/Afterlogin/MyTrips";
import TaxPage from "./components/Afterlogin/TaxPage";
import AccountInfo from "./components/account-manage/AccountInfo";
import Security from "./components/account-manage/Security";
import PrivacyData from "./components/account-manage/PrivacyData";
import RideDetail from "./components/ride-detail/RideDetail";
import Payments from "./components/payments/Payments";
import Wallet from "./components/wallet/Wallet";
import Setting from "./components/settings/Setting";
import SavedPlaces from "./components/saved-places/SavedPlaces";
import Faqs from "./components/common/Faqs";
import TermsAndCondition from "./components/common/TermsAndCondition";
import Feedback from "react-bootstrap/esm/Feedback";
import FeedbackPage from "./components/common/Feedback";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import RideReciept from "./components/Afterlogin/RideReciept";
import ContactNumber from "./components/login/ContactNumber";
import PrivateRoute from "./components/privateroute/PrivateRoute";
import { getProfileDataApi } from "./api/api";
import { requestNotificationPermission } from "./utils/notification";
import Coupons from "./components/coupons/Coupons";
import { UserContext } from "./utils/userContext";
import ContactsUs from "./components/contact-us/ContactsUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullScreenLoader from "./components/loader/FullScreenLoader";

function App() {
  const [userDetail, setUserDetail] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [enableFeaturesList, setEnableFeaturesList] = useState();
  const [requestId, setRequestId] = useState();
  const ctx = useContext(UserContext);
  const { loader } = ctx;
  const navigate = useNavigate();

  const location = useLocation();

  var location_path = location.pathname;
  if (location_path.includes("ride-detail")) {
    location_path = location_path.split("/")[1];
  }

  useEffect(() => {
    requestNotificationPermission()
      .then((token) => {
        console.log("Token received:", token);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
    var user = localStorage.getItem("user_data");

    if (user) {
      setUserDetail(JSON.parse(user));
    }
    isSafari();
  }, []);

  function isSafari() {
    const ua = navigator.userAgent;
    const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua) && !/Edg/.test(ua);
    localStorage.setItem("isSafari",isSafari);
  }


  // useEffect(() => {
  //   const setupNotifications = async () => {
  //     try {
  //       const token = await requestNotificationPermission();
  //       console.log("Token received successfully:", token);
        
  //       // Optional: Send token to your backend
  //       // await sendTokenToBackend(token);
  //     } catch (error) {
  //       // Handle different types of errors
  //       if (error.message.includes('permission denied')) {
  //         // Handle permission denial
  //         alert('Please enable notifications in your browser settings');
  //       } else {
  //         // Handle other errors
  //         console.error('Notification setup failed:', error);
  //       }
  //     }
  //   var user = localStorage.getItem("user_data");

  //   if (user) {
  //     setUserDetail(JSON.parse(user));
  //   }
  // }
  // setupNotifications()
  // }, []);
  
  useEffect(() => {
    var id = localStorage.getItem("request_id");
    if (id) {
      setRequestId(id);
    } else {
      setRequestId(null);
    }
  }, [location.pathname]);  

  /* getToken(messaging, {
    vapidKey: 
      "BPqzwFq42XqK2I9TrNgtA58c5M4rm1wICKgxvr1l81cobhRo4iJtLGj7jytVPzMafhgM90FW5RvS2tciFg8aulc",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("Firebase Token", currentToken);
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    }); */

  useEffect(() => {
    if (userDetail) {
      get_profile_data();
      if (ctx.enableFeaturesList) {
        setEnableFeaturesList((ctx.enableFeaturesList))
      }
      setInterval(()=>{
        // get_profile_data();  // comment for dev mode only
      },10000)
    }
  }, [userDetail]);

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {
        setUserProfileData(data);
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear()
          setUserDetail();
          navigate("/login")
        }
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
     <ToastContainer />
      <Header></Header>
      {loader && <FullScreenLoader/>}
      {requestId &&
        location_path !== "ride-detail" &&
        location.pathname !== "/" &&
        location.pathname !== "/terms-condition" &&
        location.pathname !== "/login" &&
        location.pathname !== "/otp" && (
          <div className="fixed-bottom">
            <div className="float-end mr-30 pb-30">
              <Link
                to={"/ride-detail/" + requestId}
                className="btn btn-primary"
              >
                Go to ride
              </Link>
            </div>
          </div>
        )}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/otp" exact element={<Otp />} />
        <Route path="/contact-number" exact element={<ContactNumber />} />
        <Route path="/terms-condition" exact element={<TermsAndCondition />} />
        <Route element={<PrivateRoute />}>
          <Route
            path="/about"
            exact
            element={<AboutPage enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/faqs"
            exact
            element={<Faqs enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/feedback"
            exact
            element={<FeedbackPage enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/privacy-policy"
            exact
            element={<PrivacyPolicy enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/dashboard"
            exact
            element={<Dashboard enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/my-trips"
            exact
            element={<MyTrips requestId={requestId}  enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/ride-reciept/:id"
            exact
            element={
              <RideReciept
                userProfile={userProfileData}
                enableFeatures={enableFeaturesList}
              />
            }
          />
          <Route
            path="/tax-page"
            exact
            element={<TaxPage enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/settings"
            exact
            element={<Setting enableFeatures={enableFeaturesList} />}
          />
          <Route
            path="/profile"
            exact
            element={
              <AccountInfo
                userProfile={userProfileData}
                enableFeatures={enableFeaturesList}
              />
            }
          />
          <Route path="/security" exact element={<Security enableFeatures={enableFeaturesList} />} />
          <Route path="/coupons" exact element={<Coupons enableFeatures={enableFeaturesList} />} />
          <Route path="/privacy-data" exact element={<PrivacyData enableFeatures={enableFeaturesList} />} />
          <Route path="/wallet" exact element={<Wallet userProfile={userProfileData} enableFeatures={enableFeaturesList} />} />
          <Route path="/saved-places" exact element={<SavedPlaces enableFeatures={enableFeaturesList} />} />
          <Route path="/contact-us" exact element={<ContactsUs userProfile={userProfileData}   enableFeatures={enableFeaturesList}            />} />
          <Route path="/ride-detail/:id" exact element={<RideDetail  enableFeatures={enableFeaturesList} />} />
          <Route path="/payments" exact element={<Payments enableFeatures={enableFeaturesList} />} />
        </Route>

        {/* <Route path="/register" exact element={<Register />} /> */}
        {/* <Route path="/drive" exact element={<DrivePage />} /> */}
        {/* <Route path="/rove-eats" exact element={<EatesPage />} /> */}
        {/* <Route path="/user-detail" exact element={<UserDetail />} /> */}
        {/* <Route path="/contact-info" exact element={<ContactInfo />} /> */}
        {/* <Route path="/terms" exact element={<Terms />} /> */}
        <Route path="/*" element={<BusinessPage />} />
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
