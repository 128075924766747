import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import { UserContext } from "../../utils/userContext";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment-timezone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MapWithAMarker from "../common/MapDashboard";


import useChangeBackButtonPath from "../../utils/hooks/useChangeBackHistory";
import {
  driverRequestApi,
  enableFeaturesApi,
  fareBreakDownApi,
  getDriverApi,
  getProfileDataApi,
  nearSearchLocationApi,
  priceCalculateApi,
  validateCouponApi,
  homeScreenNotificationApi,
  getLongRentalCarApi,
  getFavPlaceApi,
  createTapCustomerApi,
  createCustomerIdApi,
  addFavPlaceApi,
  retrieveTapCustomerApi,
  saveTapCardApi,
  addCardApi,
  checkUnpaidRequest,
  addWalletTapWebAPI,
  paybyWalletApi,
  paybyCardRideApi,
  airportCarSelectionApi,
  addCardWalletAPI,
  userWalletDetailApi,
} from "../../api/api";
import { TapCard, Currencies, Direction, Edges, Locale, Theme, Scope, ColorStyle, tokenize } from '@tap-payments/card-web'
import Spinner from "react-bootstrap/Spinner";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const google = window.google;
// const tap_secret = process.env.REACT_APP_TAP_PAYMENT_LIVE == "0" ? process.env.REACT_APP_TAP_PAYMENT_SECRET_TEST : process.env.REACT_APP_TAP_PAYMENT_SECRET;
const tap_public = process.env.REACT_APP_TAP_PAYMENT_LIVE == "0" ? process.env.REACT_APP_TAP_PAYMENT_PUBLIC_TEST : process.env.REACT_APP_TAP_PAYMENT_PUBLIC;

const Dashboard = ({ enableFeatures }) => {
  const navigate = useNavigate();
  const ctx = useContext(UserContext);
  // const { setLoader } = useContext(UserContext);

  const inputRef = useRef();

  const inputRef2 = useRef();

  const [couponCode, setCouponCode] = useState("");
  const [searchDisable, setSearchDisable] = useState(false);
  const [htmlFileString, setHtmlFileString] = useState();
  const [showLocationPrompt, setShowLocationPrompt] = useState(false);

  useChangeBackButtonPath("/dashboard");
  Number.prototype.padLeft = function (base, chr) {
    var len = String(base || 10).length - String(this).length + 1;
    return len > 0 ? new Array(len).join(chr || "0") + this : this;
  };

  const [enableFeaturesList, setEnableFeaturesList] = useState(() => {
    var enb = localStorage.getItem("enablefeatures");
    return enb ? JSON.parse(enb) : null;
  });

  const [userDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });

  async function fetchHtml() {
    setHtmlFileString(await (await fetch(`termsandcondition.html`)).text());
  }
  /* var d = new Date(),
      today = [
       d.getFullYear(),
       (d.getMonth() + 1).padLeft(),
       d.getDate().padLeft(),
     ].join("-");
   var time = [
     d.getHours().padLeft(),
     d.getMinutes().padLeft(),
     d.getSeconds().padLeft(),
   ].join(":"); */

  // {
  //   lat: 29.2618672,
  //   lng: 47.8766367,
  //   text: "",
  // }
  const [position, setPosition] = useState();
  const [currantPosition, setCurrantPosition] = useState();
  const [zoom, setZoom] = useState(13);
  // {
  //   lat: 29.24182011471483,
  //   lng: 47.972080036997795,
  //   text: "King Faisal Motorway, Airport",
  // }
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});
  // const [destinationPosition, setDestinationPosition] = useState({ lat: 0, lng: 0, text: "" });
  const [routeDirections, setRouteDirections] = useState({});
  const [requestRide, setRequestRide] = useState(false);
  const [forMeShow, setForMeShow] = useState(false);
  const [guestShow, setGuestShow] = useState(false);
  const [carDetailShow, setCarDetailShow] = useState(false);
  // const [location, setLocation] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [noOfCars, setNoOfCars] = useState(false);
  const [longRide, setLongRide] = useState(false);
  const [airportRide, setAirportRide] = useState(false);
  const [showPickupLater, setShowPickupLater] = useState(false);
  const [estimatedprice, setEstimatedprice] = useState("Loading...");
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isChrome = /chrome|chromium|crios/i.test(navigator.userAgent);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChromeModal, setIsChromeModal] = useState(false);

  const handleDateChange = (date) => {
    if (enableFeaturesList) {
      const updatedDate = new Date();
      var diff = enableFeaturesList.pickup_later_time_min;
      if (rideType[2]) {
        diff = enableFeaturesList.rental_car_time_min
      }
      updatedDate.setTime(
        updatedDate.getTime() + parseInt(diff) * 60 * 1000
      );
      if (date < updatedDate) {
        var message = "Please, select correct date."
        if (rideType[1]) {
          message = enableFeaturesList.pickupLatterMessage;
        }
        if (rideType[2]) {
          message = enableFeaturesList.LongRideMessage;
        }
        toast.error(message, {
          position: "top-center",
          autoClose: 1500,
        })
      } else {
        setStartDate(date);
      }
    }

    // const oneHourAhead = new Date(new Date().getTime() + 60 * 60 * 1000);
    // console.log(date,oneHourAhead);
  };

  const [noOfCarForRide, setNoOfCarForRide] = useState(1);
  const [rideType, setRideType] = useState({
    0: true, // pickup now
    1: false, // pickup later
    2: false, // longride
    3: false, // airport
  });

  let initial = {
    4: true,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    20: false,
    21: false,
    22: false,
    23: false,
    24: false,
  };

  const [Hours, setHours] = useState([]);

  const [noOfHours, setNoOfHours] = useState(initial);
  const [userProfileData, setUserProfileData] = useState();
  const [nearSearchLocation, setNearSearchLocation] = useState();
  const [getDriverDetail, setGetDriverDetail] = useState();
  const [carFareBreakdown, setCarFareBreakdown] = useState();
  const [priceCalculation, setPriceCalculation] = useState();
  const [homeScreenData, setHomeScreenData] = useState();
  const [path, setPath] = useState();

  const [userBlock, setUserBlock] = useState(false);
  const [userBlockModal, setUserBlockModal] = useState(false);
  const [userBlockMessage, setUserBlockMessage] = useState();

  const [addWalletShow, setAddWalletShow] = useState(false);
  const [addWalletModal, setAddWalletModal] = useState(false);
  const [walletDiff, setWalletDiff] = useState(0);

  const [addEmailShow, setAddEmailShow] = useState(false);
  const [addEmailModal, setAddEmailModal] = useState(false);
  const [addNameShow, setAddNameShow] = useState(false);

  const intervalRef = useRef(null);
  const getDriverRef = useRef(null);

  const [cardDetail, setCardDetail] = useState(null);

  const [paymentModal, setPaymentModal] = useState(false);
  const [advancePaymentModal, setAdvancePaymentModal] = useState(false);

  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [selelectPayMode, setSelectPayMode] = useState(1); // 0 cash 1 knet 2 card

  const [longRidePopup, setLongRidePopup] = useState();
  const [longRideCars, setLongRideCars] = useState();
  const [selectedCar, setSelectedCar] = useState();
  const [favPlaces, setFavPlaces] = useState();
  const [combinePlaces, setCombinePlaces] = useState();

  const [selectedPickupCar, setSelectedPickupCar] = useState();

  const [searchPickup, setSearchPickup] = useState(false); // modal
  const [searchDestination, setSearchDestination] = useState(false);
  const [recentPlaces, setRecentPlaces] = useState(() => {
    var rp = localStorage.getItem("recent-places");
    return rp ? JSON.parse(rp) : null;
  });

  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState();
  const [pickupMarkerSet, setPickupMarkerSet] = useState(false);
  const [destinationMarkerSet, setDestinationMarkerSet] = useState(false);
  const [priceCalculated, setPriceCalculated] = useState(false);
  const [isRebookedRide, setIsRebookedRide] = useState(false); // New state for rebook status
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Initial button state



  const fetchAndSetCarTypes = async () => {
    try {
      const allCarTypes = await fetchCarTypes();
      const filteredCarTypes = allCarTypes.filter(car =>
        car.display_name.includes('BMW') || car.display_name.includes('Lexus')
      );

      const uniqueCarTypes = filteredCarTypes.filter((car, index, self) =>
        index === self.findIndex((c) => c.display_name === car.display_name)
      );
      setCarTypes(uniqueCarTypes);
    } catch (error) {
      console.error("Error fetching car types:", error);
    }
  };

  const handleCarTypeChange = (event) => {
    console.log(event.target.value)
    setSelectedCarType(event.target.value);
  };

  useEffect(() => {
    fetchAndSetCarTypes();
  }, []);


  const [addressType, setAddressType] = useState({
    1: true,
    2: false,
    3: false,
  });
  const [show, setShow] = useState(false);
  const [addPlace, setAddPlace] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [savedPlaceValue, setSavedPlaceValue] = useState();

  const [showAllSavedPlaces, setShowAllSavedPlaces] = useState(false);

  const [driverAvailable, setDriverAvailable] = useState(true);
  const [driverNotAvailable, setDriverNotAvailable] = useState(true);

  const [driverLength, setDriverLength] = useState(true);
  const [driverLength1, setDriverLength1] = useState(true);

  const [favPlacesOriginal, setFavPlacesOriginal] = useState();

  const [forCreditCardShow, setForCreditCardShow] = useState(false);
  const handleForCreditCardClose = () => setForCreditCardShow(false);
  const [customerObj, setCustomerObj] = useState({});
  const [createEnabled, setCreateEnabled] = useState(false);
  const [paymentDueData, setPaymentDueData] = useState();

  const [paymentDueModal, setPaymentDueModal] = useState(false);
  const [showPaymentDueModal, setShowPaymentDueModal] = useState(false);

  const [specialPickupModal, setSpecialPickupModal] = useState(false);
  const [walletSpecialPickupModal, setWalletSpecialPickupModal] = useState(false);

  const [specialPickupData, setSpecialPickupData] = useState();
  const [showSpecialPickup, setShowSpecialPickup] = useState(false);
  const [showWalletSpecialModal, setShowWalletSpecialModal] = useState(false);

  const [surchargeModal, setSurchargeModal] = useState(false); // for modal 
  const [showSurcharge, setShowSurcharge] = useState(false);  // for modal open on button click
  const [surchageData, setSurchageData] = useState();
  const [roveDoesNotOperate, setRoveDoesNoteOperate] = useState(false);

  const [walletDetail, setWalletDetail] = useState();

  const maxDate = useMemo(() => {
    const date = new Date();
    if (enableFeaturesList) {
      date.setDate(
        date.getDate() + parseInt(enableFeaturesList.maximumDays_For_FutureRides)
      );
    } else {
      date.setDate(date.getDate() + 6);
    }
    return date;
  }, [enableFeaturesList]);


  useEffect(() => {
    if (enableFeaturesList) {
      const updatedDate = new Date();
      var diff = enableFeaturesList.pickup_later_time_min;
      if (rideType[2]) {
        diff = enableFeaturesList.rental_car_time_min
      }
      updatedDate.setTime(
        updatedDate.getTime() + parseInt(diff) * 60 * 1000
      );
      setStartDate(updatedDate);
    }
  }, [enableFeaturesList, rideType]);


  useEffect(() => {
    if (!htmlFileString) {
      fetchHtml();
    }
    /*  if (pickupPosition && Object.keys(pickupPosition).length) {
       setSearchDisable(false);
     } else {
       setSearchDisable(true);
     } */
  }, []);


  useEffect(() => {
    if (userDetail) {
      // homeScreenNotification();
      if (!favPlaces) {
        fetchFavoriteLocations();
      }
      if (enableFeatures) {
        setEnableFeaturesList(enableFeatures);
        findBlockUserMessage(enableFeatures);
      } else {
        if (ctx.enableFeaturesList) {
          setEnableFeaturesList(ctx.enableFeaturesList);
          findBlockUserMessage(ctx.enableFeaturesList);
        } else {
          // get_enable_features_list();
        }
      }

      /*  if (!userProfileData && !ctx.loginUser) {
         get_profile_data();
       } else {
         setUserProfileData(ctx.loginUser)
       }
       if (userProfileData && Object.keys(customerObj).length == 0) {
         fetchCustomer();
       } */
    }

    intervalRef.current = setInterval(() => {
      if (userDetail && pickupPosition) {
        if (!Object.keys(destinationPosition).length) {
          near_search_location();
        }
      }
    }, 1000 * 60 * 2);



    /* intervalRef.current = setInterval(() => {
      if (userDetail && pickupPosition) {
        near_search_location();
      }
    }, 1000 * 60 * 2); */
    getDriverRef.current = setInterval(() => {
      if (userDetail && pickupPosition) {
        get_driver()
      }
    }, 6000);
    /* getDriverRef.current = setInterval(() => {
      if (userDetail && pickupPosition) {
        get_driver();
      }
    }, 6000); */

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalRef.current);
      clearInterval(getDriverRef.current);
    };
  }, [userDetail, pickupPosition, destinationPosition]);

  useEffect(() => {
    if (userDetail) {
      if (!homeScreenData) {
        homeScreenNotification();
      }
      if (!walletDetail) {
        user_wallet_detail();
      }
      if (!userProfileData && enableFeaturesList) {
        get_profile_data();
      }/* else {
        setUserProfileData(ctx.loginUser)
      } */

      /* if (userProfileData) {
        fetchCustomer();
      } */
    }

  }, [userDetail, enableFeaturesList]);


  const findBlockUserMessage = (data) => {
    var block_msg = data.all_static_message.find((e) => e["you_are_blocked"]);
    if (block_msg) {
      setUserBlockMessage(block_msg.you_are_blocked.you_are_blocked_en);
    }
  };

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
    componentRestrictions: { country: ["IN", "KW"] },
  };

  const addPlaceHandler = (e) => {
    e.preventDefault();
    add_fav_place();
    setAddPlace({});
  };

  // save place
  const handleClose = () => {
    setShow(false);
    if (savedPlaceValue == 0) {
      document.getElementById("pick_up_save").src =
        "/assets/imgs/icons/Vector.png";
    } else {
      document.getElementById("dest_save").src =
        "/assets/imgs/icons/Vector.png";
    }
  };

  const specialPickupModalClose = () => {
    setSpecialPickupModal(false);
  };

  const surchargeModalClose = async () => {
    setSurchargeModal(false);
    setShowSurcharge(false);
    if (paymentDueData && paymentDueData.status == "0") {
      if (rideType[0]) {
        if (parseInt(getDriverDetail.isDriverAvailable) > 0) {
          console.log("first")
          var data = await driver_request(0, couponCode);
          if (data.status == "1") {
            localStorage.setItem("request_id", data.request_id);
            localStorage.removeItem('rebookdata')
            navigate("/ride-detail/" + data.request_id);
          } else {
            toast(data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } else {
          toast(
            "There are no driver available in this area. Please try again later or use pickup later.",
            {
              position: "top-center",
              autoClose: 1000,
            }
          );
        }
      } else if (rideType[1]) {
        await bookPickupLater();
      }
    } else {
      setPaymentDueModal(true);
    }

  };

  const walletSpecialPickupModalClose = () => {
    setWalletSpecialPickupModal(false);
  }

  const handleAddEmailModalClose = () => {
    setAddEmailModal(false);
  };

  const paymentDueModalClose = () => {
    setPaymentDueModal(false);
  };

  const handleUserBlockModalClose = () => {
    setUserBlockModal(false);
  };

  const handleDestinationClose = () => {
    setSearchDestination(false);
    setCombinePlaces();
  };

  const handlePickupClose = () => {
    setSearchPickup(false);
    setCombinePlaces();
  };

  const handleAddWalletModalClose = () => {
    setAddWalletModal(false);
  };

  const paymentModalHandler = () => {
    setPaymentModal(false);
  };

  const paymentMethodModalClose = () => {
    setPaymentMethodModal(false);
  };

  const advancePaymentModalClose = () => {
    setAdvancePaymentModal(false);
  };

  const selectedDestination = async (data) => {
    /*  var des = document.querySelector("#Location2");
    des.value = data.address; */
    // des.value = data.address.split(",")[0];
    setRouteDirections();
    setPath();
    saveRecentPalces(data);
    handleDestinationClose();

    var ride_t = (rideType[1]) ? 1 : 0;
    var p_r = await price_calculation(pickupPosition.lat, pickupPosition.lng, data.latitude, data.longitude, couponCode, ride_t);
    if (p_r.is_airport_ride == "1") {
      setAirportRide(true)
    } else {
      setAirportRide(false);
    }

    var esti_pr =
      parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
    // if (ride_t == 1) {
    if (p_r["multi_carType_data"] && p_r.multi_carType_data.length > 0) {
      setSelectedPickupCar(p_r.multi_carType_data[0]);
      esti_pr = parseFloat(p_r.multi_carType_data[0].estimated_price_final)
    }
    // }
    esti_pr = esti_pr.toFixed(3);
    var dp = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
      text: "KD " + esti_pr,
    };
    setDestinationPosition(dp);
    near_search_location(data.latitude, data.longitude, 1).then((res) => {
      var userLocation = res.user_location_data;
      var des = document.querySelector("#Location2");
      des.value = userLocation.title_address;
      var dp = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        text: "KD " + esti_pr,
        add: userLocation.title_address,
      };
      setDestinationPosition(dp);
      setPickupPosition({
        ...pickupPosition,
        text: p_r.pickup_destination_time + " Minutes",
      });
      if (!pickupPosition) {
        setPosition(dp);
      }
      setZoom(13);
      aftersetDestinationPosition(pickupPosition, dp);

      var time = setTimeout(() => {
        if ("favorite_id" in data) {
          document.getElementById("dest_save").src =
            "/assets/imgs/icons/Vector-fill.png";
          document.getElementById("dest_save_icon").classList.add("disable");
        } else {
          document.getElementById("dest_save").src =
            "/assets/imgs/icons/Vector.png";
          document.getElementById("dest_save_icon").classList.remove("disable");
        }
      }, 100);
    });
  };

  const checkWalletForSpecialPickup = (data, is_destination = 0) => {

    var is_advance_payment = data.is_advance_payment == "1";
    var pickup_base_price = parseFloat(data.pickup_base_price);
    var extra_pickup_base_price = parseFloat(data.extra_pickup_base_price);
    var total_price = pickup_base_price + extra_pickup_base_price;
    var is_special_pickup = data.is_pickup_special_price == "1";
    // var is_special_dest = data.is_destination_special_price == "1";
    // if (userProfileData && userProfileData.user_profile.wallet && (is_destination != 1)) {
    if (walletDetail && walletDetail.total_walletBalance && (is_destination != 1)) {
      setShowSpecialPickup(is_special_pickup);
      // setShowSpecialPickup(true);
      var wallet_amount = parseFloat(walletDetail.total_walletBalance);
      if ((wallet_amount < total_price) && is_advance_payment) {
        var price_diff = total_price - wallet_amount;
        setWalletDiff(price_diff.toFixed(3));
        if (!Object.keys(destinationPosition).length > 1) {
          setSpecialPickupData({ ...specialPickupData, topup_price: price_diff, is_special_pickup });
        } else {
          setSpecialPickupData({ topup_price: price_diff, is_special_pickup });
        }
        setShowWalletSpecialModal(true);
      } else {
        setShowWalletSpecialModal(false);
        /* if(is_special_dest){
          // setSpecialPickupData({ ...specialPickupData, is_special_dest, dest_price: data.destination_base_price });
        } else */
        setSpecialPickupData({ ...specialPickupData, is_special_pickup });
        if (!is_special_pickup && (specialPickupData && !specialPickupData.is_special_dest)) {
          setShowSpecialPickup(false);
        };
      }
    }
  }

  /* const specialTopupWallet = () => {
    setWalletSpecialPickupModal(false);
    setPaymentModal(true);
  } */

  const handleSpecialPicupConfirm = async () => {
    if (showWalletSpecialModal) {
      // setWalletSpecialPickupModal(true);
      setPaymentModal(true);
      setSpecialPickupModal(false);
    } else {
      setShowSpecialPickup(false);
      setSpecialPickupModal(false);
      if (rideType[1]) {
        booklongRide();
      } else {
        if (paymentDueData && paymentDueData.status == "0") {
          if (parseInt(getDriverDetail.isDriverAvailable) > 0) {

            var data = await driver_request(0, couponCode);
            if (data.status == "1") {
              localStorage.setItem("request_id", data.request_id);
              localStorage.removeItem('rebookdata')
              navigate("/ride-detail/" + data.request_id);
            } else {
              toast(data.message, {
                position: "top-center",
                autoClose: 1000,
              });
            }
          } else {
            toast(
              "There are no driver available in this area. Please try again later or use pickup later.",
              {
                position: "top-center",
                autoClose: 1000,
              }
            );
          }
        } else {
          setPaymentDueModal(true);
        }
      }
    }

  }


  const selectedPickupLoc = async (data) => {
    saveRecentPalces(data);
    handlePickupClose();
    // Update the address in the input field
    get_driver(data.latitude,data.longitude,false)
    const pic = document.querySelector("#Location1");
    pic.value = data.address;
    
    const pp = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
      text: "",
      add: data.address,
    };
    setPickupPosition(pp);
    setPosition({
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
    });
    setZoom(13);
    
    // Fetch nearby locations and handle special pricing
    const res = await near_search_location(data.latitude, data.longitude);
    const userLocation = res.user_location_data;


    // Update pickup position with new data
    const updatedPickupPosition = {
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude),
      add: data.address,
      text: near_search_condition(res)
    };
    setPickupPosition(updatedPickupPosition);
    if (rideType[0]) {
      setNoOfCarForRide(1);
    }
    if (res.is_airport_ride == "1") {
      setAirportRide(true)
    } else {
      setAirportRide(false)
    }

    // Handle UI updates for saving the pickup location
    setTimeout(() => {
      if ("favorite_id" in data) {
        document.getElementById("pick_up_save").src = "/assets/imgs/icons/Vector-fill.png";
        document.getElementById("pickup_save_icon").classList.add("disable");
      } else {
        document.getElementById("pick_up_save").src = "/assets/imgs/icons/Vector.png";
        document.getElementById("pickup_save_icon").classList.remove("disable");
      }
    }, 100);
    fetchFavoriteLocations();
  };

  const selectDestinationFromMap = () => {
    setDestinationPosition({ ...pickupPosition, text: "" });
    setRouteDirections();
    setPath();
    handleDestinationClose();
    fetchFavoriteLocations();
  };

  const handleSuggestedPickup = (e) => {
    selectedPickupLoc(JSON.parse(e.target.value));
  };

  const handleSuggestedDestination = (e) => {
    selectedDestination(JSON.parse(e.target.value));
  };

  const saveRecentPalces = (data) => {
    if (!("favorite_id" in data)) {
      let rp = localStorage.getItem("recent-places");
      if (rp) {
        rp = JSON.parse(rp);
        rp = rp.filter(
          (pl) => pl.address.split(",")[0] !== data.address.split(",")[0]
        );
        rp.unshift(data);
        setRecentPlaces(rp);
        localStorage.setItem("recent-places", JSON.stringify(rp));
      } else {
        setRecentPlaces([data]);
        localStorage.setItem("recent-places", JSON.stringify([data]));
      }
    }
  };

  const handleClearRecentPlaces = () => {
    localStorage.removeItem("recent-places");
    setRecentPlaces();
  };

  const DirectionsService = new google.maps.DirectionsService();

  function formatDate(date) {
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
        .getDate()
        .toString()
        .padStart(2, "0")}/${date.getFullYear()} ${date.toLocaleTimeString(
          "en-US",
          { hour: "numeric", minute: "numeric", hour12: true }
        )}`;
    return formattedDate;
  }

  const requestNow = async () => {
    if (paymentDueData && paymentDueData.status == "0") {
      if (parseInt(getDriverDetail.isDriverAvailable) > 0) {
        var data = await driver_request(0, couponCode);
        if (data.status == "1") {
          localStorage.setItem("request_id", data.request_id);
          localStorage.removeItem('rebookdata')
          navigate("/ride-detail/" + data.request_id);
        } else {
          toast(data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        toast(
          "There are no driver available in this area. Please try again later or use pickup later.",
          {
            position: "top-center",
            autoClose: 1000,
          }
        );
      }
    } else {
      setPaymentDueModal(true);
    }
  }

  const requestRideHandler = async (payment_topUp = true) => {
    /* localStorage.setItem("pickupPosition", JSON.stringify(pickupPosition));
    localStorage.setItem(
      "destinationPosition",
      JSON.stringify(destinationPosition)
    ); */
    // console.log("isDriverAvailable", getDriverDetail.isDriverAvailable);
    // var pending = await paymentDue();
    // if (payment_topUp) {


    if (showSpecialPickup) {
      setSpecialPickupModal(true);
      console.log('showspecialpickup', showSpecialPickup)
      setShowSurcharge(false);

    } else if (showSurcharge) {
      console.log('showsurcharge', showSurcharge)
      // console.log(!rideType[1] == true)
      setSurchargeModal(true);

    } else {
      // return false;
      await requestNow();
    }

  };

  const fetchFavoriteLocations = async () => {
    try {
      let form = new FormData();
      form.append("customer_id", userDetail.user_profile_id);
      // form.append("user_type", userDetail.user_type);
      var res = await getFavPlaceApi(form);
      var data = res.data;
      if (data.status == "1") {
        setFavPlaces(data);
        setFavPlacesOriginal(data);
      } else {
        console.log("fetchFavoriteLocations Dashboard", data);
      }
    } catch (error) {
      console.log("fetchFavoriteLocations Dashboard", error);
    }
  };


  const driver_request = async (
    ride_type = 0,
    promo = "",
    pickup_destination_time = 0,
    time_duration,
    date_time = "",
    estimated_price,
    ride_fare,
  ) => {
    try {
      setSearchDisable(true);
      ctx.setLoader(true);
      var timeZone = moment.tz.guess();
      let form = new FormData();
      form.append("Service_id", "0");
      form.append("discount_price", priceCalculation?.discount_price || "0.000");
      form.append("discount_price_second", priceCalculation?.discount_price_second || "0.000");
      form.append("is_new_design_app", "1");
      form.append("user_current_latitude", pickupPosition.lat);
      form.append("user_current_longitude", pickupPosition.lng);
      form.append("pickup_address", pickupPosition.add);
      form.append("pickup_latitude", pickupPosition.lat);
      form.append("pickup_longitude", pickupPosition.lng);
      form.append("near_location", pickupPosition.add);
      form.append("promo_code", promo ?? couponCode);
      form.append("Payment_Type", selelectPayMode);
      form.append("car_type", "17");
      form.append("user_id", userDetail.user_profile_id);
      form.append("user_type", userDetail.user_type);
      form.append("place_id", "");
      form.append(
        "destination_latitude",
        destinationPosition && Object.keys(destinationPosition).length >= 1
          ? destinationPosition.lat
          : 0
      );
      form.append(
        "destination_longitude",
        destinationPosition && Object.keys(destinationPosition).length >= 1
          ? destinationPosition.lng
          : 0
      );
      form.append(
        "destination_address",
        destinationPosition && Object.keys(destinationPosition).length >= 1
          ? destinationPosition.add
          : ""
      );
      form.append("area", "Kuwait");
      form.append("pnr_number", "");
      form.append("airline_name", "");
      form.append("offer_price", "0");
      form.append("is_rental_car", rideType[2] == true ? "1" : "0");
      if (rideType[1]) {
        form.append("airport_car_selection_id", selectedPickupCar ? selectedPickupCar.id : "0");
      };
      if (rideType[2]) {
        form.append("airport_car_selection_id", selectedCar ? selectedCar.id : "0");
      }

      form.append("rental_total_car", rideType[2] == true ? noOfCarForRide : "1");
      form.append("rental_car_hour", (rideType[2] == true && selectedCar) ? selectedCar.price.hours : "0");
      const date = new Date(startDate);
      const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
      form.append("pickup_date_time", rideType[2] == true ? formattedDate : date_time);
      if ((Object.keys(destinationPosition).length > 1) || estimated_price) {
        var est = priceCalculation.estimatedprice
        if (priceCalculation?.discount_price != "0.000") {
          est = parseFloat(est) - parseFloat(priceCalculation.discount_price);
          est.toFixed(3);
        }
        form.append(
          "estimated_price",
          estimated_price || est
        ); // price calculation
      }

      if (Object.keys(destinationPosition).length > 1 || ride_fare) {
        // form.append("ride_base_fare", ride_fare ?? priceCalculation.ride_fare);
        form.append("ride_base_fare", ride_fare || priceCalculation.base_price);
      }
      if (Object.keys(destinationPosition).length > 1) {
        var est = priceCalculation.estimatedprice_second
        if (priceCalculation.discount_price_second != "0.000") {
          est = parseFloat(est) - parseFloat(priceCalculation.discount_price_second);
          est.toFixed(3)
        }
        form.append(
          "estimatedprice_second",
          // priceCalculation.estimatedprice_second     //   undefined
          est || '0.000'
        );
      }

      form.append("is_suv_car_assign", "0");
      form.append("timezone", timeZone);
      form.append("who_is_ride_id", "0");
      form.append("airport_ride_pickup_destination", "0");
      form.append("multiple_destination", "");
      form.append("no_luggage_bags", "1");
      form.append("no_person", "0 - 1");
      form.append("flight_time_of_arrival", "0");
      form.append("rental_car_brand_type", "0");
      form.append("flight_name", "");
      form.append("base_fare_discount", "0.000");
      form.append("wallet", walletDetail?.total_walletBalance == "0.000" ? 0 : 1);
      form.append("flight_number", "");
      form.append("is_airport_ride", airportRide ? "1" : "0");
      form.append("pickup_later", ride_type || 0);
      var pickup_later_total_car = 0;
      if (rideType[1]) {
        pickup_later_total_car = noOfCarForRide;
        form.append("pickup_destination_time", pickup_destination_time);
      }
      if (rideType[2] || rideType[1]) {
        form.append("app_eta_mins", time_duration || 1);
      }
      form.append("pickup_later_total_car", pickup_later_total_car);
      form.append("car_selection_id", "0"); // dhaval
      form.append("is_user_multiple_ride", "0");
      form.append("multiple_destination_address", "");
      var res = await driverRequestApi(form);
      var data = res.data;
      setSearchDisable(false);
      if (rideType[0]) {
        toast(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
      setNoOfCarForRide(1);
      ctx.setLoader(false);
      return data;
    } catch (error) {
      ctx.setLoader(false);
      setSearchDisable(false);
      console.log("driver_request", error);
    } finally {
      ctx.setLoader(false);
      setSearchDisable(false);
    }
  };

  const selectHours = (h) => {
    for (const key of Hours) {
      if (key == parseInt(h.hours_value)) {
        initial[key] = true;
      } else {
        initial[key] = false;
      }
    };

    setNoOfHours(initial);
    if (longRideCars) {

      var update_price = longRideCars.map((car, index) => {
        car.price = h.multi_car_data[index];
        car.price.hp = parseFloat(car.price.hours_price) * noOfCarForRide;
        car.price.hp = +car.price.hp.toFixed(3);
        car.price.adp =
          parseFloat(car.price.advance_payment_amount) * noOfCarForRide;
        car.price = { ...car.price, hours: h.hours_value };
        return car;
      });
      if (selectedCar) {
        var car = h.multi_car_data.find(
          (item) => item.multi_car_type_id == selectedCar.id
        );

        setSelectedCar({
          ...selectedCar,
          price: {
            ...selectedCar.price,
            hp: +(parseFloat(car.hours_price) * noOfCarForRide).toFixed(3),
            adp: +(
              parseFloat(car.advance_payment_amount) * noOfCarForRide
            ).toFixed(3),
            hours: h.id
          },
        });
      }

      setLongRideCars(update_price);
    } else {

      setSelectedCar({
        ...h, price: {
          hp: +(parseFloat(h.hours_price) * noOfCarForRide).toFixed(3),
          adp: +(parseFloat(h.advance_payment_amount) * noOfCarForRide).toFixed(3),
        }
      })
    }

  };

  
  const selectLongrideCars = (car) => {
    setSelectedCar(car);
  };




  const bookPickupLater = async () => {
    try {
      if (rideType[1] && nearSearchLocation && nearSearchLocation["multi_carType_data"].length > 0 && !selectedPickupCar) {
        toast.error("Please, Select car", {
          position: "top-center",
          autoClose: 1500
        });
        return false;
      } else if (rideType[1] && nearSearchLocation && nearSearchLocation["multi_carType_data"].length == 0 && enableFeaturesList && ((enableFeaturesList.is_airport_ride == "1" && enableFeaturesList.is_airport_ride_car_selection == "1") || enableFeaturesList.is_all_pickup_later_ride_car_selection == 1) && !selectedPickupCar) {
        toast.error("Please, Select car", {
          position: "top-center",
          autoClose: 1500
        });
        return false;
      }
      const dateObj = new Date(startDate);

      const istMoment = moment.tz(dateObj, "Asia/Kolkata");

      // Convert IST time to UTC
      const utcMoment = istMoment.clone().utc();

      // Format the time in Kuwait time zone
      const formattedKuwaitTime = utcMoment.format("YYYY-MM-DD HH:mm:ss");
      var near_serch_res = await near_search_location(
        pickupPosition.lat,
        pickupPosition.lng
      );
      var time_duration = near_serch_res.user_location_data.time_duration;
      var Etp = 0;
      var r_f = 0;
      if (Object.keys(destinationPosition).length > 0) {
        var price_res = await price_calculation(
          pickupPosition.lat,
          pickupPosition.lng,
          Object.keys(destinationPosition).length ? destinationPosition.lat : 0,
          Object.keys(destinationPosition).length ? destinationPosition.lng : 0,
          couponCode,
          1
        );
        Etp = price_res.estimatedprice;
        r_f = price_res.ride_fare;
      }
      var data = await driver_request(
        1,
        couponCode,
        0,
        time_duration,
        formattedKuwaitTime,
        Etp,
        r_f
      );

      if (data && data.pickup_later_request_id) {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setLongRide(false);
        navigate({
          pathname: "/my-trips",
          search: `?${createSearchParams({ upcoming: "1" })}`,
        });
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }

    } catch (error) {

    }
  }


  const booklongRide = async () => {
    var isPickupLater = enableFeaturesList.is_pickup_later_surcharge

    if (
      pickupPosition &&
      Object.keys(pickupPosition).length > 0 &&
      rideType[1]
    ) {

      if (showSpecialPickup) {
        setSpecialPickupModal(true);
        setShowSurcharge(false);
      } else if (showSurcharge && isPickupLater == '1') { //dhruv added on 11/18
        setSurchargeModal(true);
        console.log('book long ride..')
      } else {
        // return false;
        if (paymentDueData && paymentDueData.status == "0") {
          await bookPickupLater();
        } else {
          setPaymentDueModal(true);
        }
      }

    } else if (
      pickupPosition &&
      Object.keys(pickupPosition).length &&
      rideType[3] == true
    ) {
      const dateObj = new Date(startDate);

      const istMoment = moment.tz(dateObj, "Asia/Kolkata");

      // Convert IST time to UTC
      const utcMoment = istMoment.clone().utc();

      // Format the time in Kuwait time zone
      const formattedKuwaitTime = utcMoment.format("YYYY-MM-DD HH:mm:ss");

      var price_res = await price_calculation(
        pickupPosition.lat,
        pickupPosition.lng,
        Object.keys(destinationPosition).length ? destinationPosition.lat : 0,
        Object.keys(destinationPosition).length ? destinationPosition.lng : 0,
        couponCode,
        1
      );
      var near_serch_res = await near_search_location(
        pickupPosition.lat,
        pickupPosition.lng
      );
      var time_duration = near_serch_res.user_location_data.time_duration;

      var Etp = price_res.estimatedprice;
      var r_f = price_res.ride_fare;

      var data = await driver_request(
        1,
        couponCode,
        price_res.pickup_destination_time,
        time_duration,
        formattedKuwaitTime,
        Etp,
        r_f
      );
      if (data && data.status == "0") {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        setLongRide(false);
        navigate({
          pathname: "/my-trips",
          search: `?${createSearchParams({ upcoming: "1" })}`,
        });
      }
    } else if (
      pickupPosition &&
      Object.keys(pickupPosition).length &&
      rideType[2] == true
    ) {
      if (!selectedCar) {
        toast.error("Please select car type.", {
          position: "top-center",
          autoClose: 1000,
        });
        return false;
      }

      var isAdvance = selectedCar.price.is_advance_payment == "1" || selectedCar.is_advance_payment == "1"; // make Boolean
      if (isAdvance) {
        setAdvancePaymentModal(true);
      }

    } else {
      toast.error("Please select pickup - destination location.", {
        position: "top-center",
        autoClose: 1000,
      });
      return false;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!roveDoesNotOperate) {
      if (userBlock) {
        setUserBlockModal(true);
      } else {
        if (addEmailShow || addNameShow) {
          setAddEmailModal(true);
        } else if (addWalletShow) {
          setAddWalletModal(true);
        } else {
          if (pickupPosition && destinationPosition) {
            try {
              // near_search_location();
              if (Object.keys(destinationPosition).length > 1) {
                price_calculation();
              }
              setRequestRide(true);
            } catch (error) {
              console.log("search ride", error);
            }
          }
        }
      }
    } else {
      toast.error("Rove Does Not Operate Here.", {
        position: "top-center",
        autoClose: 2000,
      });

    }

  };

  useEffect(() => {

    if (isRebookedRide == true) {
      if (pickupMarkerSet && destinationMarkerSet && priceCalculated) {
        setIsButtonDisabled(false); // Enable button if all conditions are true
      } else {
        setIsButtonDisabled(true); // Otherwise, keep it disabled
      }
    } else {
      setIsButtonDisabled(false)
    }

  }, [pickupMarkerSet, destinationMarkerSet, priceCalculated, isRebookedRide]);



  useEffect(() => {
    const rebookData = localStorage.getItem("rebookdata");
    if (rebookData) {
      setIsRebookedRide(true); // Indicate it's a rebooked ride

      var rebook_data = JSON.parse(rebookData);
      var pickup_data = rebook_data.pickup;
      var destination_data = rebook_data.destination;
      var pickup_lat = pickup_data.split(",")[0];
      var pickup_long = pickup_data.split(",")[1];
      var destination_lat = destination_data.split(",")[0];
      var destination_long = destination_data.split(",")[1];
      var pickup_add = rebook_data.pickup_address;
      var destination_add = rebook_data.destination_address;

      setZoom(13);
      setPosition({
        lat: parseFloat(pickup_lat),
        lng: parseFloat(pickup_long),
      });
      setRequestRide(true);
      setDestinationPosition({
        lat: parseFloat(destination_lat),
        lng: parseFloat(destination_long),
        add: destination_add
      });
      setPickupPosition({
        lat: parseFloat(pickup_lat),
        lng: parseFloat(pickup_long),
      });
      var dest_save;
      // setSpecialPickupData({...specialPickupData});

      var des = document.querySelector("#Location2");
      des.value = destination_add;
      dest_save = destination_add;
      //   near_search_location(destination_lat, destination_long, 1).then((data) => {
      //   var des = document.querySelector("#Location2");
      //   var userLocation = data.user_location_data;
      //   des.value = userLocation.title_address;
      //   dest_save = userLocation.title_address;
      //   // inputRef2.current.value = userLocation.title_address;
      // });

      setPickupMarkerSet(true);
      near_search_location(pickup_lat, pickup_long).then((data) => {

        var userLocation = data.user_location_data;
        // console.log(userLocation)

        var pic = document.querySelector("#Location1");
        if (pic) {
          pic.value = userLocation.title_address;
        }


        // inputRef.current.value = userLocation.title_address;
        var p = {
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
          text: near_search_condition(data),
          add: userLocation.title_address
        };

        setPickupPosition(p);
        get_driver(pickup_lat, pickup_long);
        var dest_position = {
          lat: parseFloat(destination_lat),
          lng: parseFloat(destination_long),

        };
        setPriceCalculated(true);

        if (Object.keys(dest_position).length && p) {
          price_calculation(
            pickup_lat,
            pickup_long,
            destination_lat,
            destination_long
          ).then((p_r) => {
            var esti_pr =
              parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
            esti_pr = esti_pr.toFixed(3);
            console.log("HELLO PRICECALCULATION", pickupPosition);
            setDestinationMarkerSet(true);
            setDestinationPosition({
              ...dest_position, add: dest_save,
              text: "KD " + esti_pr,
            });
          });
          aftersetDestinationPosition(p, dest_position);
        }
      });

      if (!roveDoesNotOperate) {
        if (userBlock) {
          setUserBlockModal(true);
        } else {
          if (addEmailShow || addNameShow) {
            setAddEmailModal(true);
          } else if (addWalletShow) {
            setAddWalletModal(true);
          } else {
            // console.log({pickupPosition , destinationPosition} )
            if (pickupPosition && destinationPosition) {
              try {
                // near_search_location();
                if (Object.keys(destinationPosition).length > 1) {
                  price_calculation();
                }
                setRequestRide(true);
              } catch (error) {
                console.log("search ride", error);
              }
            }
          }
        }
      } else {
        toast.error("Rove Does Not Operate Here.", {
          position: "top-center",
          autoClose: 2000,
        });

      }





      /*  setTimeout(() => {
         localStorage.removeItem("rebookdata");
       },1000) */
    } else {
      fetchLocation();    // currunt loczytion
    }
    if (!paymentDueData) {
      paymentDue();
    }
  }, []);

  const user_wallet_detail = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await userWalletDetailApi(form);
      var data = res.data;
      setWalletDetail(data);
      return data;
    } catch (error) {
      console.log("USERWALLETDETAIL", error)
    }
  }

  const get_profile_data = async (e) => {
    try {
      ctx.setLoader(true);
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {
        if (Object.keys(customerObj).length == 0) {
          fetchCustomer(data);
        }
        if (data.runining_ride_data != "") {
          localStorage.setItem("request_id", data.runining_ride_data.request_id);
          localStorage.removeItem('rebookdata')
          navigate("/ride-detail/" + data.runining_ride_data.request_id);
        } else {
          localStorage.removeItem("request_id");
        }
        if (data.user_profile.tap_customer_id == "") {
          if (
            data.user_profile.user_email != "" &&
            data.user_profile.name_eng != ""
          ) {
            var username = data.user_profile.name_eng;
            username = username.split(" ");
            var firstname = username[0];
            var lastname = "";
            if (username[1]) {
              lastname = username[1];
            }
            var tap_customer_form = new FormData();
            tap_customer_form.append("first_name", firstname);
            tap_customer_form.append("middle_name", "");
            tap_customer_form.append("last_name", lastname);
            tap_customer_form.append("email", data.user_profile.user_email);
            tap_customer_form.append("description", "test");
            tap_customer_form.append("currency", "KWD");
            tap_customer_form.append(
              "country_code",
              data.user_profile.country_code
            );
            tap_customer_form.append("number", data.user_profile.mobileno);
            // tap_customer_form.append("tap_secret", tap_secret);

            var tap_customer_res = await createTapCustomerApi(
              tap_customer_form
            );
            var tap_customer_data = tap_customer_res.data;
            if (tap_customer_data) {
              var customer_id = tap_customer_data.id;
              var customer_form = new FormData();
              customer_form.append("tap_customer_id", customer_id);
              customer_form.append("userId", data1.user_id);
              customer_form.append("user_type", data1.user_type);

              var customer_res = await createCustomerIdApi(customer_form);
              var customer_data = customer_res.data;
              if (customer_data.status == "1") {
              }
            }

            /*const options = {
              mode: 'no-cors',
              method: 'POST',
              headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                Authorization: 'Bearer '+tap_secret
              },
              body: JSON.stringify({
                first_name: 'test',
                middle_name: 'test',
                last_name: 'test',
                email: 'test@test.com',
                phone: {country_code: '965', number: '51234567'},
                description: 'test',
                metadata: {
                  "udf1": "test",
                },
                currency: 'KWD'
              }),
            };

            var tap_url = 'https://api.tap.company/v2/customers';
            fetch(tap_url, options)
              .then(response => response.json())
              .then(async response => {
                var customer_id = response.id
                var customer_form = new FormData();
                customer_form.append("tap_customer_id", customer_id);
                customer_form.append("user_id", data1.user_id);
                customer_form.append("user_type", data1.user_type);

                var customer_res = await createCustomerIdApi(customer_form);
                var customer_data = customer_res.data;
                if (customer_data.status == "1") {
                }
              })
              .catch(err => console.error(err));*/
          }
        }
        setUserProfileData(data);

        var dynamic = localStorage.getItem("dynamic_car")
        if (!dynamic) {
          localStorage.setItem("dynamic_car", data.dynamic_car_image);
        }
        var email = data.user_profile.user_email;
        var name = data.user_profile.name_eng;
        var wallet = data.user_profile.wallet;
        var card_detail = data.user_card_info;
        var skip_user = data.user_profile.is_skip_upfront_card_wallet;

        if (card_detail) {
          var exp = new Date(card_detail.expire_date.slice(0, 7) + "-01");
          var M = exp.getMonth() + 1;
          var Y = exp.getFullYear();
          card_detail.expire_date = (M < 10 ? "0" : "") + M + "/" + Y;
        }

        setCardDetail(card_detail);

        wallet = parseFloat(wallet);
        var is_block = data.user_profile.is_red_flag;
        var minAmount = parseFloat(enableFeaturesList && enableFeaturesList?.defualt_wallet);

        var diff = minAmount - wallet;
        setWalletDiff(diff.toFixed(3));

        if (is_block == "1") {
          setUserBlockModal(true);
          setUserBlock(true);
        } else {
          setUserBlockModal(false);
          setUserBlock(false);
          if (!email || email == "") {
            setAddEmailShow(true);
            setAddEmailModal(true);
          } else {
            setAddEmailShow(false);
            setAddEmailModal(false);
            if (!name) {
              setAddNameShow(true);
              setAddEmailModal(true);
            } else {
              setAddEmailModal(false);
              setAddNameShow(false);
              const payment_due = await paymentDue();
              if (payment_due.status == "1") {
                setShowPaymentDueModal(true);
              } else {
                if (!card_detail || (card_detail.is_tap_card_expired == "1") || (card_detail.is_card_invalid == "1")) {
                  if ((wallet < minAmount) && (skip_user == "0")) {
                    setAddWalletModal(true);
                    setAddWalletShow(true);
                  } else {
                    setAddWalletShow(false);
                    setAddWalletModal(false);
                  }
                }
              }
            }
          }
        }
        ctx.setLoader(false);
        return data;
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear();
          navigate("/login");
        }
        console.log("Error : get profile data", data);
        ctx.setLoader(false);
        return data;
      }
    } catch (error) {
      ctx.setLoader(false);
      console.log(error);
    }
  };

  const get_enable_features_list = async (e) => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      if (!enableFeatures && !ctx.enableFeaturesList) {
        var res = await enableFeaturesApi(form);
        var data = res.data;
        if (data.status == "1") {
          setEnableFeaturesList(data.enable_features);
          findBlockUserMessage(data.enable_features);
        } else {
          console.log("Error : get_enable_features_list", data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const near_search_location = async (lat, lng, isFromDestination = 0) => {
    try {
      ctx.setLoader(true)
      var data1 = {
        isFromDestination: isFromDestination,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        // latitude: lat ? lat : pickupPosition.lat,
        latitude: lat ?? pickupPosition?.lat,
        longitude: lng ?? pickupPosition?.lng,
        title_adress: "",
        car_selection_id: 0,
      };

      let form = new FormData();
      form.append("isFromDestination", data1.isFromDestination);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("latitude", data1.latitude);
      form.append("title_adress", data1.title_adress);
      form.append("longitude", data1.longitude);
      form.append("car_selection_id", data1.car_selection_id);

      var res = await nearSearchLocationApi(form);
      var data = res.data;
      if (data.message == "Invalid Auth token") {
        localStorage.clear();
        navigate("/login");
        return false;
      }
      var is_surcharge = data.is_surcharge == "1";
      if (rideType[1]) {
        is_surcharge = enableFeaturesList.is_pickup_later_surcharge == "1";
      }
      // console.log(data.is_pickup_later_surcharge , "surcharg key pickup later")

      // console.log(is_surcharge) 

      if (data1.is_airport_ride == "1") {
        setAirportRide(true)
      } else {
        setAirportRide(false)
      }

      if (is_surcharge) {
        setShowSurcharge(true);
        setSurchageData({
          is_display_surcharge_message: data.is_display_surcharge_message === "1",
          surchages_value: data.surchages_value,
          surcharge_value_title: data.surcharge_value_title,
          is_surcharge
        });
      }
      if (rideType[1] && enableFeaturesList && enableFeaturesList.is_pickup_later_surcharge == "0") {
        setShowSurcharge(false);
      }

      checkWalletForSpecialPickup(data, isFromDestination);
      console.log("pickupPosition", pickupPosition);
      setPickupPosition({
        ...pickupPosition,
        text: near_search_condition(data),
      });

      setNearSearchLocation(data);
      ctx.setLoader(false);
      return data;
    } catch (error) {
      ctx.setLoader(false);
      console.log("Error near_search_location", error);
    }finally{
      ctx.setLoader(false);
    }
  };

  const fetchCarTypes = async () => {
    try {
      // Assuming you need user type and user ID from state or localStorage
      const userDetail = JSON.parse(localStorage.getItem("user_data"));

      const data1 = {
        user_type: userDetail?.user_type || "0",
        user_id: userDetail?.user_profile_id || "",
      };

      const form = new FormData();
      Object.keys(data1).forEach(key => form.append(key, data1[key]));

      const res = await airportCarSelectionApi(form);
      const data = res.data;

      if (data.status == "1") {
        return data.airport_car_selection;
      } else {
        console.log("Error fetching car types:", data.message);
        return [];
      }
    } catch (error) {
      console.log("Error fetching car types:", error);
      return [];
    }
  };

  const get_driver = async (pickup_lat, pickup_long, near_call=true) => {
    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: pickup_lat ? pickup_lat : pickupPosition.lat,
        pickup_longitude: pickup_long ? pickup_long : pickupPosition.lng,
      };
      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);

      var res = await getDriverApi(form);
      var data = res.data;
      // if (data.isDriverAvailable == 1 && driverAvailable) {
      if(near_call){
        if (data.isDriverAvailable == 1) {
          /* clearInterval(intervalRef.current); // Stop the current interval
              intervalRef.current = setInterval(() => {
                if (userDetail && pickupPosition) {
                  near_search_location();
                }
              }, 1000 * 60 * 2); */ // Start a new cycle
          if (Object.keys(destinationPosition).length == 0) {
            if (!data.drivers && driverLength) {   // only show driver marker
              near_search_location();
              setDriverLength1(true);
              setDriverLength(false);
            }
            if (data.drivers && driverLength1) {
              near_search_location();
              setDriverLength(true);
              setDriverLength1(false);
            }
            // near_search_location();
          };
          setDriverNotAvailable(true);
          setDriverAvailable(false);
        }
        if (data.isDriverAvailable == 0 && driverNotAvailable) {
          /*   clearInterval(intervalRef.current); // Stop the current interval
                intervalRef.current = setInterval(() => {
                  if (userDetail && pickupPosition) {
                    near_search_location();
                  }
                }, 1000 * 60 * 2);  */ // Start a new cycle
          if (Object.keys(destinationPosition).length == 0) {
            near_search_location();
          };
          setDriverAvailable(true);
          setDriverNotAvailable(false);
          setDriverLength1(true);
          setDriverLength(true);
        }
      }
      if (data.status == "1") {
        setGetDriverDetail(data);
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear();
          navigate("/login");
        }
        setGetDriverDetail({ ...data });
        /*  toast("No driver found", {
          position: "top-center",
          autoClose: 2000,
        }); */
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const get_fare_break_down = async () => {
    try {
      var data1 = {
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat || 0.0,
        destination_longitude: destinationPosition.lng || 0.0,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        no_of_car: noOfCarForRide || 1,
        pickup_later: rideType[1] ? 1 : 0,
        is_airport_ride: rideType[3] ? 1 : 0,
        is_rental_car: rideType[2] ? 1 : 0,
        rental_hours_id: rideType[2] ? selectedCar.price.hours : 0,
        car_selection_id: rideType[2] ? selectedCar.price.multi_car_type_id : 0,
        promo_code: couponCode,
        car_type: 17, // get car type
        ride_fare: nearSearchLocation.pickup_base_price ?? 0,
        surchages_value: nearSearchLocation.is_surcharge == "1" ? 1 : 0,
      };


      let form = new FormData();
      form.append("no_of_car", data1.no_of_car);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("is_airport_ride", data1.is_airport_ride);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("pickup_later", data1.pickup_later);
      form.append("surchages_value", data1.surchages_value);
      form.append("is_rental_car", data1.is_rental_car);
      form.append("ride_fare", data1.ride_fare);
      form.append("rental_hours_id", data1.rental_hours_id);

      var res = await fareBreakDownApi(form);
      var data = res.data;
      if (data.status == "1") {
        setCarFareBreakdown(data.car_fare_breakdown);
      } else {
      }
    } catch (error) {
      console.log("get_fare_break_down" + error);
    }
  };

  const paymentDue = async () => {
    try {
      var form = new FormData();
      form.append("user_type", userDetail.user_type)
      form.append("customer_id", userDetail.user_profile_id)

      var res = await checkUnpaidRequest(form);
      var data = res.data;
      setPaymentDueData(data);
      return data;

    } catch (error) {
      console.log("Payment Due", error);
    }
  }

  const price_calculation = async (
    p_lat,
    p_lng,
    d_lat,
    d_lng,
    c_p,
    pickup_type = 0
  ) => {
    try {
      var data1 = {
        is_new_app: 1,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: p_lat ?? pickupPosition.lat,
        pickup_longitude: p_lng ?? pickupPosition.lng,
        car_selection_id: selectedCarType ? selectedCarType : 0,
        is_get_eta: 0,
        waiting_charge: 0,
        destination_latitude: d_lat ?? destinationPosition.lat,
        destination_longitude: d_lng ?? destinationPosition.lng,
        promo_code: c_p ?? "",
        car_type: 17,
        offer_price: 0,
        pickup_later: pickup_type,
      };

      let form = new FormData();
      form.append("is_new_app", data1.is_new_app);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("is_get_eta", data1.is_get_eta);
      form.append("waiting_charge", data1.waiting_charge);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("offer_price", data1.offer_price);
      form.append("pickup_later", data1.pickup_later);

      var res = await priceCalculateApi(form);
      var data = res.data;
      if (data.is_airport_ride == "1") {
        setAirportRide(true);
      } else {
        setAirportRide(false);
      }

      if (data.is_pickup_special_price && data.is_destination_special_price == 0) {
        setShowSpecialPickup(false);
      } else {
        setShowSpecialPickup(true);
      }
      console.log(data)
      var is_special_dest = data.is_destination_special_price == "1";
      var is_advance_payment = data.is_advance_payment == "1";
      var destination_base_price = parseFloat(data.destination_base_price);
      if (destination_base_price > 0) {
        setSpecialPickupData({ ...specialPickupData, is_special_dest, dest_price: data.destination_base_price });
      } else {
        is_special_dest = false;
        setSpecialPickupData({ ...specialPickupData, is_special_dest, dest_price: data.destination_base_price });
      }
      if (!is_special_dest && (specialPickupData && !specialPickupData.is_special_pickup)) {
        setShowSpecialPickup(false);
      };

      if (data.status == "1") {
        setPriceCalculation(data);
        var esti_pr =
          parseFloat(data.estimatedprice) - parseFloat(data.discount_price);
        esti_pr = esti_pr.toFixed(3);
        setEstimatedprice(esti_pr);
        setPriceCalculated(true)
        return data;
      } else {
        setPriceCalculation();
        setEstimatedprice("Loading...");
        setPriceCalculated(false);

      }
      setTimeout(() => {
      }, 1000);
    } catch (error) {
      console.log("price_calculation", error);
    }
  };

  const pickupAddressChange = async (e) => {
    const inputValue = e.target.value;

    if (inputValue === "") {
      if (airportRide) {
        setRecentPlaces([]);
        localStorage.removeItem("recent-places");
      }
      setCombinePlaces();
      console.log(pickupPosition);
      setPickupPosition();
      setRouteDirections();
      setPath();
      setZoom(13);
      if (currantPosition) {
        setPosition({
          lat: currantPosition.lat,
          lng: currantPosition.lng,
          text: "",
        });
      }
    } else {
      setTimeout(() => {
        if (inputRef.current.value !== "") {
          inputRef.current.addEventListener("input", () =>
            handleInputChange(inputRef.current.value)
          );
        } else {
          setCombinePlaces();
        }
      }, 700);
    }
  };

  const destinationAddressChange = (e) => {
    if (e.target.value == "") {
      // setCombinePlaces()
      setDestinationPosition({});
      setRouteDirections();
      setPath();
      if (currantPosition) {
        setPosition({
          lat: currantPosition.lat,
          lng: currantPosition.lng,
          text: "",
        });
      }
      setZoom(13);
      setRequestRide(false);
    } else {
      // inputRef2.current.addEventListener('input',()=> handleInputChange(inputRef2.current.value));
    }

    setTimeout(() => {
      if (inputRef2.current.value != "") {
        inputRef2.current.addEventListener("input", () =>
          handleInputChange(inputRef2.current.value)
        );
      } else {
        setCombinePlaces();
      }
    }, 700);
  };

  const clearDestination = () => {
    setDestinationPosition({});
    setRouteDirections();
    setPath();
    /* if (currantPosition) {
      setPosition({
        lat: currantPosition.lat,
        lng: currantPosition.lng,
        text: "",
      });
    // } */
    setZoom(13);
    setRequestRide(false);
    var des = document.querySelector("#Location2");
    des.value = "";
    handleDestinationClose();
  };

  const TimeZone = (time, format = "Do MMM YYYY, HH:mm") => {
    return moment.utc(time).tz("Asia/Kuwait").format(format);
  };

  const homeScreenNotification = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await homeScreenNotificationApi(form);
      var data = res.data;

      let currentISTTime = moment().tz("Asia/Kolkata");
      let kuwaitTime = currentISTTime.clone().tz("Asia/Kuwait");
      let kuwaitHour = kuwaitTime.hour();
      var message = "";
      // var current_time = "Thu Jul 25 2024 20:08:33 GMT+0530 (India Standard Time)";

      if (kuwaitHour >= 0 && kuwaitHour < 12) {
        message = "Good morning" + " " + userDetail.name_eng + "! ";
      } else if (kuwaitHour >= 12 && kuwaitHour < 16) {
        message = "Good afternoon" + " " + userDetail.name_eng + "! ";
      } else if (kuwaitHour >= 16 && kuwaitHour < 21) {
        message = "Good evening" + " " + userDetail.name_eng + "! ";
      } else if (kuwaitHour >= 21 && kuwaitHour < 24) {
        message = "Good evening" + " " + userDetail.name_eng + "! ";
      }

      data.show_menu_bar = false;

      if (data.status == "1") {
        var is_morning =
          data.is_message_morning_enable &&
          data.is_message_morning_enable == "1";
        var is_remove_greeting_welcome =
          data.is_remove_greetings_from_welcome_message &&
          data.is_remove_greetings_from_welcome_message == "1";

        if (data.home_screen_notification_box != "") {
          data.show_menu_bar = true;
          var redirection_id = parseInt(
            data.home_screen_notification_box?.redirection_id
          );
          data.redirection_id = redirection_id;
          message = data.home_screen_notification_box.message;
        } else if (is_remove_greeting_welcome) {
          data.show_menu_bar = false;
          message = "";
        } else if (is_morning && data.morning_message != "") {
          data.show_menu_bar = true;
          message = data.morning_message;
        }
        data.message = message;

        setHomeScreenData(data);
      } else {
        setHomeScreenData(data);
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePickupDrag = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    // setSpecialPickupData({...specialPickupData});
    near_search_location(lat, lng).then((data) => {

      var userLocation = data.user_location_data;

      var pic = document.querySelector("#Location1");
      pic.value = userLocation.title_address;
      // inputRef.current.value = userLocation.title_address;
      var p = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        // text: userLocation.address.suburb,
        text: near_search_condition(data),
        /* data.status == "1"
            ? "Closest ride to you " + userLocation.time_duration + " Min"
            : "" */ add: userLocation.title_address,
      };
      setPosition(p);
      setPickupPosition(p);
      get_driver(lat,lng,false);
      if (Object.keys(destinationPosition).length && p) {
        price_calculation(
          pickupPosition.lat,
          pickupPosition.lng,
          lat,
          lng
        ).then((p_r) => {
          var esti_pr =
            parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
          esti_pr = esti_pr.toFixed(3);
          setDestinationPosition({
            ...destinationPosition,
            text: "KD " + esti_pr,
          });
        });
        aftersetDestinationPosition(p, destinationPosition);
      }
    });

    if (pickupPosition && Object.keys(destinationPosition).length) {
      // near_search_location();
      // price_calculation();
    }
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const handleDestinationDrag = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    // setSpecialPickupData({...specialPickupData});
    near_search_location(lat, lng, 1).then((data) => {
      var userLocation = data.user_location_data;

      var d = {
        address: userLocation.title_address,
        latitude: parseFloat(userLocation.latitude),
        longitude: parseFloat(userLocation.longitude),
      };
      selectedDestination(d);
    });
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const handleFarePriceClick = () => {
    get_fare_break_down();
    setCarDetailShow(true);
  };

  const handleForMeClose = () => setForMeShow(false);
  const handleForMeShow = () => setForMeShow(true);
  const handleGuestClose = () => {
    setGuestShow(false);
    setForMeShow(true);
  };

  const handleGuestShow = () => {
    setGuestShow(true);
    setForMeShow(false);
  };

  // const handleForMeShow = () => setForMeShow(true);
  // const exampleModalClick = () => {
  //   $('#exampleModal').modal('show')
  // }

  const aftersetDestinationPosition = async (pickup, destination) => {
    if (Object.keys(pickup).length && Object.keys(destination).length) {
      if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
        var data = await getDirectionFromNextBillion(
          enableFeaturesList.nextbillion_api_key,
          pickup,
          destination
        );
        const route = data.routes[0].legs[0];
        const path = route.steps.map((step) => ({
          lat: step.start_location.latitude,
          lng: step.start_location.longitude,
        }));
        setPath(path);
      } else {
        DirectionsService.route(
          {
            origin: new google.maps.LatLng(pickup),
            destination: new google.maps.LatLng(destination),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              setRouteDirections(result);
              // setRequestRide(true);
            } else {
              console.log("err", result);
            }
          }
        );
      }
    }
  };

  const riderSectionHover = (e) => {
    e.preventDefault();
    var element = e.target;
    if (document.querySelector(".rider_section.active") != null) {
      document
        .querySelector(".rider_section.active")
        .classList.remove("active");
    }
    element.classList.add("active");
    return false;
  };

  const pickupLater = (e) => {
    document.getElementById("main").style.display = "none";
    document.getElementById("flip").style.display = "block";
  };




  // const fetchLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         if (result.state === "granted") {
  //           navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //               var lat = position.coords.latitude;
  //               var long = position.coords.longitude;
  //               // setSpecialPickupData({...specialPickupData});
  //               near_search_location(lat, long).then((data) => {
  //                 var userLocation = data.user_location_data;
  //                 var pic = document.querySelector("#Location1");
  //                 // inputRef.current.value = userLocation.title_address;

  //                 setPickupPosition({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                   // text: userLocation.address.suburb,
  //                   text: near_search_condition(data),
  //                   /* "Closest ride to you " +
  //                   userLocation.time_duration +
  //                   " Min" */ add: userLocation.title_address,
  //                 });
  //                 setPosition({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                   // text: userLocation.address.suburb,
  //                   // add: userLocation.display_name,
  //                 });
  //                 setCurrantPosition({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                   text: userLocation.title_address,
  //                   add: userLocation.title_address,
  //                 });
  //                 setZoom(13);
  //                 if (pic) {
  //                   pic.value = userLocation.title_address;
  //                 }
  //               })
  //             },
  //             (error) => {
  //               console.error("Error getting geolocation:", error);
  //             },
  //             { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
  //           );
  //         } else if (result.state === "prompt") {
  //           navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //               var lat = position.coords.latitude;
  //               var long = position.coords.longitude;
  //               // setSpecialPickupData({...specialPickupData});
  //               near_search_location(lat, long).then((data) => {
  //                 var userLocation = data.user_location_data;

  //                 var pic = document.querySelector("#Location1");
  //                 pic.value = userLocation.title_address;
  //                 // inputRef.current.value = userLocation.title_address;
  //                 setZoom(13);
  //                 setPickupPosition({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                   // text: userLocation.address.suburb,
  //                   text: near_search_condition(data),
  //                   /* "Closest ride to you " +
  //                     userLocation.time_duration +
  //                     " Min" */ add: userLocation.title_address,
  //                 });
  //                 setPosition({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                   // text: userLocation.address.suburb,
  //                   // add: userLocation.display_name,
  //                 });
  //                 setCurrantPosition({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                   text: userLocation.title_address,
  //                   add: userLocation.title_address,
  //                 });
  //               });
  //             },
  //             (error) => {
  //               console.error("Error getting geolocation:", error);
  //             },
  //             { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
  //           );
  //           alert('Location permisson issue "prompt"....');
  //         } else if (result.state === "denied") {
  //           alert('Location permisson  "denied"....');
  //         }
  //         /* result.onchange = function () {
  //           console.log(result.state);
  //         }; */
  //       });
  //   } else {
  //     alert("Sorry location not available!");
  //   }
  // };

  // const fetchLocation = () => {
  //   // if (!navigator.geolocation) {
  //   //   alert("Geolocation is not supported by your browser.");
  //   //   return;
  //   // }

  //   const getCurrentLocation = (options) => {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const lat = position.coords.latitude;
  //         const long = position.coords.longitude;

  //         // Call API to get nearby locations
  //         near_search_location(lat, long)
  //           .then((data) => {
  //             const userLocation = data.user_location_data;
  //             const pic = document.querySelector("#Location1");

  //             setPickupPosition({
  //               lat: parseFloat(userLocation.latitude),
  //               lng: parseFloat(userLocation.longitude),
  //               text: near_search_condition(data),
  //               add: userLocation.title_address,
  //             });

  //             setPosition({
  //               lat: parseFloat(userLocation.latitude),
  //               lng: parseFloat(userLocation.longitude),
  //             });

  //             setCurrantPosition({
  //               lat: parseFloat(userLocation.latitude),
  //               lng: parseFloat(userLocation.longitude),
  //               text: userLocation.title_address,
  //               add: userLocation.title_address,
  //             });

  //             setZoom(13);

  //             // Update input field
  //             if (pic) {
  //               pic.value = userLocation.title_address;
  //             }
  //           })

  //           .catch((error) => {
  //             console.error("Error fetching nearby location:", error);
  //             alert("Failed to fetch nearby location data.");
  //           });
  //       },
  //       (error) => {
  //         console.error("Error getting geolocation:", error);
  //         switch (error.code) {
  //           case error.PERMISSION_DENIED:
  //             alert("User denied the request for Geolocation.");
  //             break;
  //           case error.POSITION_UNAVAILABLE:
  //             alert("Location information is unavailable.");
  //             break;
  //           case error.TIMEOUT:
  //             alert("The request to get user location timed out.");
  //             break;
  //           default:
  //             alert("An unknown error occurred.");
  //             break;
  //         }
  //       },
  //       options
  //     );
  //   };

  //   // Attempt to get location without checking permissions
  //   getCurrentLocation({ enableHighAccuracy: true, timeout: 5000, maximumAge: 1000 });
  // };

  const getCurrentLocation = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChrome = /chrome|chromium|crios/i.test(navigator.userAgent);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;

        near_search_location(lat, long).then((data) => {
          if (data) {
            const userLocation = data.user_location_data;
            const pic = document.querySelector("#Location1");
            setPickupPosition({
              lat: parseFloat(userLocation.latitude),
              lng: parseFloat(userLocation.longitude),
              text: near_search_condition(data),
              add: userLocation.title_address,
            });
            setPosition({
              lat: parseFloat(userLocation.latitude),
              lng: parseFloat(userLocation.longitude),
            });
            setCurrantPosition({
              lat: parseFloat(userLocation.latitude),
              lng: parseFloat(userLocation.longitude),
              text: userLocation.title_address,
              add: userLocation.title_address,
            });
            setZoom(13);
            if (pic) {
              pic.value = userLocation.title_address;
            }
          }

        });
      },
      (error) => {
        if(isSafari){
          setIsModalOpen(true)
        }
        if(isChrome){
          setIsChromeModal(true)
        }
        console.error("Error getting geolocation:", error);
      },
      // { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
      // { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      { enableHighAccuracy: true, maximumAge: 2000 }
    );
  };



  // dhaval new below khatu
  const fetchLocation = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChrome = /chrome|chromium|crios/i.test(navigator.userAgent);
    if (navigator.geolocation) {
      // Check if navigator.permissions.query is available
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((result) => {
            if (result.state === "granted") {
              // if (!localStorage.getItem("locationFetched")) {
              setIsModalOpen(false)
              setIsChromeModal(false)
              getCurrentLocation();
              // localStorage.setItem("locationFetched", "true"); // Mark as fetched
              // }
              // getCurrentLocation();

            }
            else if (result.state === "prompt") {
              getCurrentLocation()
            }

            if (result.state === "denied") {
              if (isSafari) {
                setIsModalOpen(true);
              }
              if (isChrome) {
                setIsChromeModal(true)
              }
              // setShowLocationPrompt(true)
              alert('Location permission "denied"....');
            }
          })
          .catch((error) => {
            if (isSafari) {
              setIsModalOpen(true);
            }
            if (isChrome) {
              setIsChromeModal(true)
            }
            console.error("Error querying permissions:", error);
          });
      } else {
        // Fallback if permissions API is not supported
        getCurrentLocation();
      }
    } else {
      alert("Sorry, geolocation is not available!");
    }
  };


  // dhaval old below
  // const fetchLocation = () => {
  //   if (navigator.geolocation) {
  //     // Function to handle the logic for geolocation fetching
  //     askForLocation();


  //     /* if (navigator.permissions && navigator.permissions.query) {
  //       navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //         if (result.state === "granted") {
  //           // Proceed if permission is granted
  //           getLocation();
  //         } else if (result.state === "prompt") {
  //           alert('Location permission is required!');
  //           getLocation(); // Proceed to get location if prompt state
  //         } else if (result.state === "denied") {
  //           alert('Location permission denied, please enable location manually.');
  //         }
  //       });
  //     } else {
  //       // Fallback to the traditional geolocation method if permissions API is not supported
  //       getLocation();
  //     } */
  //   } else {
  //     alert("Sorry, location is not available on your device.");
  //   }
  // };



  const pickupLaterClose = (e) => {
    document.getElementById("flip").style.display = "none";
    document.getElementById("main").style.display = "block";
  };

  const near_search_condition = (data) => {
    if (data.status == "1") {
      if (data.isDisableArea == "1") {
        setRoveDoesNoteOperate(true);
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        setRoveDoesNoteOperate(false);
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        setRoveDoesNoteOperate(false);
        return "Waiting";
      } else {
        setRoveDoesNoteOperate(false);
        return (
          "Closest ride to you " +
          data.user_location_data.time_duration +
          " Min"
        );
      }
    } else if (data.status == "0") {
      if (data.isDisableArea == "1") {
        setRoveDoesNoteOperate(true);
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        setRoveDoesNoteOperate(false);
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        setRoveDoesNoteOperate(false);
        return "Waiting";
      } else if (
        parseInt(data.driver_login_count) > 0 &&
        data.near_by_car_count == "0"
      ) {
        setRoveDoesNoteOperate(true);
        return "Rove doesn't operate here";
      } else if (
        parseInt(data.driver_login_count) > 0 &&
        data.free_driver_count == "0"
      ) {
        setRoveDoesNoteOperate(false);
        return "All drivers are busy";
      } else {
        setRoveDoesNoteOperate(false);
        return "Driver not available";
      }
    }
  };

  const handleInputChange = (inputval) => {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const options = {
      input: inputval,
      fields: options1.fields,
      types: options1.types,
      componentRestrictions: options1.componentRestrictions,
    };
    /* autocompleteService.getPlacePredictions(options, (predictions) => {
      if (predictions) {
        const detailedPredictions = predictions.map((prediction, index) => {
          console.log(prediction.geometry);
          return {
          ...prediction,
          latitude: prediction.geometry.location.lat(),
          longitude: prediction.geometry.location.lng(),
          address: prediction.description,
          title: prediction.description,
          name: "",
          favorite_id: index + ""
        }
        });

        setCombinePlaces([...detailedPredictions, ...favPlaces]);
      }
    }); */
    if (inputval == "") {
      setCombinePlaces();
      // fetchFavoriteLocations();
      setFavPlaces(favPlacesOriginal);
      var rp = localStorage.getItem("recent-places");
      setRecentPlaces(JSON.parse(rp));
    } else {
      var filFav = favPlaces.requests.filter(
        (pl) =>
          pl.name.toLowerCase().includes(inputval.toLowerCase()) ||
          pl.address.toLowerCase().includes(inputval.toLowerCase())
      );

      var sugg = favPlaces.suggested_places.filter((pl) =>
        pl.address_title.toLowerCase().includes(inputval.toLowerCase())
      );

      if (recentPlaces && recentPlaces.length > 0) {
        var recFil = recentPlaces.filter((pl) =>
          pl.address.toLowerCase().includes(inputval.toLowerCase())
        );
        setRecentPlaces(recFil);
      }

      setFavPlaces((prevFavPlaces) => ({
        ...prevFavPlaces,
        requests: filFav,
        suggested_places: sugg,
      }));

      autocompleteService.getPlacePredictions(
        options,
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const placesService = new google.maps.places.PlacesService(
              document.createElement("div")
            );

            // Get place details for each prediction to get the geometry (latitude and longitude)
            const detailedPredictions = predictions.map((prediction) => {
              return new Promise((resolve, reject) => {
                placesService.getDetails(
                  { placeId: prediction.place_id, fields: options1.fields },
                  (placeDetails, placeStatus) => {
                    if (
                      placeStatus === google.maps.places.PlacesServiceStatus.OK
                    ) {
                      const detailedPlace = {
                        ...placeDetails,
                        description: prediction.description,
                      };
                      resolve(detailedPlace);
                    } else {
                      reject(placeStatus);
                    }
                  }
                );
              });
            });
            Promise.all(detailedPredictions)
              .then((places) => {
                if (places) {
                  const detailedPredictions = places.map((place, index) => {
                    return {
                      ...place,
                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng(),
                      address: place.description,
                      title: place.description,
                      name: "",
                      // favorite_id: index.toString()
                    };
                  });

                  /* var notmatch = filterNonFavoritePlaces(favPlaces.requests, detailedPredictions);
                console.log(notmatch); */
                  setCombinePlaces(detailedPredictions);
                }
              })
              .catch((error) => {
                console.error("dest Error fetching place details:", error);
              });
          }
        }
      );
    }
  };

  function filterNonFavoritePlaces(favPlaces, googlePredictedPlaces) {
    return googlePredictedPlaces.filter((googlePlace) => {
      return !favPlaces.some((favPlace) => {
        return (
          favPlace.address.slice(0, 30) === googlePlace.address.slice(0, 30)
        );
      });
    });
  }

  const onSelectChange = async (v) => {
    var val = v;
    // inputRef.current.value = null
    // inputRef2.current.value = null
    // setPickupPosition();
    // setDestinationPosition({});

    if (val == "0") {
      setShowPickupLater(false);
      setNoOfCars(false);
      // setAirportRide(false);
      setLongRide(false);
    }
    if (val == "1") {
      // later
      setShowPickupLater(true);
      setEstimatedprice()
      setNoOfCars(false);
      // setAirportRide(false);
      setLongRide(true);
      setRequestRide(false);
      // pickupLater();
    }
    if (val == "2") {
      //long
      await get_rental_car();
      setLongRide(true);
      setNoOfCars(true);
      setRouteDirections();
      setPath();
      setShowPickupLater(true);
      // setPickupPosition();
      // inputRef.current.value = null;
      var des = document.querySelector("#Location2");
      des.value = null;
      // inputRef2.current.value = null;
      setDestinationPosition({});
      // setAirportRide(false);
      setRequestRide(false);
      // pickupLater();
    }
    if (val == "3") {
      //airport
      setLongRide(true);
      setShowPickupLater(true);
      setAirportRide(true);
      setNoOfCars(false);
      setRequestRide(false);
      // pickupLater();
    }
  };

  const get_rental_car = async () => {
    try {
      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);

      var res = await getLongRentalCarApi(form);
      var data = res.data;
      if (data.status == "1") {
        setLongRidePopup(data);
        var rental_car = data.rental_car; // []
        // rental_car = ""    // check
        var rental_car_hours = data.rental_car_hours;
        if (rental_car != "" && rental_car != null) {
          //           rental_car = rental_car.map((car, index) => {
          //             car.price = rental_car_hours[0].multi_car_data[index];
          //             car.price.adp = car.price.advance_payment_amount;
          //             car.price.hp = car.price.hours_price;
          //             car.price = { ...car.price, hours: rental_car_hours[0].hours_value };
          //             return car;
          //           });

          //           setLongRideCars(rental_car);
          //         }
          //         var hours = rental_car_hours.map((h) => h.hours_value);
          //         var ho = {};
          //         rental_car_hours.map((h, index) => {
          //           if (index == 0) {
          //             ho[parseInt(h.hours_value)] = true;
          //             if (rental_car == "") {
          //               setSelectedCar({ ...h, price: { hp: +h.hours_price } })
          //             }
          //           } else {
          //             ho[parseInt(h.hours_value)] = false;
          //           }
          //         });
          //         setNoOfHours(ho);
          //         console.log(hours)   
          rental_car = rental_car.map((car, index) => {
            car.price = rental_car_hours[0].multi_car_data[index];
            car.price.adp = +car.price.advance_payment_amount;
            car.price.hp = +car.price.hours_price;
            car.price = { ...car.price, hours: rental_car_hours[0].hours_value };
            return car;
          });
          // selectLongrideCars(rental_car[0]);
          setLongRideCars(rental_car);
        } else {

          var ho = {};
          rental_car_hours.map((h, index) => {
            if (index == 0) {
              ho[parseInt(h.hours_value)] = true;
              if (rental_car == "") {
                setSelectedCar({ ...h, price: { hp: +h.hours_price } })
              }
            } else {
              ho[parseInt(h.hours_value)] = false;
            }
          });
          setNoOfHours(ho);
        }
        var hours = rental_car_hours.map((h) => h.hours_value);
        setHours(hours);
        return data;
      } else {
      }
    } catch (error) {
      console.log("dashbord get_rental_car", error);
    }
  };

  const getAddressFromLat = async (lat, lng) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };

      const results = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              resolve(results[0].formatted_address);
            } else {
              console.log("No results found");
              resolve(null); // Resolve with null if no results found
            }
          } else {
            reject(status); // Reject the promise with the status
          }
        });
      });

      return results;
    } catch (error) {
      console.log("getAddressFromLat", error);
      return null; // Return null in case of an error
    }
  };

  const validate_coupon = async () => {
    try {
      if (!couponCode.trim().length) {
        toast.error("Please enter coupon code", {
          position: "top-center",
          autoClose: 1000,
        });
        return false;
      }

      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        coupon_code: couponCode,
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat,
        destination_longitude: destinationPosition.lng,
        car_type: 17,
        pickup_later: rideType[0] ? 0 : 1, // make condition start
        timezone: "",
        no_of_ride: noOfCarForRide || 1, //condition over
        pickup_date_time: "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("coupon_code", data1.coupon_code);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("car_type", data1.car_type);
      form.append("pickup_later", data1.pickup_later);
      form.append("timezone", data1.timezone);
      form.append("no_of_ride", data1.no_of_ride);
      form.append("pickup_date_time", data1.pickup_date_time);

      var res = await validateCouponApi(form);
      var data = res.data;
      if (data.status == "1") {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        var remove_coupon = document.querySelector(".bi.bi-x-square");
        var apply_btn = document.querySelector(".apply-coupon");
        apply_btn.classList.add("d-none");
        remove_coupon.classList.remove("d-none");
        if (Object.keys(destinationPosition).length > 0) {
          price_calculation(
            pickupPosition.lat,
            pickupPosition.lng,
            destinationPosition.lat,
            destinationPosition.lng,
            couponCode
          ).then((data) => {
            if (data.status == "1") {
              var esti_pr = parseFloat(data.estimatedprice) - parseFloat(data.discount_price);
              esti_pr = esti_pr.toFixed(3);
              setEstimatedprice(esti_pr);
              setDestinationPosition({ ...destinationPosition, text: "KD " + esti_pr, })
            }
          });
        }
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        setCouponCode("");
      }
    } catch (error) {
      console.log("validate_coupon", error);
    }
  };

  const cancelCouponCodeHandle = async () => {
    try {
      var apply_btn = document.querySelector(".apply-coupon");
      apply_btn.classList.remove("d-none");
      var remove_coupon = document.querySelector(".bi.bi-x-square");
      remove_coupon.classList.add("d-none");
      setCouponCode("");
      price_calculation(
        pickupPosition.lat,
        pickupPosition.lng,
        destinationPosition.lat,
        destinationPosition.lng,
        ""
      ).then((data) => {
        if (Object.keys(destinationPosition).length > 0) {
          if (data.status == "1") {
            var esti_pr =
              parseFloat(data.estimatedprice) - parseFloat(data.discount_price);
            esti_pr = esti_pr.toFixed(3);
            setEstimatedprice(esti_pr);
            setDestinationPosition({ ...destinationPosition, text: "KD " + esti_pr, })
          }
        }
      });
    } catch (error) {
      console.log("cancelCouponCodeHandle", error);
    }
  };
  const handleReloadPage = () => {
    window.location.reload();
  };
  
  const PAYMENT_METHOD = (
    <div>
      {cardDetail ?
        <div
          className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode == 2 ? "select" : ""
            }`}
          style={{ cursor: "pointer" }}
          onClick={() => setSelectPayMode(2)}
        >
          <div className="col-md-6 d-flex align-items-center">
            <img
              src={cardDetail.card_brand == "VISA"
                ? "/assets/imgs/icons/visa@2x.png"
                : "/assets/imgs/icons/ic_mastercard-2.png"}
              alt="ic_mastercard-2@2x"
              width={30}
            />
            <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
              {`#### ${cardDetail.card_number}`}
            </p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <p className=" me-2 fw-bold fs-6 mb-0">
              Expires in : {cardDetail.expire_date}
            </p>
          </div>
        </div>
        :
        <div
          className={`row d-flex justify-content-between mb-3 p-2 ${forCreditCardShow ? "select" : ""
            }`}
          style={{ cursor: "pointer" }}
          onClick={() => setForCreditCardShow(true)}
        >
          <div className="col-md-6 d-flex align-items-center">
            <img
              src={"/assets/imgs/icons/card@2x.png"}
              alt="card@2x"
              width={30}
            />
            <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
              {"Add Credit Card"}
            </p>
          </div>
        </div>}
      <div
        className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode == 1 ? "select" : ""
          }`}
        style={{ cursor: "pointer" }}
        onClick={() => setSelectPayMode(1)}
      >
        <div className="col-md-12 d-flex align-items-center">
          <img
            src={"/assets/imgs/icons/knet_card@2x.png"}
            alt="knet_card@2x@2x"
            width={30}
          />
          <p className="ms-2 fw-bold fs-6 mb-0">KNET</p>
        </div>
      </div>
      <div
        className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode == 0 ? "select" : ""
          }`}
        onClick={() => setSelectPayMode(0)}
        style={{ cursor: "pointer" }}
      >
        <div className="col-md-12 d-flex align-items-center">
          <img
            src={"/assets/imgs/icons/cash@2x.png"}
            alt="cash@2x@2x"
            width={30}
          />
          <p className="ms-2 fw-bold fs-6 mb-0">Cash</p>
        </div>
      </div>
    </div>
  );

  const CHEVRON = <div>
    <i className="bi bi-chevron-down text-end"></i>
  </div>;

  const PAYMENT_SELECTION =
    (selelectPayMode == 2 ? (
      cardDetail && (
        <div
          className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode == 2 ? "select" : ""
            }`}
          onClick={() => setPaymentMethodModal(true)}
          style={{ cursor: "pointer" }}
        >
          <div className="col-md-12 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">

              <img
                src={
                  cardDetail
                    ? cardDetail.card_brand == "VISA"
                      ? "/assets/imgs/icons/visa@2x.png"
                      : "/assets/imgs/icons/ic_mastercard-2.png"
                    : "/assets/imgs/icons/card@2x.png"
                }
                alt="ic_mastercard-2@2x"
                width={30}
              />
              <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                {cardDetail
                  ? `#### ${cardDetail.card_number}`
                  : "Add Credit Card"}
              </p>
            </div>
            {CHEVRON}
          </div>
          {/* {cardDetail && (
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <p className=" me-2 fw-bold fs-6 mb-0">
                Expires in : {cardDetail && cardDetail.expire_date}
              </p>
            </div>
          )} */}
        </div>
        /*  <div className="row d-flex justify-content-between mb-3">
      <div className="col-md-6 d-flex align-items-center">
        <img
          src={
            cardDetail && cardDetail.card_brand == "VISA"
              ? "/assets/imgs/icons/visa@2x.png"
              : "/assets/imgs/icons/ic_mastercard-2.png"
          }
          alt="ic_mastercard-2@2x"
          width={25}
        />
        <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
          {cardDetail
            ? `#### ${cardDetail.card_number}`
            : "No card added"}
        </p>
      </div>
      <div className="col-md-6 d-flex align-items-center justify-content-end">
        <p className=" me-2 fw-bold fs-6 mb-0">
          Expires in : {cardDetail && cardDetail.expire_date}
        </p>
      </div>
    </div> */
      )
    ) : selelectPayMode == 1 ? (
      <div
        className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode == 1 ? "select" : ""
          }`}
        onClick={() => setPaymentMethodModal(true)}
        style={{ cursor: "pointer" }}
      >
        <div className="col-md-12 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={"/assets/imgs/icons/knet_card@2x.png"}
              alt="knet_card@2x@2x"
              width={30}
            />
            <p className="ms-2 fw-bold fs-6 mb-0">KNET</p>
          </div>
          {CHEVRON}
        </div>
      </div>
    ) : (
      <div
        className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode == 0 ? "select" : ""
          }`}
        onClick={() => setPaymentMethodModal(true)}
        style={{ cursor: "pointer" }}
      >
        <div className="col-md-12 d-flex align-items-center  justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={"/assets/imgs/icons/cash@2x.png"}
              alt="cash@2x@2x"
              width={30}
            />
            <p className="ms-2 fw-bold fs-6 mb-0">Cash</p>
          </div>
          {CHEVRON}
        </div>
      </div>
    ));

  const handlePaymentMethodSelect = () => { };

  const getAddressFromNextBillion = async (key, lat, lan) => {
    try {
      var api = "https://api.nextbillion.io/postalcode?key=" + key;
      var b = {
        at: { lat: lat, lng: lan },
      };
      var res = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(b),
      });
      var data = await res.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getDirectionFromNextBillion = async (key, pickup, destination) => {
    try {
      var origin = pickup.lat + "," + pickup.lng;
      var des = destination.lat + "," + destination.lng;
      var api =
        "https://api.nextbillion.io/directions/json?key=" +
        key +
        "&origin=" +
        origin +
        "&destination=" +
        des +
        "&mode=car&steps=true&overview=simplified";

      var res = await fetch(api, {
        method: "GET",
      });
      var data = await res.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMinusClick = () => {
    if (Object.keys(enableFeaturesList).length) {
      var min_car = 1;
      if (noOfCarForRide > min_car) {
        if (rideType[2]) {
          if (longRideCars) {
            var update = longRideCars.map((car) => {
              var new_price =
                parseFloat(car.price.hours_price) * (noOfCarForRide - 1);
              new_price = new_price.toFixed(3);

              var new_ad_price =
                parseFloat(car.price.advance_payment_amount) *
                (noOfCarForRide - 1);
              new_ad_price = new_ad_price.toFixed(3);

              car.price = {
                ...car.price,
                hp: +new_price,
                adp: +new_ad_price,
              };
              if (selectedCar) {
                if (selectedCar.id == car.id) {
                  setSelectedCar({
                    ...selectedCar,
                    price: {
                      ...selectedCar.price,
                      hp: +new_price,
                      adp: +new_ad_price,
                    },
                  });
                }
              }
              return car;
            });

            setLongRideCars(update);
            /*  if (selectedCar) {
               setSelectedCar({
                 ...selectedCar,
                 price: {
                   ...selectedCar.price,
                   hp: +(
                     parseFloat(selectedCar.price.hours_price) *
                     (noOfCarForRide - 1)
                   ).toFixed(3),
                   adp: +(
                     parseFloat(selectedCar.price.advance_payment_amount) *
                     (noOfCarForRide - 1)
                   ).toFixed(3),
                 },
               });
             } */
          } else {
            if (selectedCar) {
              setSelectedCar({
                ...selectedCar,
                price: {
                  ...selectedCar.price,
                  hp: +(
                    parseFloat(selectedCar.hours_price) *
                    (noOfCarForRide - 1)
                  ).toFixed(3),
                  adp: +(
                    parseFloat(selectedCar.advance_payment_amount) *
                    (noOfCarForRide - 1)
                  ).toFixed(3),
                },
              });
            }
          }
        }
        setNoOfCarForRide((prev) => prev - 1);
      }
    }
  };

  const handlePlusClick = () => {
    if (Object.keys(enableFeaturesList).length) {
      var max_car;

      if (rideType[2]) { // Long Ride
        max_car = parseInt(enableFeaturesList.max_rental_car_selection);
      } else if (rideType[1] && !airportRide) { // Pickup Later
        max_car = parseInt(enableFeaturesList.max_car_pickup_later_car_selection);
      } else if (airportRide) { // Airport Ride
        max_car = parseInt(enableFeaturesList.max_car_airport_ride_car_selection);
      } else {
        max_car = 3;
      }

      if (noOfCarForRide < max_car) {
        if (rideType[2]) { // Long Ride logic
          if (longRideCars) {
            var update = longRideCars.map((car) => {
              var new_price = parseFloat(car.price.hours_price) * (noOfCarForRide + 1);
              new_price = new_price.toFixed(3);

              var new_ad_price = parseFloat(car.price.advance_payment_amount) * (noOfCarForRide + 1);
              new_ad_price = new_ad_price.toFixed(3);

              car.price = {
                ...car.price,
                hp: +new_price,
                adp: +new_ad_price,
              };
              if (selectedCar) {
                if (selectedCar.id == car.id) {
                  setSelectedCar({
                    ...selectedCar,
                    price: {
                      ...selectedCar.price,
                      hp: +new_price,
                      adp: +new_ad_price,
                    },
                  });
                }
              }
              return car;
            });

            setLongRideCars(update);

            /*  if (selectedCar) {
               setSelectedCar({
                 ...selectedCar,
                 price: {
                   ...selectedCar.price,
                   hp: +(parseFloat(selectedCar.price.hours_price) * (noOfCarForRide + 1)).toFixed(3),
                   adp: +(parseFloat(selectedCar.price.advance_payment_amount) * (noOfCarForRide + 1)).toFixed(3),
                 },
               });
             } */
          } else {
            if (selectedCar) {
              setSelectedCar({
                ...selectedCar,
                price: {
                  hp: +(parseFloat(selectedCar.hours_price) * (noOfCarForRide + 1)).toFixed(3),
                  adp: +(parseFloat(selectedCar.advance_payment_amount) * (noOfCarForRide + 1)).toFixed(3),
                },
              });
            }
          }
        }
        setNoOfCarForRide((prev) => prev + 1);
      }
    }
  };

  const openLowWalletPopupForLongride = async (data) => {
    console.log(data);
    if ((data) || (walletDetail && walletDetail.total_walletBalance)) {
      // var wallet = userProfileData.user_profile.wallet;
      var wallet = data ? data.total_walletBalance : walletDetail.total_walletBalance;
      var card_detail = userProfileData.user_card_info;
      var skip_user = userProfileData.is_skip_upfront_card_wallet;

      var price_for_advance_pay = (selectedCar.advance_payment_amount || selectedCar.price.advance_payment_amount);
      // var price_for_advance_pay = selectedCar.advance_payment_amount;
      price_for_advance_pay = parseFloat(price_for_advance_pay);

      var total = parseFloat(noOfCarForRide) * price_for_advance_pay;
      console.log(total, noOfCarForRide);
      wallet = parseFloat(wallet);
      // console.log(wallet, total,noOfCarForRide);
      if ((wallet - total) < 0) {
        setWalletDiff((total - wallet).toFixed(3));
        setAdvancePaymentModal(false);
        setPaymentModal(true);
      } else {
        if (rideType[2]) {
          /*  1,
           couponCode,
           price_res.pickup_destination_time,
           time_duration,
           formattedKuwaitTime,
           Etp,
           r_f */
          var data = await driver_request(1, couponCode);
          advancePaymentModalClose();
          if (data.pickup_later_request_id) {
            navigate({
              pathname: "/my-trips",
              search: `?${createSearchParams({ upcoming: "1" })}`,
            });
          } else {
            toast.error(data.message, {
              position: "top-center",
              autoClose: 1500
            });
          }
        }
      }
    }
  };

  const advancePayAccept = () => {
    openLowWalletPopupForLongride();
  };

  const cancleRideInfo = async () => {
    localStorage.removeItem('rebookdata')
    setSpecialPickupData();
    setRequestRide(false);
    setPriceCalculation();
    // setPickupPosition();
    setPosition({ ...pickupPosition });
    setDestinationPosition({});
    setRouteDirections();
    setPath();
    setShowSpecialPickup(false);
    await near_search_location();
    /* var pic = document.querySelector("#Location1");
    pic.value = ""; */
    var des = document.querySelector("#Location2");
    des.value = "";
    // inputRef.current.value = "";
    // inputRef2.current.value = "";
    // fetchLocation();
  };

  const cancleRidePickupLater = () => {
    localStorage.removeItem('rebookdata')
    setLongRide(false);
    setPickupPosition();
    setDestinationPosition({});
    setRouteDirections();
    setPath();
    var pic = document.querySelector("#Location1");
    pic.value = "";
    var des = document.querySelector("#Location2");
    des.value = "";
    // inputRef.current.value = "";
    // inputRef2.current.value = "";
    fetchLocation();
  };

  var favpls;
  var savedPlacesMap;
  if (favPlaces && favPlaces.requests.length > 0) {
    favpls = showAllSavedPlaces
      ? favPlaces.requests
      : favPlaces.requests.slice(0, 5);

    savedPlacesMap = favpls.map((sp, index) => (
      <div
        className="my-3 d-flex align-items-center"
        style={{ cursor: "pointer" }}
        key={index}
        onClick={() => {
          if (searchPickup) {
            selectedPickupLoc(sp);
          } else {
            selectedDestination(sp);
          }
        }}
      >
        <img
          src={
            sp.address_type == "Home"
              ? "/assets/imgs/icons/ic_home.png"
              : sp.address_type == "Work"
                ? "/assets/imgs/icons/ic_work.png"
                : "/assets/imgs/icons/star.png"
          }
          alt="search@2x"
          width={30}
        />
        <div className="mx-2">
          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{sp.name}</h6>
          <p className="ms-2 mb-0">{sp.address}</p>
        </div>
      </div>
    ));
  }


  const add_fav_place = async () => {
    try {
      // var address_type = "Home";
      // if (addressType[1]) {
      //   address_type = "Home";
      // }
      // if (addressType[2]) {
      //   address_type = "Work";
      // }
      // if (addressType[3]) {
      // }
      var address_type = document.getElementById("name1").value;

      var latitute = document.getElementById("place_lat").value;
      var longitude = document.getElementById("place_long").value;
      var customAddress = document.getElementById("place_address").value;

      const form = new FormData();
      form.append("user_type", userDetail.user_type || "");
      form.append("User_Id", userDetail?.user_profile_id || "");
      form.append("address_type", address_type);
      form.append("Name", address_type);
      form.append("Latitude", latitute);
      form.append("Longitude", longitude);
      form.append("Address", customAddress);
      form.append("title", customAddress);

      const res = await addFavPlaceApi(form);

      if (res.data.status == "1") {
        toast("Saved Place Successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        setIsLoading(true);
        handleClose();
        fetchFavoriteLocations();
      } else {
        toast(res.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setIsLoading(true);
        console.log(
          "Error while adding saved places:",
          res.data.message || "Unknown error"
        );
      }
    } catch (error) {
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log("Error adding saved places:", error.message);
    }
  };

  const addToSavePlace = (e, val) => {
    if (e.currentTarget.classList.contains("disable")) {
      return false;
    }
    setShow(true);
    if (val == 0) {
      //pickup
      setSavedPlaceValue(0);
      var timeout = setTimeout(() => {
        document.getElementById("pick_up_save").src =
          "/assets/imgs/icons/Vector-fill.png";
        document.getElementById("place_address").value = pickupPosition.add;
        document.getElementById("place_lat").value = pickupPosition.lat;
        document.getElementById("place_long").value = pickupPosition.lng;
      }, 100);
      return () => clearTimeout(timeout);
    } else if (val == 1) {
      setSavedPlaceValue(1);
      var timeout = setTimeout(() => {
        document.getElementById("dest_save").src =
          "/assets/imgs/icons/Vector-fill.png";
        document.getElementById("place_address").value =
          destinationPosition.add;
        document.getElementById("place_lat").value = destinationPosition.lat;
        document.getElementById("place_long").value = destinationPosition.lng;
      }, 100);
      return () => clearTimeout(timeout);
    } else {
      setSavedPlaceValue();
    }
  };

  const fetchCustomer = async (userProfileData) => {
    if (userProfileData.user_profile.tap_customer_id != '') {

      setIsLoading(true)

      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append("customer_id", userProfileData.user_profile.tap_customer_id);

      var res = await retrieveTapCustomerApi(form);
      var data = res.data;

      setCustomerObj(data);
      /* if( data.cards ) {
        var cards = data.cards
        cards.map((card) => {
          if(card.brand == 'VISA') {
            card.brand_image = '/assets/imgs/icons/visa@2x.png'
          }
          else {
            card.brand_image = '/assets/imgs/icons/ic_mastercard-2.png'
          }
          return card;
        })
      }
      else {
        var cards = []
      } */
      // setSavedCards(cards)

      setIsLoading(false)
    }
  }

  const handleCardToken = async (token) => {
    ctx.setLoader(true);
    if (token.status == "ACTIVE") {

      setForCreditCardShow(false);
      setIsLoading(true);

      var token_name = token.card.name
      var token_name_split = token_name.split(' ')
      var token_first_name = ''
      var token_middle_name = ''
      var token_last_name = ''
      if (token_name_split.length > 2) {
        token_first_name = token_name_split[0]
        token_middle_name = token_name_split[1]
        token_last_name = token_name_split[2]
      }
      else if (token_name_split.length > 1) {
        token_first_name = token_name_split[0]
        token_last_name = token_name_split[1]
      }

      var save_card_form = new FormData();
      // save_card_form.append("tap_secret", tap_secret);
      save_card_form.append("currency", customerObj.currency);
      save_card_form.append("threeDSecure", "1");
      save_card_form.append("save_card", "1");
      save_card_form.append("token", token.id);
      save_card_form.append("customer_id", customerObj.id);
      // save_card_form.append("first_name", customerObj.first_name);
      // save_card_form.append("middle_name", customerObj.middle_name);
      // save_card_form.append("last_name", customerObj.last_name);
      save_card_form.append("first_name", token_first_name);
      save_card_form.append("middle_name", token_middle_name);
      save_card_form.append("last_name", token_last_name);
      save_card_form.append("email", customerObj.email);
      save_card_form.append("country_code", customerObj.phone.country_code.replace('+', ''));
      save_card_form.append("number", customerObj.phone.number);
      save_card_form.append("redirect_url", token.url);
      var save_card_res = await saveTapCardApi(save_card_form);
      // var save_card_data = save_card_res.data;
      if (save_card_res.data.status == "VALID") {

        var form = new FormData();
        // form.append("tap_secret", tap_secret);
        form.append("userId", userDetail.user_profile_id);
        form.append("user_type", userDetail.user_type);
        form.append("tap_card_id", token.card.id);
        form.append("tap_customer_id", userProfileData.user_profile.tap_customer_id);
        form.append("card_number", token.card.last_four);
        form.append("card_name", token.card.name);
        form.append("card_brand", token.card.brand);
        form.append("card_responce", "");

        var res = await addCardApi(form);
        paymentMethodModalClose();

        get_profile_data().then(res => {
          if (rideType[2]) {
            selectedCardTopup(walletDiff, res);
          }
        }).catch(e => console.log(e))

        //       await get_profile_data();
        //       setTimeout(() => {
        //         paybycard_ride_api()
        // await get_profile_data();
        setTimeout(() => {
          if (paymentDueData) {
            paybycard_ride_api()
          }
        }, 3000);
        ctx.setLoader(false);
      } else {
        toast.error("Card saved failed.", {
          position: "top-center",
          autoClose: 1500
        })
        ctx.setLoader(false);
      }
      // var data = res.data;

      setIsLoading(false);
      // fetchCustomer()
    }
  }

  const homeScreenNotificationClickHandler = () => {
    const redirection_id = homeScreenData.redirection_id;

    if ((redirection_id == 2) || (redirection_id == 3) && (userProfileData && userProfileData.user_profile.is_vip_user == "0")) {
      setAddWalletShow(true);
      setAddWalletModal(true);
    } else if (redirection_id == 4) {
      setPaymentDueModal(true);
    } else if (redirection_id == 1) {
      setAddEmailShow(true);
      setAddEmailModal(true);
    } else if (redirection_id == 5) {
      navigate("/coupons")
    }
  }

  const walletAddTapHandler = async (amount) => {
    var data1 = {
      user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
      user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
    };

    var form = new FormData();
    form.append("user_type", data1.user_type);
    form.append("user_id", data1.user_id);
    form.append("amount", amount);

    var res = await addWalletTapWebAPI(form);
    var data = res.data;
    if (data.status == "1") {
      let wallet_pay_url = data.wallet_pay_url;
      wallet_pay_url = wallet_pay_url.replace(
        "https://rovemain.rovesolution.com",
        "https://www.riderove.com/dev_rove"
      );
      // window.open(wallet_pay_url, '_blank');
      // var win = window.open(wallet_pay_url, "popup", "width=600,height=600");
      var win
      setTimeout(() => {
        win = window.open(wallet_pay_url, "popup", "width=600,height=600");
        // win = window.open(wallet_pay_url,'_blank');
      })
      var timer = setInterval(function () {
        if (win && win.closed) {
          clearInterval(timer);
          // paybywallet_api();
          setPaymentDueModal(false);
          paymentModalHandler()
          // get_profile_data().then(data => {
          user_wallet_detail().then(data => {
            console.log("HELLO OUTER");
            if (showWalletSpecialModal) {
              console.log("HELLO");
              setShowWalletSpecialModal(false);
              setShowSpecialPickup(false);
              setSpecialPickupModal(false);
              if (rideType[1]) {
                booklongRide();
              } else {
                requestNow();
              }
            }
            if (rideType[2]) {
              openLowWalletPopupForLongride(data)
              // driver_request(1,couponCode);
            }
          });

        }
      }, 1000);
    } else {
    }
  };


  const paybywallet_api = async () => {
    try {

      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("request_id", paymentDueData.unpaid_request_id[0].ride_request_id);

      var res = await paybyWalletApi(form);
      var data = res.data;
      return data;

    } catch (error) {
      console.error("paybywallet_api", error);
    }
  }

  const paybycard_ride_api = async () => {
    try {

      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("request_id", paymentDueData.unpaid_request_id[0].ride_request_id);

      var res = await paybyCardRideApi(form);
      var data = res.data;

      await get_profile_data();
      return data;

    } catch (error) {
      console.error("paybycard_ride_api", error);
    }
  }

  const selectedCardTopup = async (amount, data) => {
    try {
      ctx.setLoader(true);
      setSearchDisable(true);
      var form = new FormData();
      form.append("user_id", userDetail.user_profile_id);
      form.append("user_type", userDetail.user_type);
      form.append(
        "tap_customer_id", data ? data.user_profile.tap_customer_id :
        userProfileData.user_profile.tap_customer_id
      );
      form.append("tap_card_id", data ? data.user_card_info.tap_card_id : userProfileData.user_card_info.tap_card_id);
      form.append("amount", amount);

      var res = await addCardWalletAPI(form);
      var data = res.data;
      if (data.status == "1") {
        user_wallet_detail().then(async (data) => {
          if (rideType[1]) {
            booklongRide();
          } else if (rideType[2]) {
            openLowWalletPopupForLongride(data);
          } else {
            var ride = await driver_request();
            if (ride.status == "1") {
              localStorage.setItem("request_id", ride.request_id);
              localStorage.removeItem('rebookdata')
              navigate("/ride-detail/" + ride.request_id);
            }
          }
        });
      } else {
        setSearchDisable(false);
      }
      toast(data.message, {
        position: "top-center",
        autoClose: 3000,
      });
      ctx.setLoader(false);
    } catch (error) {
      ctx.setLoader(false);
      setSearchDisable(false);
      console.log("selectedCardTopup", error);
    }
  }

  const pickupInputClick = () => {
    setShowAllSavedPlaces(false);
    setSearchPickup(true);
    setTimeout(() => {
      inputRef.current.focus();

    }, 100);
  }

  const destinationInputClick = () => {
    if (!roveDoesNotOperate) {
      setSearchDestination(true);
      setShowAllSavedPlaces(false);
      setTimeout(() => {
        inputRef2.current.focus();
      }, 100);
    }
  }

  const selectPickupLaterCar = (car) => {
    let est = car.estimated_price_final;
    console.log(car)
    if (rideType[0]) {
      if (car.isDriverAvailable == "0") {
        toast.error("Please,choose another car.", {
          position: "top-center",
          autoClose: 1500
        });
        return false;
      }
    }

    if (est && (est != "") && Object.keys(destinationPosition).length > 0) {
      setDestinationPosition({ ...destinationPosition, text: "KD " + est });
    }
    setSelectedPickupCar(car);
  }

  const PICKUPLATERMULTICAR = () => {
    if ((requestRide || rideType[1]) && nearSearchLocation && Object.keys(destinationPosition).length == 0) {
      return nearSearchLocation.static_multi_carType_data.map((car, index) => {
        var image = car.multi_car_image.replace(
          "https://rovemain.rovesolution.com/",
          "https://www.riderove.com/dev_rove/"
        );
        return (
          <div
            key={car.id}
            className={`card mb-3 ${selectedPickupCar &&
              selectedPickupCar.display_name == car.display_name
              ? "active"
              : ""
              }`}
            style={{ cursor: "pointer" }}
            onClick={() => selectPickupLaterCar(car)}
          >
            <div className="card-body ">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-3 w-30">
                  <img src={image} alt="" width="100px" />
                </div>
                <div className="col-md-6 px-0">
                  <h5 className="mb-0 fw-bold">
                    {car.display_name}&nbsp;&nbsp;&nbsp;&nbsp;
                    <span title="Seating capacity">
                      <img
                        src="/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"
                        alt=""
                        width="13px"
                      />
                      &nbsp;{car.seating_capacity} &nbsp;
                      <i
                        className="bi bi-info-circle"
                        style={{ cursor: "pointer" }}
                        onClick={handleFarePriceClick}
                      ></i>
                    </span>
                  </h5>
                  <p className="mb-0">
                    {car.car_brands} &nbsp;
                    {/* <i
                      className="bi bi-info-circle"
                      style={{ cursor: "pointer" }}
                      onClick={handleFarePriceClick}
                    ></i> */}
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="">
                    {rideType[1] && <p className="text-end fw-bold mb-0 fs-6">
                      {car.price ? "KD " + car.price.hp.toFixed(3) : "No estimation price"}
                    </p>}
                    {requestRide && <p className="text-end fw-bold mb-0 fs-6">
                      {car.eta == "0" ? "Amount : \- " : (car.estimated_price_final != "") ? "KD " + car.estimated_price_final : "No estimation price"}
                    </p >}
                    {requestRide && <p className="text-end fw-bold mb-0 fs-6">{car.eta == "0" ? "No Cars Available" : "ETA : " + car.eta + " Min"}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    }
    if ((requestRide || rideType[1]) && priceCalculation && Object.keys(destinationPosition).length > 0) {
      return priceCalculation.multi_carType_data.map((car, index) => {
        var image = car.multi_car_image.replace(
          "https://rovemain.rovesolution.com/",
          "https://www.riderove.com/dev_rove/"
        );
        return (
          <div
            key={car.id}
            className={`card mb-3 ${selectedPickupCar &&
              selectedPickupCar.display_name == car.display_name
              ? "active"
              : ""
              }`}
            style={{ cursor: "pointer" }}
            onClick={() => selectPickupLaterCar(car)}
          >
            <div className="card-body ">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-3 w-30">
                  <img src={image} alt="" width="100px" />
                </div>
                <div className="col-md-6 px-0">
                  <h5 className="mb-0 fw-bold">
                    {car.display_name}&nbsp;&nbsp;&nbsp;&nbsp;
                    <span title="Seating capacity">
                      <img
                        src="/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"
                        alt=""
                        width="13px"
                      />
                      &nbsp;{car.seating_capacity} &nbsp;
                      <i
                        className="bi bi-info-circle"
                        style={{ cursor: "pointer" }}
                        onClick={handleFarePriceClick}
                      ></i>
                    </span>
                  </h5>
                  <p className="mb-0">
                    {car.car_brands} &nbsp;
                    {/* <i
                      className="bi bi-info-circle"
                      style={{ cursor: "pointer" }}
                      onClick={handleFarePriceClick}
                    ></i> */}
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="">
                    {rideType[1] && <p className="text-end fw-bold mb-0 fs-6">
                      {(car.estimated_price_final != "") ? "KD " + car.estimated_price_final : "No estimation price"}
                    </p >}
                    {requestRide && <p className="text-end fw-bold mb-0 fs-6">
                      {car.eta == "0" ? "Amount : \- " : (car.estimated_price_final != "") ? "KD " + car.estimated_price_final : "No estimation price"}
                    </p >}
                    {requestRide && <p className="text-end fw-bold mb-0 fs-6">{car.eta == "0" ? "No Cars Available" : "ETA : " + car.eta + " Min"}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    }
  }

  return (
    <main>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <section className="section mt-40 mb-100">
        <div className="container-fluid">
          <div className="row">

            <div className="col-md-3 col-lg-3">
              <div className="sidebar sidebar-dashboard ">
                <form
                  className="box-search-ride box-search-ride-style-3 wow fadeInUp"
                  method="post"
                >
                  <div id="main" className="w-100">
                    <h4 className="mb-3"> Request a Ride</h4>
                    <div className="box-form-search">
                      <div className="mb-10">
                        <div className="pickup-later pickup-button">
                          <div className="row ride-type">
                            <div className="col-md-6 col-lg-6 mb-10 w-50">
                              {" "}
                              <div
                                className={`d-flex align-items-center justify-content-center ${rideType[0] ? "select" : ""
                                  }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (showPaymentDueModal) {
                                      setPaymentDueModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(0);
                                      setRideType({
                                        0: true,
                                        1: false,
                                        2: false,
                                        3: false,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Pickup Now
                              </div>{" "}
                            </div>
                            <div className="col-md-6 col-lg-6 mb-10 w-50">
                              <div
                                className={`d-flex align-items-center justify-content-center ${rideType[1] ? "select" : ""
                                  }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (showPaymentDueModal) {
                                      setPaymentDueModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(1);
                                      setRideType({
                                        0: false,
                                        1: true,
                                        2: false,
                                        3: false,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Pickup Later
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 mb-10 w-100">
                              <div
                                className={`d-flex align-items-center justify-content-center ${rideType[2] ? "select" : ""
                                  }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (showPaymentDueModal) {
                                      setPaymentDueModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(2);
                                      setRideType({
                                        0: false,
                                        1: false,
                                        2: true,
                                        3: false,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Long Ride
                              </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-6 mb-10 w-50">
                              <div
                                className={`d-flex align-items-center justify-content-center ${rideType[3] ? "select" : ""
                                  }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (showPaymentDueModal) {
                                      setPaymentDueModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(3);
                                      setRideType({
                                        0: false,
                                        1: false,
                                        2: false,
                                        3: true,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Airport Ride
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="search-item search-from">
                        <div className="search-icon">
                          <div className="svg-location">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                              fill="none"
                              data-baseweb="icon"
                            >
                              <title>search</title>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm5-2a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>{" "}
                          <span className="item-icon icon-from"></span>
                        </div>
                        <div className="search-inputs">
                          <input
                            className="search-input dropdown-location"
                            type="text"
                            name="pick_up"
                            readOnly={true}
                            id="Location1"
                            placeholder="Pickup location"
                            onClick={pickupInputClick}
                          />
                        </div>
                        {pickupPosition &&
                          Object.keys(pickupPosition).length > 1 && (
                            <div
                              id="pickup_save_icon"
                              className="css-RvwPE"
                              onClick={(e) => addToSavePlace(e, 0)}
                            >
                              <div className="css-eJxVAS">
                                <img
                                  id="pick_up_save"
                                  src={"/assets/imgs/icons/Vector.png"}
                                  alt="ic_mastercard-2@2x"
                                  width={30}
                                />
                              </div>
                            </div>
                          )}
                      </div>
                      <div className={`search-item search-to ${noOfCars ? "d-none" : ""}`}>
                        <div className="search-icon">
                          <div className="svg-location">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                              fill="none"
                              data-baseweb="icon"
                            >
                              <title>search</title>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14 10h-4v4h4v-4ZM7 7v10h10V7H7Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>{" "}
                          <span className="item-icon icon-to"></span>
                        </div>
                        <div className="search-inputs">
                          <input
                            className="search-input dropdown-location"
                            type="text"
                            name="destination"
                            readOnly={true}
                            id="Location2"
                            placeholder="Dropoff location"
                            onClick={destinationInputClick}
                          />
                        </div>
                        {Object.keys(destinationPosition).length > 1 && (
                          <div
                            id="dest_save_icon"
                            className="css-RvwPE"
                            onClick={(e) => addToSavePlace(e, 1)}
                          >
                            <div className="css-eJxVAS">
                              <img
                                id="dest_save"
                                src={"/assets/imgs/icons/Vector.png"}
                                alt="ic_mastercard-2@2x"
                                width={30}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {showPickupLater && (
                        <>
                          <h6 className="mt-20 mb-20">
                            When do you want to be picked up?
                          </h6>
                          <div className="form-group">
                            <DatePicker
                              showIcon
                              icon="fa fa-calendar"
                              toggleCalendarOnIconClick
                              // isClearable
                              selected={startDate}
                              minDate={new Date()}
                              maxDate={maxDate}
                              withPortal
                              className="form-control"
                              showTimeSelect
                              timeFormat="p"
                              timeIntervals={10}
                              // timeInputLabel="Time:"
                              dateFormat="MM/dd/yyyy h:mm aa"
                              // showTimeInput
                              placeholderText="Enter date & time"
                              onChange={handleDateChange}
                            />
                          </div>
                        </>
                      )}
                      {(noOfCars || rideType[1]) && (
                        <div className="mb-20">
                          <div className="pickup-later pickup-button">
                            <h6>Number of cars: </h6>
                            <div className="row noofcars justify-content-evenly">
                              <div className="col-md-3 col-lg-3 w-25">
                                {" "}
                                <div
                                  className={`d-flex align-items-center justify-content-center`}
                                  style={{ padding: "0", cursor: "pointer" }}
                                  onClick={handleMinusClick}
                                >
                                  <i
                                    className="bi bi-dash"
                                    style={{ fontSize: "26px" }}
                                  ></i>
                                </div>{" "}
                              </div>
                              <div className="col-md-3 col-lg-3 w-25">
                                <div
                                  className={`d-flex align-items-center justify-content-center select`}
                                >
                                  {noOfCarForRide}
                                </div>
                              </div>
                              <div className="col-md-3 col-lg-3 w-25">
                                <div
                                  className={`d-flex align-items-center justify-content-center`}
                                  style={{ padding: "0", cursor: "pointer" }}
                                  onClick={handlePlusClick}
                                >
                                  <i
                                    className="bi bi-plus"
                                    style={{ fontSize: "0px" }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* HOURS SELECTON  */}
                      {noOfCars && (!airportRide) && (
                        <>
                          <div className="mb-20">
                            <div className="pickup-later pickup-button">
                              <h6>Number of hours: </h6>
                              <div className="hours d-flex  mb-10 pb-3">
                                {longRidePopup &&
                                  longRidePopup.rental_car_hours.map((h) => (
                                    <div
                                      className="col-md-3 col-lg-3 px-2"
                                      key={h.id}
                                    >
                                      {" "}
                                      <div
                                        style={{
                                          minWidth: "50px",
                                          cursor: "pointer",
                                        }}
                                        className={`d-flex align-items-center justify-content-center ${noOfHours[parseInt(h.hours_value)]
                                          ? "select"
                                          : ""
                                          }`}
                                        onClick={() => selectHours(h)}
                                      >
                                        {h.hours_name}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {showPickupLater && (
                        <>
                          <div>
                            <div className="d-flex">
                              <div className="pr-20">
                                <i className="fa fa-hourglass"></i>
                              </div>
                              <div>
                                <p>
                                  Extra wait time included to meet your ride
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="pr-20">
                                <i className="fa fa-credit-card"></i>
                              </div>
                              <div>
                                <p>
                                  Cancel at no charge up to 60 minutes in
                                  advance
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mb-30">
                            <button
                              type="button"
                              className="btn text-muted text-underline"
                              data-bs-toggle="modal"
                              data-bs-target="#termsModal"
                            >
                              See Terms
                            </button>
                          </div>
                        </>
                      )}
                      <div
                        className="search-item search-button mb-0 "
                        style={{ maxWidth: "100%" }}
                      >
                        {!showPickupLater && (
                          <button
                            className="btn btn-search"
                            type="button"
                            onClick={(e) => handleFormSubmit(e)}
                          >
                            {" "}
                            Search
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {longRide || rideType[3] ? (
              <div className="col-md-4 col-lg-4 mb-3">
                <div id="choose_ride_section">
                  <h3 className="fw-bold mb-10 text-center">Ride Details</h3>
                  <div
                    className="row d-flex justify-content-between mb-3"
                    onClick={() => {
                      navigate("/wallet");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="col-md-6 d-flex align-items-center w-50">
                      <img
                        src="/assets/imgs/icons/wallet@2x.png"
                        alt="wallet@2x"
                        width={25}
                      />
                      <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                        Wallet
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                      <p className="fw-bold fs-6 mb-0">
                        KD {walletDetail ? walletDetail.total_walletBalance : "Loading..."}
                      </p>
                    </div>
                  </div>
                  {!rideType[2] && PAYMENT_SELECTION}
                  <div className="car-selection">
                    {rideType[2] &&
                      longRideCars &&
                      longRideCars.map((car, index) => {

                        var image = car.multi_car_image.replace(
                          "https://rovemain.rovesolution.com/",
                          "https://www.riderove.com/dev_rove/"
                        );
                        return (
                          <div
                            key={car.id}
                            className={`card ${selectedCar &&
                              selectedCar.display_name == car.display_name
                              ? "active"
                              : ""
                              } my-2`}
                            style={{ cursor: "pointer" }}
                            onClick={() => selectLongrideCars(car)}
                          >
                            <div className="card-body ">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-md-3 w-30 col-3">
                                  <img src={image} alt="" width="100px" />
                                </div>
                                <div className="col-md-6 px-0 col-6">
                                  <h5 className="mb-0 fw-bold">
                                    {car.display_name}&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span title="Seating capacity">
                                      <img
                                        src="/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"
                                        alt=""
                                        width="13px"
                                      />
                                      &nbsp;{car.seating_capacity} &nbsp;
                                      <i
                                        className="bi bi-info-circle"
                                        style={{ cursor: "pointer" }}
                                        onClick={handleFarePriceClick}
                                      ></i>
                                    </span>
                                  </h5>
                                  {/*  <p className="mb-0">
                                    {car.car_brands} &nbsp;
                                    <i
                                      className="bi bi-info-circle"
                                      style={{ cursor: "pointer" }}
                                      onClick={handleFarePriceClick}
                                    ></i>
                                  </p> */}
                                </div>
                                <div className="col-md-3 col-3">
                                  <div className="">
                                    <p className="text-end fw-bold mb-0 fs-6">
                                      KD {car.price ? car.price.hp.toFixed(3) : "loading..."}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {rideType[1] && nearSearchLocation && nearSearchLocation["multi_carType_data"].length > 0 && PICKUPLATERMULTICAR()}
                    {(rideType[3] || rideType[1]) && nearSearchLocation && nearSearchLocation["multi_carType_data"].length == 0 && enableFeaturesList && ((enableFeaturesList.is_airport_ride == "1" && enableFeaturesList.is_airport_ride_car_selection == "1") || enableFeaturesList.is_all_pickup_later_ride_car_selection == 1) && (
                      carTypes.map((car) =>
                        <div
                          key={car.id}
                          className={`card ${selectedPickupCar &&
                            selectedPickupCar.display_name == car.display_name
                            ? "active"
                            : ""
                            }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => selectPickupLaterCar(car)}
                        >
                          <div className="card-body ">
                            <div className="row justify-content-center align-items-center">
                              <div className="col-md-3 col-3 w-30">
                                <i
                                  className="fa-solid fa-car-rear"
                                  style={{ color: "#1f3c4b", marginRight: '0px' }}
                                ></i>
                              </div>
                              <div className="col-md-6 col-6 px-0">
                                <h5 className="mb-0 fw-bold">
                                  {car.display_name}&nbsp;&nbsp;&nbsp;&nbsp;
                                  <i
                                    className="bi bi-info-circle"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleFarePriceClick}
                                  ></i>
                                </h5>
                              </div>

                            </div>
                          </div>
                        </div>
                      )
                      /*  <div className="car-selection-container mb-2">
                         <div className="car-display">
                           <i
                             className="fa-solid fa-car-rear"
                             style={{ color: "#1f3c4b", marginRight: '0px' }}
                           ></i>
                           <Form.Select
                             aria-label="Select Car Type"
                             value={selectedCarType}
                             onChange={handleCarTypeChange}
                             style={{
                               border: 'none',
                               borderRadius: '4px',
                               height: '38px'
                             }}
                           >
                             {carTypes.map((car) => (
                               <option key={car.id} value={car.id}>
                                 {car.display_name}
                                 {console.log(car)}
                               </option>
                             ))}
                           </Form.Select>
                         </div>
                       </div> */
                    )}

                    {/*  {rideType[1] && (
                      <div
                        className={`card ${carType.eco ? "active" : ""}`}
                        onClick={() =>
                          setCarType({
                            ...carType,
                            eco: true,
                            lux: false,
                            any: false,
                          })
                        }
                      >
                        <div className="card-body ">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-3 w-30">
                              <img
                                src={
                                  enableFeaturesList &&
                                    enableFeaturesList.is_car_selection == 0
                                    ? BASE_IMG_URL +
                                    enableFeaturesList.request_any_img
                                    : "/assets/imgs/ride_icons/car_type_luxury.png"
                                }
                                alt=""
                                width="100px"
                              />
                            </div>
                            <div className="col-md-5">
                              <h5
                                className="mb-0 fw-bold"
                                style={{ cursor: "pointer" }}
                                onClick={handleFarePriceClick}
                              >
                                {enableFeaturesList &&
                                  enableFeaturesList.fastest_car_name}
                                &nbsp;<i className="fa fa-user"></i>&nbsp;
                                {nearSearchLocation &&
                                  nearSearchLocation.free_driver_count}
                              </h5>
                              {showSurcharge && surchageData && (
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={handleFarePriceClick}
                                  className="fw-bold mb-0"
                                >
                                  <img
                                    src="/assets/imgs/icons/flash.png"
                                    alt="flash"
                                    width="15px"
                                  />&nbsp;
                                  {surchageData.surcharge_value_title}
                                </p>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <p className="text-end fw-bold mb-0 fs-6">
                                  {priceCalculation
                                    ? `KD ${estimatedprice}`
                                    : "No estimated price"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="row d-flex justify-content-between my-3">
                    <div className="col-md-6 d-flex align-items-center w-50">
                      <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                        Total Estimated Price
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                      {rideType[1] && <i
                        className="bi bi-info-circle me-2"
                        style={{ cursor: "pointer" }}
                        onClick={handleFarePriceClick}
                      ></i>}
                      <p className="fw-bold fs-6 mb-0">
                        {rideType[1] && (priceCalculation && estimatedprice
                          ? `KD ${parseFloat(
                            estimatedprice * noOfCarForRide
                          ).toFixed(3)}`
                          : "No estimated price")}
                        {rideType[2] && (selectedCar
                          ? `KD ${selectedCar.price.hp.toFixed(3)}`
                          : "No estimated price")}
                        {rideType[3] && (priceCalculation
                          ? `KD ${parseFloat(
                            estimatedprice * noOfCarForRide
                          ).toFixed(3)}`
                          : "No estimated price")}
                      </p>
                    </div>
                  </div>
                  {!rideType[2] && <div className="my-3">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "50px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "70%", height: "45px" }}
                        placeholder="Enter coupon code"
                        onChange={(e) => setCouponCode(e.target.value)}
                        value={couponCode}
                      />
                      <button
                        type="button"
                        onClick={validate_coupon}
                        className="btn btn-primary apply-coupon mx-3"
                        style={{ height: "45px" }}
                      >
                        Apply
                      </button>
                      <i
                        className="bi bi-x-square fs-1 mx-3 d-none"
                        onClick={cancelCouponCodeHandle}
                      ></i>
                    </div>
                  </div>}

                  <div className="fix-bottom">
                    <div className="d-flex">
                      <div className="d-flex justify-content-evenly w-100">
                        <button
                          type="button"
                          className="btn btn-outline-danger w-45 "
                          onClick={cancleRidePickupLater}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary w-45"
                          onClick={booklongRide}
                        >
                          Book
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {requestRide && !showPickupLater ? (
              <div className="col-md-5 col-lg-5 mb-3">
                <div id="choose_ride_section">
                  <h3 className="fw-bold mb-10 text-center">Ride Detail</h3>
                  <div
                    className="row d-flex justify-content-between mb-3"
                    onClick={() => {
                      navigate("/wallet");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="col-md-6 d-flex align-items-center w-50">
                      <img
                        src="/assets/imgs/icons/wallet@2x.png"
                        alt="wallet@2x"
                        width={25}
                      />
                      <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                        Wallet
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                      <p className="fw-bold fs-6 mb-0">
                        KD{" "}
                        {walletDetail ? walletDetail.total_walletBalance : "Loading..."}
                      </p>
                    </div>
                  </div>

                  {PAYMENT_SELECTION}
                  {nearSearchLocation && nearSearchLocation["multi_carType_data"].length > 0 && PICKUPLATERMULTICAR()
                    /*  nearSearchLocation.static_multi_carType_data.map((car, index) => {
                       var image = car.multi_car_image.replace(
                         "https://rovemain.rovesolution.com/",
                         "https://www.riderove.com/dev_rove/"
                       );
                       return (
                         <div
                           key={car.id}
                           className={`card ${selectedPickupCar &&
                             selectedPickupCar.display_name == car.display_name
                             ? "active"
                             : ""
                             }`}
                           style={{ cursor: "pointer" }}
                           onClick={() => setSelectedPickupCar(car)}
                         >
                           <div className="card-body ">
                             <div className="row justify-content-center align-items-center">
                               <div className="col-md-3 w-30">
                                 <img src={image} alt="" width="100px" />
                               </div>
                               <div className="col-md-6 px-0">
                                 <h5 className="mb-0 fw-bold">
                                   {car.display_name}&nbsp;&nbsp;&nbsp;&nbsp;
                                   <span title="Seating capacity">
                                     <img
                                       src="/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"
                                       alt=""
                                       width="13px"
                                     />
                                     &nbsp;{car.seating_capacity} &nbsp;
                                   <i
                                     className="bi bi-info-circle"
                                     style={{ cursor: "pointer" }}
                                     onClick={handleFarePriceClick}
                                   ></i>
                                   </span>
                                 </h5>
                                 <p className="mb-0">
                                   {car.car_brands} &nbsp;
                                 </p>
                               </div>
                               <div className="col-md-3">
                                 <div className="">
                                   <p className="text-end fw-bold mb-0 fs-6">
                                      {car.price ?"KD "+ car.price.hp.toFixed(3) : "No estimation price"}
                                   </p>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       );
                     }) */
                  }
                  <div className="row d-flex justify-content-between my-3">
                    <div className="col-md-6 d-flex align-items-center w-50">
                      <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                        Total Estimated Price
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                      {rideType[0] && <i
                        className="bi bi-info-circle me-2"
                        style={{ cursor: "pointer" }}
                        onClick={handleFarePriceClick}
                      ></i>}
                      <p className="fw-bold fs-6 mb-0">
                        {rideType[0] && (priceCalculation && estimatedprice
                          ? `KD ${parseFloat(
                            estimatedprice * noOfCarForRide
                          ).toFixed(3)}`
                          : "No estimated price")}
                      </p>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "50px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "70%", height: "45px" }}
                        placeholder="Enter coupon code"
                        onChange={(e) => setCouponCode(e.target.value)}
                        value={couponCode}
                      />
                      <button
                        className="btn btn-primary apply-coupon mx-3"
                        style={{ height: "45px" }}
                        type="button"
                        onClick={validate_coupon}
                      >
                        Apply
                      </button>
                      <i
                        className="bi bi-x-square fs-1 mx-3 d-none"
                        onClick={cancelCouponCodeHandle}
                      ></i>
                    </div>
                  </div>

                  <div className="fix-bottom">
                    <div className="d-flex">
                      <div className="d-flex justify-content-evenly w-100">
                        <button
                          type="button"
                          className="btn btn-outline-danger w-45 "
                          onClick={cancleRideInfo}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className={`${isButtonDisabled == true ? ' btn btn-primary w-45 disabled' : ' btn btn-primary w-45'}`}
                          // disabled={}
                          onClick={() => requestRideHandler(true)}
                        >

                          {isButtonDisabled || searchDisable ? <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner> : "Request Ride"}

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className={
                requestRide
                  ? `col-md-4 col-lg-4`
                  : longRide
                    ? `col-md-5 col-lg-5`
                    : `col-md-9 col-lg-9`
              }
            >
              <div id="map" className="">
                {/* <MapComponent /> */}

                {/* <marquee className="marquee_dashboard"> */}
                {homeScreenData && homeScreenData.show_menu_bar && (
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={homeScreenData.message}
                    readOnly={true}
                    name="home_screen_notification"
                    disabled={homeScreenData.redirection_id ? false : true}
                    onClick={homeScreenNotificationClickHandler}
                    style={{
                      background: "#eee",
                      textAlign: "center",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  />
                )}
                {/* </marquee> */}
                {position ? (
                  <MapWithAMarker
                      // containerElement={<div style={{ height: `80vh` }} />}
                      // mapElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: '500px', width: '100%' }} />}
                      mapElement={<div style={{ height: '100%', width: '100%' }} />}
                  zoom={zoom}
                  pickupPosition={pickupPosition}
                      position={position}
                  destinationPosition={destinationPosition}
                  handlePickupDrag={handlePickupDrag}
                  handleDestinationDrag={handleDestinationDrag}
                      // center={location || { lat: 37.7749, lng: -122.4194 }} 
                      routeDirections={routeDirections}
                      driverPositions={
                        getDriverDetail ? getDriverDetail.drivers : null
                      }
                  path={path}
                      dynamicCar={userProfileData ? userProfileData?.dynamic_car_image : ""}
                      fetchLocation={fetchLocation}
                />
                ) :
                  <Skeleton count={1} style={{ height: `80vh` }} />

                  // <div
                  //   className="d-flex justify-content-center align-items-center z-1"
                  //   style={{ backgroundColor: "rgba(255,255,255,0.6)", height: "80vh" }}
                  // >
                  //   <Spinner animation="border" role="status">
                  //     <span className="visually-hidden">Loading...</span>
                  //   </Spinner>
                  // </div>
                }
              </div>
            </div>
          </div>
        </div>

        {/* Special Pickup  */}
        <Modal
          show={specialPickupModal}
          onHide={specialPickupModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {specialPickupData && (specialPickupData.is_special_pickup && !specialPickupData.is_special_dest) && "Pickup Location"}
              {specialPickupData && (specialPickupData.is_special_pickup && specialPickupData.is_special_dest) && "ROVE"}
              {specialPickupData && (specialPickupData.is_special_dest && !specialPickupData.is_special_pickup) && "Destination Location"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-rove my-2 px-5 text-center">
                {specialPickupData && (specialPickupData.is_special_pickup && !specialPickupData.is_special_dest) && "Special rate will be applicable in this location."}
                {specialPickupData && (specialPickupData.is_special_dest && !specialPickupData.is_special_pickup) && `Rove does not operate in this area due to that we are charging extra KD ${specialPickupData.dest_price} base fare.`}
                {specialPickupData && enableFeaturesList && (specialPickupData.is_special_pickup && specialPickupData.is_special_dest) &&
                  enableFeaturesList.special_pickup_destination_message}
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={specialPickupModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Reject
            </button>
            <button className="btn btn-primary mx-3" onClick={handleSpecialPicupConfirm}>
              Accept
            </button>
          </Modal.Footer>
        </Modal>

        {/*   <Modal
          show={walletSpecialPickupModal}
          onHide={walletSpecialPickupModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Pickup Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-rove my-2 px-5 text-center">
                {`Topup your wallet up to KD ${(specialPickupData && specialPickupData.topup_price) ? specialPickupData.topup_price.toFixed(3): 0.000} to request ride from this area.`}
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={walletSpecialPickupModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Reject
            </button>
            <button className="btn btn-primary mx-3" onClick={specialTopupWallet}>
              Accept
            </button>
          </Modal.Footer>
        </Modal> */}

        {/* Fare Breakdown */}
        <Modal show={carDetailShow} onHide={() => setCarDetailShow(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Fare Breakdown</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center my-3">
              {/* {rideType[1] &&
              <>
                <img
                  src={
                    enableFeaturesList && enableFeaturesList.is_car_selection == 0
                      ? BASE_IMG_URL + enableFeaturesList.request_any_img
                      : "/assets/imgs/ride_icons/car_type_luxury.png"
                  }
                  alt="car type"
                  width="100px"
                />
                <h6 className="mt-3 mb-0 fw-bold">
                  {enableFeaturesList && enableFeaturesList.fastest_car_name}
                  &nbsp;<i className="fa fa-user"></i>&nbsp;
                  {nearSearchLocation && nearSearchLocation.free_driver_count}
                </h6>
              </>
              } */}
              {rideType[2] && selectedCar && <>
                {selectedCar.multi_car_image && <img
                  src={
                    selectedCar.multi_car_image
                      ? selectedCar.multi_car_image.replace(
                        "https://rovemain.rovesolution.com/",
                        "https://www.riderove.com/dev_rove/")
                      : "/assets/imgs/ride_icons/car_type_luxury.png"
                  }
                  alt="car type"
                  width="100px"
                />}
                <h6 className="mt-3 mb-0 fw-bold">
                  {selectedCar.display_name}   &nbsp;&nbsp;
                  <span title="Seating capacity ms-2">
                    <img
                      src="/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"
                      alt="Selected_SeatingCapicity_cartype"
                      width="15px"
                    />
                    &nbsp;{selectedCar.seating_capacity} &nbsp;
                  </span>
                </h6></>}
              {(rideType[1] || rideType[0]) && selectedPickupCar && <>
                {selectedPickupCar.multi_car_image &&
                  <img
                    src={
                      selectedPickupCar.multi_car_image
                        ? selectedPickupCar.multi_car_image.replace(
                          "https://rovemain.rovesolution.com/",
                          "https://www.riderove.com/dev_rove/")
                        : "/assets/imgs/ride_icons/car_type_luxury.png"
                    }
                    alt="car type"
                    width="100px"
                  />}
                <h5 className="mt-3 mb-0 fw-bold">
                  {selectedPickupCar.display_name}   &nbsp;&nbsp;
                  {selectedPickupCar.seating_capacity &&
                    <span title="Seating capacity">
                      <img
                        src="/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"
                        alt="Selected_SeatingCapicity_cartype"
                        width="15px"
                      />
                      &nbsp;{selectedPickupCar.seating_capacity} &nbsp;
                    </span>}
                </h5>
                <p className="fw-bold">{selectedPickupCar.car_brands}</p>
              </>}
            </div>

            <div className="my-2">
              {carFareBreakdown && carFareBreakdown ? (
                carFareBreakdown.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-9">
                      <h6 className="fw-bold">{item.title}</h6>
                    </div>
                    <div className="col-md-3">
                      <p className="text-end mb-0">
                        {item.is_surcharge == "1" &&
                          <img
                            src={
                              "/assets/imgs/icons/flash.png"
                            }
                            alt="flash"
                            width="15px"
                          />}&nbsp; KD {item.price}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" role="status" size="md">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary w-100"
              variant="primary"
              onClick={() => setCarDetailShow(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Surcharge  */}
        <Modal
          show={surchargeModal}
          onHide={surchargeModalClose}
          centered
        >
          {/* <Modal.Header closeButton onClick={() => setSurchargeModal(false)}>
            <Modal.Title>Long Rides</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div className="my-3 px-5">
              <h4 className="text-rove my-2 fw-bold text-center" >
                Fares are {surchageData ? surchageData.surchages_value : 1.3}x higher than usual.
              </h4>
              <p className="text-rove fw-bold my-2 text-center">
                your fare on this trip will be {surchageData ? surchageData.surchages_value : 1.3}x the normal fare.When demand is high,we increase rates to keep drivers available.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button className="btn btn-outline-danger w-50 " onClick={() => setSurchargeModal(false)}>
              Reject
            </button>
            <button className="btn btn-primary  w-50 " onClick={surchargeModalClose}>
              Accept
            </button>
          </Modal.Footer>
        </Modal>

        {/* Wallet Top-up  */}
        <Modal show={paymentModal} onHide={paymentModalHandler} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Top up your wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="row d-flex justify-content-between mb-3 p-3 "
              style={{
                background: "rgb(233 192 196 / 49%)",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-12  ">
                {/* <img
                  mailto:src="/assets/imgs/icons/wallet@2x.png"
                  alt="wallet@2x"
                  width={25}
                /> */}
                {walletDiff &&
                  <p
                    className=" ms-2 fw-bold fs-6 mb-0"
                    style={{ color: "#e3666" }}
                  >
                    Please top up you wallet by KD {walletDiff && walletDiff} to process with
                    this booking.
                  </p>}
              </div>
            </div>
            <div className="my-3">
              <h6 className="text-rove my-3 fw-bold">Select Payment Method</h6>
              {cardDetail ?
                <div
                  className={`row d-flex justify-content-between mb-3 p-2 `}
                  style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                  onClick={() => { selectedCardTopup(walletDiff); paymentModalHandler(); }}
                >
                  <div className="col-md-6 d-flex align-items-center">
                    <img
                      src={cardDetail.card_brand == "VISA"
                        ? "/assets/imgs/icons/visa@2x.png"
                        : "/assets/imgs/icons/ic_mastercard-2.png"}
                      alt="ic_mastercard-2@2x"
                      width={30}
                    />
                    <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                      {`#### ${cardDetail.card_number}`}
                    </p>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-end">
                    <p className=" me-2 fw-bold fs-6 mb-0">
                      Expires in : {cardDetail.expire_date}
                    </p>
                  </div>
                </div>
                :
                <div
                  className={`row d-flex justify-content-between mb-3 p-2`}
                  style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                  onClick={() => { setForCreditCardShow(true); paymentModalHandler(); }}
                >
                  <div className="col-md-6 d-flex align-items-center">
                    <img
                      src={"/assets/imgs/icons/card@2x.png"}
                      alt="card@2x"
                      width={30}
                    />
                    <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                      {"Add Credit Card"}
                    </p>
                  </div>
                </div>}
              <div
                className={`row d-flex justify-content-between mb-3 p-2 `}
                style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                onClick={() => walletAddTapHandler(walletDiff)}
              >
                <div className="col-md-12 d-flex align-items-center">
                  <img
                    src={"/assets/imgs/icons/knet_card@2x.png"}
                    alt="knet_card@2x@2x"
                    width={30}
                  />
                  <p className="ms-2 fw-bold fs-6 mb-0">KNET</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={paymentModalHandler}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-3"
              type="button"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              Top up KD {walletDiff.toFixed(3)}
            </button>
          </Modal.Footer> */}
        </Modal>

        {/* Select Payment Method */}
        <Modal
          show={paymentMethodModal}
          onHide={paymentMethodModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Select Payment Method</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-3">{PAYMENT_METHOD}</div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {/* <button
              className="btn btn-outline-danger"
              onClick={paymentMethodModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button> */}
            <button
              className="btn btn-primary mx-3"
              type="button"
              onClick={paymentMethodModalClose}
            >
              Select
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={forMeShow} onHide={handleForMeClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Switch rider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="d-flex rider_section active"
              onMouseEnter={riderSectionHover}
            >
              <div className="pr-30">
                <button type="button" className="btn">
                  <i className="fa fa-user"></i>
                </button>
              </div>
              <div className="">
                <p>Me</p>
              </div>
            </div>
            <div
              className="d-flex rider_section"
              onMouseEnter={riderSectionHover}
            >
              <div className="pr-30">
                <button type="button" className="btn">
                  <i className="fa fa-user-plus"></i>
                </button>
              </div>
              <div className="" onClick={handleGuestShow}>
                <p>Order ride for someone else</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary w-100"
              variant="primary"
              onClick={handleForMeClose}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>

        {/* pick_up location */}
        <Modal
          size="lg"
          show={searchPickup}
          onHide={handlePickupClose}
          centered
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Switch rider</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="container" style={{ minHeight: "600px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="search-item search-from "
                    id="location-pickup"
                  >
                    <label>
                      <input
                        type="text"
                        className="form-control"
                        // id="Location1"
                        placeholder="Pickup location"
                        ref={inputRef}
                        // value={pickupPosition.text}
                        name="pickup"
                        autoComplete="off"
                        onChange={pickupAddressChange}
                      // autoComplete="off"
                      />
                    </label>
                  </div>
                  <div className="d-flex justify-content-evenly mt-3">
                    <button
                      className="btn btn-locationmap w-45"
                      onClick={handlePickupClose}
                    >
                      Select location from map
                    </button>
                    <button
                      className="btn btn-locationmap w-45"
                      onClick={() => {
                        fetchLocation();
                        handlePickupClose();
                      }}
                    >
                      Go to currunt location
                    </button>
                  </div>
                  <div className="my-3">
                    {combinePlaces && (
                      <>
                        <h6 className="fw-bold mb-4 mt-5">Search Places</h6>
                        {combinePlaces.length &&
                          combinePlaces.map((pl, index) => (
                            <div
                              className="my-3 d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() => selectedPickupLoc(pl)}
                            >
                              <img
                                src="/assets/imgs/icons/search.png"
                                alt="wallet@2x"
                                width={30}
                              />
                              <div className="mx-2">
                                <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">
                                  {pl.address.split(",")[0]}
                                </h6>
                                <p className="ms-2 mb-0">{pl.address}</p>
                              </div>
                            </div>
                          ))}{" "}
                      </>
                    )}

                    {favPlaces &&
                      favPlaces.suggested_places &&
                      favPlaces.suggested_places.length > 0 && (
                        <>
                          <h6 className="fw-bold mb-3 mt-5 text-secondary">
                            Suggested Places
                          </h6>
                          {favPlaces.suggested_places.map((sp) => {
                            if (sp.is_sublocation == "1") {
                              return (
                                <>
                                  <div className="d-flex align-items-center">
                                    {/* <h6 className="fw-bold my-2 ">
                              {sp.address_title}
                            </h6> */}
                                    <img
                                      src="/assets/imgs/icons/Login_Logo.png"
                                      alt="wallet@2x"
                                      width={40}
                                    />
                                    <select
                                      name="suggested-place"
                                      id="suggested-place"
                                      className="form-select ms-3"
                                      onChange={handleSuggestedPickup}
                                    >
                                      <option value="0" defaultValue={true}>
                                        {sp.address_description}
                                      </option>
                                      {sp.sublocation.map((sl, index) => {
                                        sl.address = sl.name;
                                        return (
                                          <option
                                            value={JSON.stringify(sl)}
                                            key={index}
                                          >
                                            {sl.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </>
                      )}
                    {favPlaces && favPlaces.requests.length > 0 && (
                      <>
                        <div className="d-flex justify-content-between">
                          <h6 className="fw-bold mb-4 mt-5 text-secondary">
                            Saved Places
                          </h6>

                          {favPlaces.requests.length > 5 &&
                            !showAllSavedPlaces && (
                              <h6
                                className="fw-bold mb-4 mt-5"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowAllSavedPlaces(true)}
                              >
                                Show All
                              </h6>
                            )}
                        </div>
                        {savedPlacesMap}
                        {/* {favPlaces.requests.map((sp, index) =>
                        <div className="my-3 d-flex align-items-center" style={{cursor:"pointer"}} key={index} onClick={()=>selectedPickupLoc(sp)}>
                        <img
                          src={sp.address_type == "Home" ?  "/assets/imgs/icons/ic_home.png": (sp.address_type == "Work" ? "/assets/imgs/icons/ic_work.png" :"/assets/imgs/icons/star.png")}
                          alt="search@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{sp.name}</h6> 
                          <p className="ms-2 mb-0">{sp.address}</p> 
                        </div>
                      </div>
                        )} */}
                      </>
                    )}
                    {recentPlaces && recentPlaces.length > 0 && (
                      <>
                        <div className="d-flex justify-content-between">
                          <h6 className="fw-bold mb-4 mt-5 text-secondary">
                            Recent Places
                          </h6>
                          <h6
                            className="fw-bold mb-4 mt-5"
                            style={{ cursor: "pointer" }}
                            onClick={handleClearRecentPlaces}
                          >
                            Clear All
                          </h6>
                        </div>
                        <div>
                          {recentPlaces.length > 0 &&
                            recentPlaces.map((pl, index) => {
                              if (index < 5) {
                                return (
                                  <div
                                    className="my-3 d-flex align-items-center"
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => selectedPickupLoc(pl)}
                                  >
                                    <img
                                      src="/assets/imgs/icons/icn_recent.png"
                                      alt="search@2x"
                                      width={30}
                                    />
                                    <div className="mx-2">
                                      <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">
                                        {pl.address.split(",")[0]}
                                      </h6>
                                      <p className="ms-2 mb-0">{pl.address}</p>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* destination location */}
        <Modal
          size="lg"
          show={searchDestination}
          onHide={handleDestinationClose}
          centered
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Switch rider</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="container" style={{ minHeight: "600px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className={`search-item search-from ${noOfCars ? "d-none" : ""
                      }`}
                    id="location-destination"
                  >
                    <label>
                      <input
                        type="text"
                        className="form-control"
                        // id="Location2"
                        placeholder="Dropoff location"
                        ref={inputRef2}
                        name="destination"
                        autoComplete="off"
                        onChange={destinationAddressChange}
                      />
                    </label>
                  </div>
                  <div className=" d-flex justify-content-evenly mt-3">
                    <button
                      className="btn btn-locationmap w-45"
                      onClick={selectDestinationFromMap}
                    >
                      Select location from map
                    </button>
                    {destinationPosition &&
                      Object.keys(destinationPosition).length > 1 && (
                        <button
                          className="btn btn-locationmap w-45"
                          onClick={clearDestination}
                        >
                          Skip destination
                        </button>
                      )}
                  </div>
                  <div className="my-3">
                    {combinePlaces && (
                      <>
                        <h6 className="fw-bold mb-4 mt-5">Search Places</h6>

                        {combinePlaces.length &&
                          combinePlaces.map((pl, index) => (
                            <div
                              className="my-3 d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() => selectedDestination(pl)}
                            >
                              <img
                                src="/assets/imgs/icons/search.png"
                                alt="wallet@2x"
                                width={30}
                              />
                              <div className="mx-2">
                                <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">
                                  {pl.address.split(",")[0]}
                                </h6>
                                <p className="ms-2 mb-0">{pl.address}</p>
                              </div>
                            </div>
                          ))}
                      </>
                    )}

                    {favPlaces &&
                      favPlaces.suggested_places &&
                      favPlaces.suggested_places.length > 0 && (
                        <>
                          <h6 className="fw-bold mb-3 mt-5 text-secondary">
                            Suggested Places
                          </h6>
                          {favPlaces.suggested_places.map((sp) => {
                            if (sp.is_sublocation == "1") {
                              return (
                                <>
                                  <div className="d-flex align-items-center">
                                    {/* <h6 className="fw-bold my-2 ">
                              {sp.address_title}
                            </h6> */}
                                    <img
                                      src="/assets/imgs/icons/Login_Logo.png"
                                      alt="wallet@2x"
                                      width={40}
                                    />
                                    <select
                                      name="suggested-place"
                                      id="suggested-place"
                                      className="form-select ms-3"
                                      onChange={handleSuggestedDestination}
                                    >
                                      <option value="0" defaultValue={true}>
                                        {sp.address_description}
                                      </option>
                                      {sp.sublocation.map((sl, index) => {
                                        sl.address = sl.name;
                                        return (
                                          <option
                                            value={JSON.stringify(sl)}
                                            key={index}
                                          >
                                            {sl.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </>
                      )}
                    {favPlaces && favPlaces.requests.length > 0 && (
                      <>
                        <div className="d-flex justify-content-between">
                          <h6 className="fw-bold mb-4 mt-5 text-secondary">
                            Saved Places
                          </h6>

                          {favPlaces.requests.length > 5 &&
                            !showAllSavedPlaces && (
                              <h6
                                className="fw-bold mb-4 mt-5"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowAllSavedPlaces(true)}
                              >
                                Show All
                              </h6>
                            )}
                        </div>
                        {savedPlacesMap}

                        {/* {favPlaces.requests.map((sp, index) =>
                        <div className="my-3 d-flex align-items-center" key={index} style={{cursor:"pointer"}} onClick={()=>selectedDestination(sp)}>
                        <img
                          src={sp.address_type == "Home" ?  "/assets/imgs/icons/ic_home.png": (sp.address_type == "Work" ? "/assets/imgs/icons/ic_work.png" :"/assets/imgs/icons/star.png")}
                          alt="search@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{sp.name}</h6> 
                          <p className="ms-2 mb-0">{sp.address}</p> 
                        </div>
                          
                      </div>
                        )} */}
                      </>
                    )}
                    {recentPlaces && recentPlaces.length > 0 && (
                      <>
                        <div className="d-flex justify-content-between">
                          <h6 className="fw-bold mb-4 mt-5 text-secondary">
                            Recent Places
                          </h6>
                          <h6
                            className="fw-bold mb-4 mt-5"
                            style={{ cursor: "pointer" }}
                            onClick={handleClearRecentPlaces}
                          >
                            Clear All
                          </h6>
                        </div>
                        <div>
                          {recentPlaces.length > 0 &&
                            recentPlaces.map((pl, index) => (
                              <div
                                className="my-3 d-flex align-items-center"
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectedDestination(pl)}
                              >
                                <img
                                  src="/assets/imgs/icons/icn_recent.png"
                                  alt="search@2x"
                                  width={30}
                                />
                                <div className="mx-2">
                                  <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">
                                    {pl.address.split(",")[0]}
                                  </h6>
                                  <p className="ms-2 mb-0">{pl.address}</p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* // block user  */}
        <Modal
          show={userBlockModal}
          onHide={handleUserBlockModalClose}
          centered
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Switch rider</Modal.Title>  */}
          </Modal.Header>
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-center text-rove my-2">
                {userBlockMessage ? userBlockMessage : ""}
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={handleUserBlockModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-3"
              onClick={() => {
                navigate("/profile");
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>

        {/* advance payment  */}
        <Modal
          show={advancePaymentModal}
          onHide={advancePaymentModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Long Rides</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-rove my-2">
                Advance payment required to facilitate long rides.
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={advancePaymentModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button className="btn btn-primary mx-3" onClick={advancePayAccept}>
              Accept
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={addEmailModal} onHide={handleAddEmailModalClose} centered>
          {/*   <Modal.Header closeButton>
             <Modal.Title>Switch rider</Modal.Title> 
          </Modal.Header> */}
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-center text-rove my-2">
                {addEmailShow
                  ? "Please add your email address."
                  : "Your profile is incomplete."}
              </h4>
              {addEmailShow ? (
                <p className="text-center mt-2 fw-bold">
                  To be able to send payment receipts,
                  <br />
                  we need your email address.
                </p>
              ) : (
                <p className="text-center mt-2 fw-bold">
                  Please, set up your profile.
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={handleAddEmailModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-3"
              onClick={() => {
                navigate("/profile");
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={addWalletModal} onHide={handleAddWalletModalClose} centered >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div
              className="row d-flex justify-content-between mb-3 p-3"
              style={{
                background: "rgb(233 192 196 / 49%)",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-12 d-flex align-items-center w-50">
                <img
                  src="/assets/imgs/icons/wallet@2x.png"
                  alt="wallet@2x"
                  width={25}
                />
                <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">Wallet</p>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                <p className="fw-bold fs-6 mb-0">
                  KD {walletDetail ? walletDetail.total_walletBalance : "Loading..."}
                </p>
              </div>
            </div>
            <div className="my-3">
              {cardDetail && cardDetail.is_tap_card_expired == "1" ? <h5 className="text-center text-danger fw-bold my-2 mb-4">
                YOUR SAVED CARD IS EXPIRED
              </h5> :
                <h5 className="text-center fw-bold text-danger my-2 mb-4">
                  NO CREDIT CARD SAVED
                </h5>}

              <p className="text-center mt-2 fw-bold">
                {cardDetail && cardDetail.is_tap_card_expired == "1" ? "You can't book a ride, because your saved card is expired on your account and your balance is less than" : "You can't book a ride, because you have no credit card saved on your account and your balance is less than"} <br />
                {enableFeaturesList ? (
                  <h5 className="fw-bold text-rove my-3">
                    KD {enableFeaturesList.defualt_wallet}
                  </h5>
                ) : (
                  <h5 className="fw-bold text-rove my-3">KD 5.000</h5>
                )}
              </p>
              <p className="text-center mt-2 fw-bold">
                You need to top up your balance or have a credit card saved on
                your account.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-primary mx-3 w-100"
              onClick={() => {
                navigate("/wallet", { state: { topUp: walletDiff } });
              }}
            >
              Top up KD {walletDiff ? walletDiff : "5.000"}
            </button>
            <button
              className="btn btn-primary mx-3 w-100"
              onClick={() => {
                navigate("/payments");
              }}
            >
              Add Credit Card
            </button>
          </Modal.Footer>
        </Modal>


        <Modal show={paymentDueModal} onHide={paymentDueModalClose} centered >
          <Modal.Header closeButton>Payment Due</Modal.Header>
          <Modal.Body>
            <div
              className="row d-flex justify-content-between mb-3 p-3"
              style={{
                background: "rgb(233 192 196 / 49%)",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-12 d-flex align-items-center w-50">
                <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">Your last ride fare</p>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                <p className="fw-bold fs-6 mb-0">
                  {paymentDueData && paymentDueData?.unpaid_request_id && paymentDueData?.unpaid_request_id?.length > 0 ? "KD " + paymentDueData.unpaid_request_id[0].amount : "Loading..."}
                </p>
              </div>
            </div>
            <div className="my-3">
              <h5 className="text-center fw-bold text-rove my-2">
                Select Payment Method
              </h5>
              {cardDetail ?
                <div
                  className={`row d-flex justify-content-between mb-3 p-2 `}
                  style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                  onClick={() => { paybycard_ride_api(); setPaymentDueModal(false); }}
                >
                  <div className="col-md-6 d-flex align-items-center">
                    <img
                      src={cardDetail.card_brand == "VISA"
                        ? "/assets/imgs/icons/visa@2x.png"
                        : "/assets/imgs/icons/ic_mastercard-2.png"}
                      alt="ic_mastercard-2@2x"
                      width={30}
                    />
                    <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                      {`#### ${cardDetail.card_number}`}
                    </p>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-end">
                    <p className=" me-2 fw-bold fs-6 mb-0">
                      Expires in : {cardDetail.expire_date}
                    </p>
                  </div>
                </div>
                :
                <div
                  className={`row d-flex justify-content-between mb-3 p-2`}
                  style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                  onClick={() => { setForCreditCardShow(true); setPaymentDueModal(false); }}
                >
                  <div className="col-md-6 d-flex align-items-center">
                    <img
                      src={"/assets/imgs/icons/card@2x.png"}
                      alt="card@2x"
                      width={30}
                    />
                    <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                      {"Add Credit Card"}
                    </p>
                  </div>
                </div>}
              <div
                className={`row d-flex justify-content-between mb-3 p-2 `}
                style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                onClick={() => walletAddTapHandler((paymentDueData && paymentDueData.unpaid_request_id.length > 0) &&
                  paymentDueData.unpaid_request_id[0].amount)}
              >
                <div className="col-md-12 d-flex align-items-center">
                  <img
                    src={"/assets/imgs/icons/knet_card@2x.png"}
                    alt="knet_card@2x@2x"
                    width={30}
                  />
                  <p className="ms-2 fw-bold fs-6 mb-0">KNET</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/*  <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-primary mx-3 w-100"
              onClick={paymentDueModalClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-3 w-100"
              onClick={() => {
                // navigate("/wallet");
              }}
            >
              Pay
            </button>
          </Modal.Footer> */}
        </Modal>

        <Modal show={guestShow} onHide={handleGuestClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Guest rides</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <img src="/assets/imgs/img5.jpg" /> */}
            <img src="/assets/imgs/bg/img5.jpg" alt="" />
            <h4 className="model_title">Booking for someone else?</h4>
            <p>
              Now both you and your guest will get trip information in the app
              or by text.
            </p>
            <ul style={{ margin: "0", padding: "0" }}>
              <li>
                <div className="d-flex ">
                  <div className="mr-25">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M1 1v22h1l5-5h16V1H1Zm17 10H6V8h12v3Z"
                        fill="currentColor"
                      />
                      <title>Speech bubble</title>
                    </svg>
                  </div>
                  <div>
                    <p>Make pickup easy</p>
                    <p>
                      Guests can easily find the right vehicle or contact their
                      driver without you.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex ">
                  <div className="mr-25">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <title>Shield check</title>
                      <path
                        d="M12 1C6.68 1 2 3.04 2 3.04v11.9c0 4.84 5.57 7.15 10 9.06 4.4-1.89 10-4.2 10-9.06V3.04S17.5 1 12 1Zm-1 16.12-5.56-5.56 2.12-2.12L11 12.88l5.94-5.94 2.12 2.12L11 17.12Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div>
                    <p>Follow their ride</p>
                    <p>
                      You can follow your guest's ride from start to finish.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex ">
                  <div className="mr-25">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <title>Credit card</title>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 4h22v4H1V4Zm0 7h22v9H1v-9Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div>
                    <p>Pay with your account</p>
                    <p>Pay for your guest's trip when you request the ride.</p>
                  </div>
                </div>
              </li>
            </ul>
          </Modal.Body>
        </Modal>

        <div className="modal fade" id="termsModal" aria-labelledby="termsModalLabel" aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content ">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="termsModalLabel">
                  Rove Reserve Terms and Conditions
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
              </div>
              <div className="modal-footer d-block">
                <button
                  type="button"
                  className="btn btn-primary btn-block w-100"
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add To Saved Place</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="add_place_form" onSubmit={(e) => {
              e.preventDefault(); // Prevent page reload
              addPlaceHandler(); // Trigger your add place logic
            }}>
              <input type="hidden" name="lat" id="place_lat" />
              <input type="hidden" name="long" id="place_long" />
              <input type="hidden" name="address" id="place_address" />
              {/* <div className="row addfavplace d-flex justify-content-evenly"> */}
              {/* <div className="col-md-3 col-lg-3 h-25 w-25">
                  {" "}
                  <div
                    className={`d-flex  align-items-center justify-content-evenly ${addressType[1] ? "select" : ""
                      }`}
                    onClick={() =>
                      setAddressType({
                        1: true,
                        2: false,
                        3: false,
                      })
                    }
                  >
                    <i class="bi bi-house"></i>
                    Home
                  </div>{" "}
                </div>
                <div className="col-md-3 col-lg-3  h-25 w-25">
                  <div
                    className={`d-flex align-items-center justify-content-evenly ${addressType[2] ? "select" : ""
                      }`}
                    onClick={() =>
                      setAddressType({
                        1: false,
                        2: true,
                        3: false,
                      })
                    }
                  >
                    <i class="bi bi-briefcase"></i>
                    Work
                  </div>
                </div> */}
              {/* <div className="col-md-3 col-lg-3 w-25">
                  <div
                    className={`d-flex align-items-center justify-content-evenly ${addressType[3] ? "select" : ""
                      }`}
                    onClick={() =>
                      setAddressType({
                        1: false,
                        2: false,
                        3: true,
                      })
                    }
                  >
                    <i class="bi bi-star"></i>
                    Other
                  </div>
                </div>
              </div>
              {addressType[3] && (
                <div className="form-group mb-3 mt-3">
                  <input
                    style={{ background: "#eee" }}
                    type="text"
                    className="form-control"
                    id="name1"
                    placeholder="Enter your title"
                  />
                </div>
              )} */}
              <div className="form-group mb-3 mt-3">
                <input
                  style={{ background: "#eee" }}
                  type="text"
                  className="form-control"
                  id="name1"
                  placeholder="Enter your title"
                />
              </div>
              <div className="my-3"></div>

              {/* <div className="mt-20">
                <MapWithAMarker
                  containerElement={<div style={{ height: `50vh` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  location={addPlace ? addPlace : Position}
                />
              </div> */}
            </form>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={addPlaceHandler}
            >
              Add Place
            </button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={forCreditCardShow} onHide={handleForCreditCardClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Enter Card Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TapCard
              scope={Scope.TOKEN}
              operator={{
                publicKey: tap_public
              }}
              transaction={{
                reference: '',
                paymentAgreement: {
                  id: '',
                  contract: {
                    id: ''
                  }
                }
              }}
              order={{
                id: '',
                amount: 1,
                currency: Currencies.KWD,
                description: '',
                reference: '',
                metadata: {}
              }}
              invoice={{
                id: ''
              }}
              customer={{
                id: userProfileData?.user_profile?.tap_customer_id,
                name: [
                  {
                    lang: Locale.EN,
                    first: userProfileData?.user_profile?.name_eng ? userProfileData?.user_profile?.name_eng.split(' ')[0] : '',
                    last: userProfileData?.user_profile?.name_eng ? userProfileData?.user_profile?.name_eng.split(' ')[1] : '',
                    middle: ''
                  }
                ],
                nameOnCard: userProfileData?.user_profile?.name_eng,
                editable: true,
                contact: {
                  email: userProfileData?.user_profile?.user_email,
                  phone: {
                    countryCode: userProfileData?.user_profile?.country_code.replace('+', ''),
                    number: userProfileData?.user_profile?.mobileno
                  }
                }
              }}
              merchant={{
                id: ''
              }}
              interface={{
                locale: Locale.EN,
                cardDirection: Direction.DYNAMIC,
                edges: Edges.CURVED,
                theme: Theme.DYNAMIC,
                powered: true,
                colorStyle: ColorStyle.COLORED,
                loader: true
              }}
              features={{
                acceptanceBadge: true,
                customerCards: {
                  saveCard: false,
                  autoSaveCard: false,
                }
              }}
              fieldsVisibility={{
                card: {
                  cardHolder: true
                }
              }}
              acceptance={{
                supportedSchemes: ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
                supportedFundSource: ['CREDIT', 'DEBIT'],
                supportedPaymentAuthentications: ['3DS']
              }}
              post={{
                url: "https://tap.company"
              }}
              onReady={() => console.log('onReady')}
              onFocus={() => console.log('onFocus')}
              onBinIdentification={(data) => console.log('onBinIdentification', data)}
              onValidInput={(data) => setCreateEnabled(data)}
              onError={(data) => console.log('onError', data)}
              onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
              onSuccess={(data) => { handleCardToken(data) }}
            />
            <button className='btn mt-3 token_button w-100' onClick={tokenize} disabled={!createEnabled}>
              Save Card
            </button>
          </Modal.Body>
        </Modal>

        <Modal show={isModalOpen} onOpenChange={setIsModalOpen}
          onHide={() => setIsModalOpen(false)}
          centered
          backdrop="static"
        >
          <Modal.Body className="modal-content">
            <h2>Enable Location Services</h2>
            {/* <pre>{"Please enable location services and allow Safari to access your location in System Preferences:\n\n1. Open System Settings (or System Preferences).\n2. Go to Privacy & Security > Location Services.\n3. Turn on Location Services.\n4. Ensure Safari is checked in the list."}</pre> */}
            <p>{"Please enable location services and allow Safari to access your location in System Preferences:"}
              <br />
            {"1. Open System Settings (or System Preferences)."}
            <br />
            {"2. Go to Privacy & Security > Location Services."}
            <br />
            {"3. Turn on Location Services."}
            <br />
             {"4. Ensure Safari is checked in the list."}
             </p>
              <h5 style={{ color:'inherit', fontWeight:"500",lineHeight:"1.2"}}>If Denied</h5>
            <p>
              {"After enabling location services, please reload the page to allow this website to access your location."}
              <br />
               {"Once the page reloads, a prompt will appear asking you to confirm location access."}
            </p>
            <button className="btn btn-primary" onClick={handleReloadPage}>Reload Page</button>

            {/* <button onClick={ setIsModalOpen(false)}>Ask for location</button> */}
            {/* <button onClick={handleDenyLocation}>Close</button> */}
          </Modal.Body>
        </Modal>

        <Modal show={isChromeModal} onOpenChange={setIsChromeModal}
          onHide={() => setIsChromeModal(false)}
          centered
          backdrop="static"
        >
          <Modal.Body className="modal-content">
            <h2>Enable Location Services</h2>
            <pre className="fs-6">{" Chrome users:\n\n1. Click the lock icon in the address bar of Chrome.\n2. In the dropdown menu, locate the Location permission.\n3. Set it to 'Allow' or 'Ask'.\n\nIf you've previously denied access:\n\n1. Click the three-dot menu in the top-right corner of Chrome.\n2. Select 'Settings' > 'Privacy and security' > 'Site settings'.\n3. Under 'Permissions', click 'Location'.\n4. Find the website in the list and click the trash icon to reset the permission, or manually allow location access."
            }</pre>
            {/* <button onClick={ setIsModalOpen(false)}>Ask for location</button> */}
            {/* <button onClick={handleDenyLocation}>Close</button> */}
          </Modal.Body>
        </Modal>

      </section>
    </main>
  );
};

export default Dashboard;
