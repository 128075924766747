import React, { useEffect } from "react";
import Hero from "../components/hero/Hero";
import HowItWorks from "../components/how-it-works/HowItWorks";
import DownloadApps from "../components/download-section/DownloadApps";
import { useNavigate } from "react-router-dom";

function Homepage() {
  const navigate = useNavigate();
  useEffect(() => {
    var auth = localStorage.getItem("auth-token");
    if (auth) {
      navigate("/dashboard");
    };
  }, []);
  

  return (
    <main className="main">
      <Hero></Hero>
      <HowItWorks></HowItWorks>
      {/* <DownloadApps/> */}
    </main>
  );
}

export default Homepage;
