import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { sendAccountDeleteOtp, verifyAccountDeleteOtp, removeCustomerApi } from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/userContext";

const Sidebar = ({ pathname }) => {
  const [forMeShow, setForMeShow] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [number, setNumber] = useState('');
  const [userDetail, setUserDetail] = useState({});
  const [otp, setOtp] = useState(['', '', '', '']);
  const [successMessage, setSuccessMessage] = useState("");
  const [reason, setReason] = useState('');
  const navigate = useNavigate();
  const { commonPending } = useContext(UserContext);

  const itemsRef = useRef([]);

  const handleDeactivateAccount = () => {
    localStorage.clear()
    navigate("/");
  };

  useEffect(() => {
    var user = localStorage.getItem("user_data");
    var user_number = localStorage.getItem("user_number");

    if (user) {
      setUserDetail(JSON.parse(user));
    }

    if (user_number) {
      const cleanedNumber = user_number.replace(/\D/g, '');
      const tenDigitNumber = cleanedNumber.slice(-10);
      setNumber(tenDigitNumber);
    }
  }, []);

  const post_deleteAccountOtpApi = async (e) => {
    e.preventDefault();
    try {
      const data = {
        user_type: userDetail?.user_type || "0",
        user_id: userDetail?.user_profile_id || "",
        country_code: userDetail.country_code || "",
        mobile_number: number || "",
      };
      console.log('check data', data);

      const form = new FormData();
      Object.keys(data).forEach((key) => form.append(key, data[key]));

      const res = await sendAccountDeleteOtp(form);
      const responseData = res.data;

      if (responseData.status == "1") {
        setSuccessMessage("OTP sent successfully");
        toast(`Your Otp is ${responseData.otp_code}`, {
          position: "top-center",
          autoClose: 3000,
        });
        setTimeout(() => setSuccessMessage(""), 3000);
        setDeletePopup(false);
        setShowOtpModal(true);
      } else {
        setSuccessMessage("Error sending OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage("An error occurred. Please try again.");
    }
  };

  const handleOtpChange = (index, event) => {
    const value = event.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < 3) {
        itemsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
      itemsRef.current[index - 1].focus();
    }
  };

  const verifyOtpApi = async () => {
    const otpValue = otp.join('');
    console.log('check otp', otpValue)
    try {
      const data = {
        user_type: userDetail?.user_type || "",
        user_id: userDetail?.user_profile_id || "",
        otp: otpValue,
      };

      const form = new FormData();
      Object.keys(data).forEach((key) => form.append(key, data[key]));

      const res = await verifyAccountDeleteOtp(form);
      const responseData = res.data;

      if (responseData.status == "1") {
        setShowOtpModal(false);
        setShowConfirmDelete(true);
        toast("OTP Verify Successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const DeleteAccountApi = async () => {
    try {
      const data = {
        user_type: userDetail?.user_type || "",
        user_id: userDetail?.user_profile_id || "",
        country_code: userDetail?.country_code || "",
        mobile_number: number || "",
        reason: reason || "",
      };

      const form = new FormData();
      Object.keys(data).forEach((key) => form.append(key, data[key]));

      const res = await removeCustomerApi(form);
      const responseData = res.data;

      if (responseData.status == "1") {
        toast.success("Account deleted successfully.");
        toast("Account Deleted Successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
        localStorage.clear();
        navigate('/');
      } else {
        toast.error("Failed to delete the account. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleDeleteAccount = () => {
    if (!reason.trim()) {
      toast.error("Reason is required to delete your account.");
      return;
    }
    DeleteAccountApi();
  };

  const handleShowOtpModal = () => {
    setOtp(['', '', '', '']);
    setShowOtpModal(true);
  };

  const codeInputFields = new Array(4).fill(0).map((item, index) => (
    <div
      className="col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xxl-3 otp-input"
      key={index}
    >
      <div className="form-group">
        <input
          ref={(ref) => (itemsRef.current[index] = ref)}
          type="text"
          name={`code-${index}`}
          id={`otp-${index}`}
          value={otp[index]}
          onChange={(event) => handleOtpChange(index, event)}
          className="form-control"
          onKeyDown={(event) => handleKeyDown(event, index)}
          maxLength={1}
          style={{ padding: "0.375rem 1.85rem" }}
        />
      </div>
    </div>
  ));

  return (
    <>
      
      <Link
        to="/settings"
        className={
          pathname === "/settings" ? "btn text-profile activ" : "btn text-profile"
        }
      >
        Settings
      </Link>
      <Link
        to="/saved-places"
        className={
          pathname === "/saved-places"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Saved Places
      </Link>
      <Link
        to="/contact-us"
        className={
          pathname === "/contact-us"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Contact Us
      </Link>
      <Link
        to="/coupons"
        className={
          pathname === "/coupons" ? "btn text-profile activ" : "btn text-profile"
        }
      >
        Coupons
      </Link>
      <Link
        to="/about"
        className={pathname === "/about" ? "btn text-profile activ" : "btn text-profile"}
      >
        About Us
      </Link>
      <Link
        to="/feedback"
        className={
          pathname === "/feedback" ? "btn text-profile activ" : "btn text-profile"
        }
      >
        Feedback
      </Link>
      <Link
        className={
          pathname === "/privacy-policy"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
        to="/privacy-policy"
      >
        Privacy Policy
      </Link>
      <Link
        to="/faqs"
        className={
          pathname === "/faqs" ? "btn text-profile activ" : "btn text-profile"
        }
      >
        FAQ
      </Link>
      {commonPending && commonPending.status =="0" && 
      <button
        type="button"
        onClick={() => setForMeShow(true)}
        className={
          pathname === "/dashboard"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Delete Account
      </button>}

      <Modal show={forMeShow} centered>
        <div className="text-rove fw-bold" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '30px', textAlign: 'center', margin: '10px', padding: '0px' }}>
          Deactivate your account instead<br />
          of deleting?
        </div>
        <Modal.Body>
          <div className="col-md-12">
            <div className="d-flex justify-content-end">
              <div className="text-left">
                <div className="d-flex align-items-center mb-3">
                  <img src="/assets/imgs/icons/caution-sign.png" style={{ width: '20px' }} className="me-2" />
                  <div>
                    <h6 className="text-rove fw-bold mb-2">
                      Deactivating your account is temporary your account details and
                      history will be restored once you reactivate your account.
                    </h6>
                    <p>
                      Your booking, saved cards, transactions, and saved places will
                      remain saved with us. If you decide to register again, your data
                      will be retrieved.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <img src="/assets/imgs/icons/eye_close.png" style={{ width: '22px' }} className="me-2" />
                  <div>
                    <h6 className="text-rove fw-bold mb-2">
                      Deleting your account is permanent. Are you sure you want to
                      delete your account permanently?
                    </h6>
                    <p>
                      Once your account is deleted, you cannot access your account to
                      recover any data. We'll need to verify your identity for
                      security purposes with our Customer Care Center to complete your
                      request.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <button className="btn btn-primary w-100 fw-bold" onClick={handleDeactivateAccount}>
              Deactivate Account
            </button>
          </div>
          <div className="mt-3">
            <button
              className="btn btn-primary w-100 fw-bold"
              onClick={() => {
                setForMeShow(false);
                setDeletePopup(true);
              }}
            >
              Delete or Deactivate your account
            </button>
          </div>
          <div className="mt-3">
            <button
              className="btn btn-light w-100"
              onClick={() => setForMeShow(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={deletePopup} onHide={() => setDeletePopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-rove">Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-2">
            <div>
              <h6 className="text-rove fw-bold mb-3">
                Are you sure you want to delete your account?
              </h6>
              <h6 className="text-rove fw-bold">
                If you want to change your account info, you can easily do so in
                your profile page.
              </h6>
            </div>
          </div>
          <div className="mt-5">
            <Link className="btn btn-primary w-100 fw-bold" to={"/profile"}>
              Go To Profile
            </Link>
          </div>
          <div className="mt-3">
            <h6 className="text-danger fw-bold mt-2">WARNING</h6>
          </div>
          <div className="mt-3">
            <h6 className="text-rove fw-bold mt-2">
              If you delete your account, you won't be able to reactivate it
              later.
            </h6>
          </div>
          <div className="mt-5">
            <button className="btn btn-light w-100" onClick={post_deleteAccountOtpApi}>
              Next
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-rove">Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-2">
            <h6 className="text-rove fw-bold mb-3">Enter the OTP sent to your number:</h6>
            <div className="row">
              {codeInputFields}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={verifyOtpApi}>
            Verify OTP
          </button>
          <button className="btn btn-light" onClick={() => setShowOtpModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-rove">Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-2">
            <div>
              <h6 className="text-rove fw-bold mb-3">Tell us why you are leaving</h6>
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <textarea
                      cols="3"
                      rows="3"
                      className="form-control"
                      placeholder="Reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <button
                      className="btn btn-primary fw-bold w-100"
                      type="button"
                      onClick={handleDeleteAccount}
                    >
                      Delete My Account
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
