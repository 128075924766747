import { useEffect } from "react";
import { useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
} from "react-google-maps";
const custom_style = require("../../custom_style.json");

const options = {
    mapTypeId: "terrain",
    draggable: false,
    scrollwheel: false,
    disableDefaultUI: true,
    zoomControl: false,
    styles: custom_style,
  };

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;


const MapWithAMarker = withGoogleMap(({location}) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={location}
      options={options}
    >
      <>
        {location &&
        location.text !== undefined &&
        location.text != "" ? (
          <MarkerWithLabel
            position={location}
            cursor="hand"
            icon="/assets/imgs/icons/pickupmarker.png"
            labelAnchor={new google.maps.Point(-10, 40)}
            labelStyle={{
              backgroundColor: "#fff",
              fontSize: "10px",
              fontWeight: "700",
              padding: "5px",
            }}
          >
            <div>
              {location ? location.text : ""}
              &nbsp;&nbsp;
              <i className="fa fa-chevron-right"></i>
            </div>
          </MarkerWithLabel>
        ) : (
          ""
        )}
      </>

      {/* <TrafficLayer autoUpdate /> */}
    </GoogleMap>
  ));

export default MapWithAMarker;
