import React, { useEffect, useState } from "react";
import { customerCommonPendingApi, enableFeaturesApi, getCartypeApi, getProfileDataApi } from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

export const UserContext = React.createContext({
  userImage: null,
  loginUser: null,
  commonPending: null,
  isLoadingUpdateBtn: false,
  setIsLoadingUpdateBtn: () => {}, // Placeholder function

  setUserImage: () => {},
  setLoginUser: () => {},
  enableFeaturesList: null,
  carTypes: null,
  loader: false,
  setEnableFeaturesList: () => {},
  setLoader: () => {},
  setCommonPending: () => {},

});

export const UserProvider = ({ children }) => {
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });
  const [loginUser, setLoginUser] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [enableFeaturesList, setEnableFeaturesList] = useState(null);
  const [carTypes, setCarTypes] = useState();
  const [isLoadingUpdateBtn, setIsLoadingUpdateBtn] = useState(false);
  const [commonPending, setCommonPending] = useState();

  const location = useLocation();

  const [loader, setLoader] = useState(false);


  const navigate = useNavigate();

  const profileUrlChange = async (url) => {
    const baseUrl = "https://www.riderove.com/dev_rove/uploads/customer/";
    const fallbackUrl = "https://rovemain.rovesolution.com/uploads/customer/";
  
    try {
      const fullUrl = baseUrl + url;
  
      // Axios request to check if the URL exists
      const res = await axios.get(fullUrl);
  console.log(res)
      // If the request is successful, return the full URL
      if (res.status === 200) {
        return fullUrl;
      }
    } catch (error) {
      console.error("Error fetching profile image:", error);
  
      // If the request fails, return the fallback URL
      return fallbackUrl + url;
    }
  
    // Default to the fallback URL in case of an unknown issue
    return fallbackUrl + url;
  };

  // const profileUrlChange = async (url) => {
  //   const baseUrl = "https://www.riderove.com/dev_rove/uploads/customer/";
  //   const fallbackUrl = "https://rovemain.rovesolution.com/uploads/customer/";
  //   try {
  //     const fullUrl = baseUrl + url;
  
  //     const res = await fetch(fullUrl, { method: "GET", mode: "no-cors" });
  //     console.log(res,"res")
  //     // Since no-cors doesn't allow reading the response, simply return the URL
  //     if (res.ok || res.type === 'opaque') {
  //       return fullUrl;
  //     } else {
  //       return fallbackUrl + url;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return fallbackUrl + url;
  //   }
  // };
  
 /*  useEffect(() => {
    var user = localStorage.getItem("user_data");
    if (user) {
      setUserDetail(JSON.parse(user));
    }
    }, []); */
  
  useEffect(() => {
    if (userDetail) {
      get_profile_data();
      if (!enableFeaturesList) {
        get_enable_features_list();
      }
      if(!carTypes){
        get_car_types();
      }
    };
  }, [userDetail]);
  
  useEffect(()=>{
    if(userDetail){
      checkCommonPending();
    }
  },[location.pathname,userDetail])

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {
        setLoginUser(data);
        setUserImage(data.user_profile.user_image);
        if (data.runining_ride_data != "") {
          localStorage.setItem("request_id", data.runining_ride_data.request_id);
        } else {
          localStorage.removeItem("request_id");
        }
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear()
          navigate("/login")
        }
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_enable_features_list = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await enableFeaturesApi(form);
      var data = res.data;
      if (data.status == "1") {
        setEnableFeaturesList(data.enable_features);
      } else {
        console.log("Error : get_enable_features_list context", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkCommonPending = async () => {
    try {
      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append('user_id', userDetail.user_profile_id);
      form.append('user_type', userDetail.user_type);

      const res = await customerCommonPendingApi(form);
      const data = res.data;
      setCommonPending(data);
      return data;

    } catch (error) {
      console.log("checkCommonPending", error);
    }
  }

  const get_car_types = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);


      var res = await getCartypeApi(form);
      var data = res.data;
      if (data.status == "1") {
        setCarTypes(data);
      } else {
        console.log("get_car_types context", data);
      }

    } catch (error) {
      console.log("get_car_types", error);
    }
  }

  return (
    <UserContext.Provider value={{ loginUser,setUserImage,userImage,commonPending,setCommonPending, setLoginUser,enableFeaturesList,setEnableFeaturesList,carTypes,loader,setLoader  , isLoadingUpdateBtn,
      setIsLoadingUpdateBtn,}}>
      {children}
    </UserContext.Provider>
  );
};
