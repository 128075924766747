import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import { getProfileDataApi, retrieveTapCustomerApi, deleteTapCardApi, addCardApi, saveTapCardApi, deleteCardApi, defaultCardApi, customerCommonPendingApi } from "../../api/api";
import { TapCard, Currencies, Direction, Edges, Locale, Theme, Scope, ColorStyle, tokenize, saveCard } from '@tap-payments/card-web'
import Spinner from "react-bootstrap/Spinner";
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

const tap_secret = process.env.REACT_APP_TAP_PAYMENT_LIVE == "0" ? process.env.REACT_APP_TAP_PAYMENT_SECRET_TEST : process.env.REACT_APP_TAP_PAYMENT_SECRET;
const tap_public = process.env.REACT_APP_TAP_PAYMENT_LIVE == "0" ? process.env.REACT_APP_TAP_PAYMENT_PUBLIC_TEST : process.env.REACT_APP_TAP_PAYMENT_PUBLIC;

const Payments = () => {
  const location = useLocation();
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  })
  const [userProfileData, setUserProfileData] = useState();
  const [forCreditCardShow, setForCreditCardShow] = useState(false);
  const handleForCreditCardClose = () => setForCreditCardShow(false); 
  
  const [forCreditCardEditShow, setForCreditCardEditShow] = useState(false);
  const handleForCreditCardEditClose = () => setForCreditCardEditShow(false); 

  const [createEnabled, setCreateEnabled] = useState(false);
  const [customerObj, setCustomerObj] = useState({})
  const [savedCards, setSavedCards] = useState([])
  const [defaultCard, setDefaultCard] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cardBtnDisabled, setCardBtnDisabled] = useState(false)
  const [cardEditData, setCardEditData] = useState([])

  useEffect(() => {
    if (userDetail) {      
      get_profile_data();
      // get_enable_features_list()
    }
    var text_input = document.querySelector("#other_amount");
    if (location.state) {
      const { topUp } = location.state;

      if (topUp && (!text_input.value) ) {
        text_input.value = topUp;
      };
    };
  }, [userDetail]);

  useEffect(() => {
    if( userProfileData ) {
      fetchCustomer();
    }
  },[userProfileData])

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      // console.log(res)
      var data = res.data;
      if (data.status == "1") {

        if( data.user_card_info ) {
          setDefaultCard(data.user_card_info)
        }

        setUserProfileData(data);
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCustomer = async (e) => {
    if( userProfileData.user_profile.tap_customer_id != '' ) {
      
      setIsLoading(true)

      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append("customer_id", userProfileData.user_profile.tap_customer_id);

      var res = await retrieveTapCustomerApi(form);
      var data = res.data;
      // setCustomerObj(data)
      if( data.cards ) {
        var cards = data.cards;
        cards = cards.map((card) => {
          if(card.brand == 'VISA') {
            card.brand_image = '/assets/imgs/icons/visa@2x.png'
          }
          else {
            card.brand_image = '/assets/imgs/icons/ic_mastercard-2.png'
          }
          return card;
        })
      }
      else {
        var cards = []
      }
      setSavedCards(cards)

      setIsLoading(false)
    }
  }

  const cardDefaultCheckHandler = (e) => {
    if( e.target.checked ) {
      setCardBtnDisabled(false)
    }
    else {
      setCardBtnDisabled(true)
    }
  }

  const updateCardHandler = () => {
    setForCreditCardEditShow(false)
    defaultCardHandler(cardEditData)
  }

  const defaultCardHandler = async (card) => {
    
    var form = new FormData();
    // form.append("tap_secret", tap_secret);
    form.append('userId', userDetail.user_profile_id);
    form.append('user_type', userDetail.user_type);
    form.append('tap_card_id', card.id);
    form.append('tap_customer_id', userProfileData.user_profile.tap_customer_id);
    form.append('last_four', card.last_four);

    var res = await defaultCardApi(form);
    var data = res.data;
    if( data.status ) {
      get_profile_data()
    }
  }

  const editCardHandler = async (card) => {
    setForCreditCardEditShow(true)
    setCardEditData(card)
    setTimeout(() => {
      if( savedCards.length < 2 ) {
        document.getElementById('defaultCard').disabled = true
        setCardBtnDisabled(true)
      }
      else {
        document.getElementById('defaultCard').disabled = false
        setCardBtnDisabled(false)
      }
      if( card.id == defaultCard.tap_card_id ) {
        document.getElementById('defaultCard').checked = true
        setCardBtnDisabled(false)
      }
      else {
        setCardBtnDisabled(true)
      }
      document.getElementById('cardId').value = card.id
      document.getElementById('cardHolder').value = card.name
      document.getElementById('cardNumber').value = '#### #### #### '+card.last_four
      document.getElementById('cardExpiry').value = card.expiry.month + '/' + card.expiry.year
      document.getElementById('cardCVV').value = '123'
    },500)
  }
  

  const checkCommonPending = async () => {
    try {
      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append('user_id', userDetail.user_profile_id);
      form.append('user_type', userDetail.user_type);

      const res = await customerCommonPendingApi(form);
      const data = res.data;
      return data;

    } catch (error) {
      console.log("checkCommonPending", error);
    }
  }


  const deleteCardHandler = async (card_id) => {
    
    try {
      const pending = await checkCommonPending();
      if (pending.status == "1") {
       
        toast.error(pending.message, {
          position: "top-center",
          autoClose: 1500
        });
        return false;
      }

      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append("customer_id", userProfileData.user_profile.tap_customer_id);
      form.append("card_id", card_id);
  
      var res = await deleteTapCardApi(form);
      var data = res.data;
      if( data.delete ) {  
        var delete_card_form = new FormData();
        delete_card_form.append('userId', userDetail.user_profile_id);
        delete_card_form.append('user_type', userDetail.user_type);
        delete_card_form.append('tap_card_id', card_id);
        delete_card_form.append('tap_customer_id', userProfileData.user_profile.tap_customer_id);
  
        var delete_card_res = await deleteCardApi(delete_card_form);
        var delete_card_data = delete_card_res.data;
        if( delete_card_data.status ) {
          get_profile_data()
        }
      }
    } catch (error) {
      console.log("deleteCardHandler", error);
    }

  }

  const cardHandler = () => {
    setForCreditCardShow(true);
  }

  const handleSaveCardChange = () => {
    console.log('save card')
  }
  
  const handleCardToken = async (token) => {
   
    if( token.status == "ACTIVE" ) {

      setForCreditCardShow(false);
      setIsLoading(true);

      var token_name = token.card.name
      var token_name_split = token_name.split(' ')
      var token_first_name = ''
      var token_middle_name = ''
      var token_last_name = ''
      if( token_name_split.length > 2 ) {
        token_first_name = token_name_split[0]
        token_middle_name = token_name_split[1]
        token_last_name = token_name_split[2]
      }
      else if( token_name_split.length > 1 ) {
        token_first_name = token_name_split[0]
        token_last_name = token_name_split[1]
      }
      
      var save_card_form = new FormData();
      // save_card_form.append("tap_secret", tap_secret);
      save_card_form.append("threeDSecure", "1");
      save_card_form.append("save_card", "1");
      save_card_form.append("token", token.id);

      /* save_card_form.append("currency", customerObj.currency);
      save_card_form.append("customer_id", customerObj.id);
      save_card_form.append("email", customerObj.email);
      save_card_form.append("country_code", customerObj.phone.country_code.replace('+',''));
      save_card_form.append("number", customerObj.phone.number); */

      save_card_form.append("currency", "KWD");
      save_card_form.append("customer_id", userProfileData.user_profile.tap_customer_id);
      save_card_form.append("email", userProfileData.user_profile.email);
      save_card_form.append("country_code", userProfileData.user_profile.country_code.replace('+',''));
      save_card_form.append("number", userProfileData.user_profile.mobileno);

     /*  // save_card_form.append("first_name", customerObj.first_name);
      // save_card_form.append("middle_name", customerObj.middle_name);
      // save_card_form.append("last_name", customerObj.last_name); */
      save_card_form.append("first_name", token_first_name);
      save_card_form.append("middle_name", token_middle_name);
      save_card_form.append("last_name", token_last_name);
      save_card_form.append("redirect_url", token.url);
      
      var save_card_res = await saveTapCardApi(save_card_form);
      // var save_card_data = save_card_res.data;
      
      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append("userId", userDetail.user_profile_id);
      form.append("user_type", userDetail.user_type);
      form.append("tap_card_id", token.card.id);
      form.append("tap_customer_id", userProfileData.user_profile.tap_customer_id);
      form.append("card_number", token.card.last_four);
      form.append("card_name", token.card.name);
      form.append("card_brand", token.card.brand);
      form.append("card_responce", "");
  
      var res = await addCardApi(form);
      // var data = res.data;

      setIsLoading(false);
      // fetchCustomer()
      get_profile_data()
    }
  }

  return (
    <main className="main">
      
      <section className="section mt-40 " id="tax-page">
        <div className="container">
          <div className="row">
            <div className="offset-2 col-md-8">
              <div className="text-center">
                <h3>Payments</h3>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="offset-2 col-md-8">
              <h5 className="text-muted mt-3">Saved Credit Cards</h5>
              {              
                isLoading ? (
                  <div className="d-flex justify-content-center align-items-center mt-5 ">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : 
              savedCards.length > 0 ? savedCards.map((card,index) => (
                <div className="card mt-3" key={index}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <img
                          src={card.brand_image}
                          alt="ic_mastercard-2@2x"
                          width={50}
                        />
                        { card.id == defaultCard.tap_card_id ? <div className="pull-right">
                          <button className="btn btn-basic"  >Default</button>
                        </div> : '' }
                        <p className="mt-4 fw-bold fs-6 mb-0">
                          <i>{`#### #### ####`}</i> {card.last_four}
                        </p>
                        <div className="mt-4 fw-bold fs-6 mb-0">
                          <strong><span className="uppercase">{card.name}</span></strong>&nbsp;<span className="bullet">&bull;</span>&nbsp;
                          <span className="">{card.expiry.month}/{card.expiry.year}</span>
                          <div className="pull-right">
                            <Dropdown>
                              <Dropdown.Toggle variant="" id="dropdown-basic" className="dot-icon">
                                <i className="fa fa-ellipsis-v"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => editCardHandler(card)}>
                                  <i className="fa fa-pencil me-2"></i>&nbsp;Edit
                                </Dropdown.Item>
                                { card.id != defaultCard.tap_card_id ? <Dropdown.Item href="#" onClick={() => defaultCardHandler(card)}>
                                  <i className="fa fa-star me-2"></i>&nbsp;Set as Default
                                </Dropdown.Item> : '' }
                                <Dropdown.Item href="#" onClick={() => deleteCardHandler(card.id)}>
                                  <i className="fa fa-trash me-2"></i>&nbsp;Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              )) : <h4 className="mt-3">No Credit Card added yet</h4>}
              
              <div className="mt-4">
                <button className="btn btn-card w-100" type="button" onClick={cardHandler}>
                  <i className="fa fa-plus"></i>&nbsp; Add Credit Card
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal size="lg" show={forCreditCardShow} onHide={handleForCreditCardClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TapCard
            scope={Scope.TOKEN}
            operator={{
              publicKey: tap_public
            }}
            transaction={{
              reference: '',
              paymentAgreement: {
                id: '',
                contract: {
                  id: ''
                }
              }
            }}
            order={{
              id: '',
              amount: 1,
              currency: Currencies.KWD,
              description: '',
              reference: '',
              metadata: {}
            }}
            invoice={{
              id: ''
            }}
            customer={{
              id: userProfileData?.user_profile.tap_customer_id,
              name: [
                {
                  lang: Locale.EN,
                  first: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[0] : '',
                  last: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[1] : '',
                  middle: ''
                }
              ],
              nameOnCard: userProfileData?.user_profile.name_eng,
              editable: true,
              contact: {
                email: userProfileData?.user_profile.user_email,
                phone: {
                  countryCode: userProfileData?.user_profile.country_code.replace('+',''),
                  number: userProfileData?.user_profile.mobileno
                }
              }
            }}
            merchant={{
              id: ''
            }}
            interface={{
              locale: Locale.EN,
              cardDirection: Direction.DYNAMIC,
              edges: Edges.CURVED,
              theme: Theme.DYNAMIC,
              powered: true,
              colorStyle: ColorStyle.COLORED,
              loader: true
            }}
            features={{
              acceptanceBadge: true,
              customerCards: {
                saveCard: false,
                autoSaveCard: false,
              }
            }}
            fieldsVisibility={{
              card: {
                cardHolder: true
              }
            }}
            acceptance={{
              supportedSchemes: ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
              supportedFundSource: ['CREDIT', 'DEBIT'],
              supportedPaymentAuthentications: ['3DS']
            }}
            post={{
              url: "https://tap.company"
            }}
            onReady={() => console.log('onReady')}
            onFocus={() => console.log('onFocus')}
            onBinIdentification={(data) => console.log('onBinIdentification', data)}
            onValidInput={(data) => setCreateEnabled(data)}
            onError={(data) => console.log('onError', data)}
            onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
            // onChangeSaveCardLater={handleSaveCardChange}
            onSuccess={(data) => { console.log('on Success', data); handleCardToken(data); }}
            // onSuccess={(data) => console.log('on Success', data)}
          />
          <button className='btn mt-3 token_button w-100' onClick={tokenize} disabled={!createEnabled}>
          Save Card
          </button>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={forCreditCardEditShow} onHide={handleForCreditCardEditClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Credit Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input type="hidden" id="cardId" />
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Card Holder Name</label>
                  <input type="text" className="form-control" id="cardHolder" disabled={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Card Number</label>
                  <input type="text" className="form-control" id="cardNumber" disabled={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Expiry MM/YY</label>
                  <input type="text" className="form-control" id="cardExpiry" disabled={true} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Card CVV</label>
                  <input type="password" className="form-control" id="cardCVV" disabled={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="defaultCard" onChange={cardDefaultCheckHandler} value="1" />
                  <label className="form-check-label">Make this card default for future trips?</label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <button className="btn btn-primary w-100" disabled={cardBtnDisabled} onClick={updateCardHandler}>Update</button>
                <div className="mt-3 text-center">
                  <p>To verify your credit card, an amount of up to KD 1 will be charged. After verification, the amount will be automatically refunded.</p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="">
                        <img src="" />
                      </div>
                      <div className="">
                        <p className="mb-0">All you information is safe and secured with tap payment gateway.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Payments;
