import React from "react";
import { Spinner } from "react-bootstrap";

const FullScreenLoader = () => {
  return (
    <div
          className="d-flex justify-content-center align-items-center vh-100 vw-100 position-absolute"
          style={{ backgroundColor: "rgba(255,255,255,0.6)",zIndex:1066 }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
  );
};

export default FullScreenLoader;
