// const host = "https://www.riderove.com/dev_rove/api";
const host = "https://rovemain.rovesolution.com/api";
// const host = "http://rovemain.rovesolution.com/admin";

const api = {
    otp: `${host}/send_otp_api/send_otp/`,
    emailOtp: `${host}/send_otp_email_api/send_otp`,
    checkEmail:`${host}/checkuser_email_api/check_email`,
    login: `${host}/login_reg_api/login/`,
    call_otp: `${host}/otp_voice_call_api/call_otp`,
    get_profile_data:`${host}/getprofile_api/get_profiledata`,
    phone_send_otp:`${host}/send_update_mobile_otp/send_otp/`,
    email_verify:`${host}/email_verify_send/email_send`,
    enable_features:`${host}/enable_features_api/enable_features?is_new_app=1`,
    update_profile_data:`${host}/updateprofile_api/update_profiledata/`,
    get_fav_place:`${host}/Cancel_Request/getfavourite_place/`,
    add_fav_place: `${host}/Cancel_Request/addfavourite_place`,
    delete_fav_place:`${host}/Cancel_Request/remove_favourite_place/`,
    update_fav_place:`${host}/Cancel_Request/update_favourite_place/`,
    near_search_location:`${host}/near_search_location_api/near_search_location/`,
    get_driver: `${host}/getdriver_api/get_driver`,
    // price_calculate: `${host}/estimatedfar_api/price_calculate`,          //   old
    price_calculate: `${host}/estimatedfar_car_selection_api/price_calculate`, //  new
    get_fare_break_down: `${host}/fare_break_down_api/get_fare_break_down`,
    driver_request: `${host}/driverrequest_api/driver_request`,
    get_request_driver: `${host}/getrequestdriver_api/get_request_driver/`,
    cancle_riderequest: `${host}/cancleridebyuser_api/cancle_riderequest/`,
    logout: `${host}/logout_api/logout/`,
    getCoupon: `${host}/promotions_api/get_promotions/`,
    car_types: `${host}/cartype_api/get_cartype/`, // google api keys
    user_location_request: `${host}/userlocationrequest_api/user_location_request/`, // flags of ride status
    validate_coupon: `${host}/validatepromotion_api/validate_coupon/`, 
    get_past_rides: `${host}/history_api/get_history/`, 
    rating_api: `${host}/ratting_api/ratting/`, 
    home_screen_notification: `${host}/home_screen_notification_api/home_screen_notification`, 
    remove_history: `${host}/removehistory_api/remove_history/`, 
    customer_running_ride_apply_coupon_api: `${host}/customer_running_ride_apply_coupon_api/apply_coupon`, 
    customer_remove_ride_apply_coupon_api: `${host}/customer_remove_ride_apply_coupon_api/remove_coupon`, 
    car_rental_update_api: `${host}/car_rental_update_api/car_rental_update/`, 
    pickup_later_update_api : `${host}/getrequestdriver_api/update_pickup_later_time/`,
    get_rental_car : `${host}/rental_car_selection_api/get_rental_car/`,
    add_wallet_tap_web_api : `${host}/add_wallet_tap_web_api/add_wallet_tap_web/`,
    retrieve_tap_customer_api : `${host}/tap_customer_api/retrieve_customer/`,
    create_tap_customer_api : `${host}/tap_customer_api/create_tap_customer/`,
    delete_tap_card_api : `${host}/tap_customer_api/delete_card/`,
    save_tap_card_api : `${host}/tap_customer_api/save_card/`,
    create_tap_charge_api : `${host}/tap_customer_api/create_charge/`,
    add_card_wallet_api : `${host}/add_card_payment_wallet_api/add_card_payment_wallet`,
    create_customer_id_api : `${host}/usercard_api/create_customer_id/`,
    add_card_api : `${host}/usercard_api/add_card/`,
    delete_card_api : `${host}/usercard_api/delete_card/`,
    default_card_api: `${host}/usercard_api/make_default_card/`,
    update_far_runningride: `${host}/estimatefarupdate_api/update_far/`,
    change_riderequest_runningride: `${host}/changerequest_api/change_riderequest/`, // update dastination
    wallet_history_v2_api: `${host}/wallet_history_v2_api/wallet_history/`, 
    check_user_number_exist_app_api: `${host}/check_user_number_exist_app_api/check_user_number/`, 
    transfer_wallet_freind_knet_api: `${host}/transfer_wallet_freind_knet_api/transfer_wallet_freind_knet`, 
    redeem_voucher: `${host}/redeem_voucher_api/redeem_voucher/`, 
    check_unpaid_request: `${host}/Cancel_Request/check_unpaid_request/`, 
    paybywallet_api: `${host}/paybywallet_api/paybywallet/`, 
    getUserWallet: `${host}/get_user_wallet_api/user_wallet_detail`, 
    feedback_api: `${host}/feedback_api/feedback/`,
    send_account_delete_otp: `${host}/send_account_delete_otp/send_otp/`,
    verify_account_delete_otp: `${host}/verify_account_delete_otp/verify_otp/`,
    remove_customer_api: `${host}/remove_customer_api/remove_customer/`,
    airport_car_selection_api: `${host}/airport_car_selection_api/get_car_selection/`,
    paybycard_ride_api: `${host}/paybycard_ride_api/paybycard_ride/`,     //   static key from backend need to change while production
    customer_ride_preference: `${host}/customer_ride_preference_api/customer_ride_preference/`,     //   static key from backend need to change while production
    contact_us_api: `${host}/contact_us_api/contact_us/`, 
    user_wallet_detail: `${host}/get_user_wallet_api/user_wallet_detail/`, 
    customer_common_pending: `${host}/customer_common_pending_api/customer_common_pending/`, 
    create_feedback: ` https://api.rovesolution.com/ticket/createFeedback/`,
};

export default api;
