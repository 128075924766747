import { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
  Polyline,
  InfoWindow,
} from "react-google-maps";
// } from "@react-google-maps/api";

const custom_style = require("../../custom_style.json");

const options = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  zoomControl: true,
  // defaultClickableIcons: false,
  clickableIcons: false,
  styles: custom_style,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const MapWithAMarker = withGoogleMap(
  ({
    handlePickupDrag,
    handleDestinationDrag,
    zoom,
    pickupPosition,
    position,
    destinationPosition,
    routeDirections,
    driverPositions,
    path,
    dynamicCar,
    fetchLocation
  }) => {
    const mapRef = useRef(null);
    useEffect(() => {
      if (path && mapRef.current) {
        const bounds = new google.maps.LatLngBounds();
        path.forEach((point) => {
          bounds.extend(new google.maps.LatLng(point.lat, point.lng));
        });
        mapRef.current.fitBounds(bounds);
      }
    }, [path]);

    useEffect(() => {
      if (mapRef.current) {
        const map = mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    
        const controlDiv = document.createElement("div");
        const controlUI = document.createElement("button");
    
        // Style the button
        controlUI.style.backgroundColor = "#ffffff"; // Remove default background
        controlUI.style.border = "none"; // Remove border
        controlUI.style.borderRadius = "50%"; // Make it circular (optional)
        controlUI.style.padding = "10px"; // Adjust padding to fit image
        controlUI.style.cursor = "pointer";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,0.3)";
        
        // Create an img element
        const buttonImage = document.createElement("img");
        buttonImage.src = "/assets/imgs/icons/userLoc@2x.png"; // Path to your image
        buttonImage.alt = "Locate Me"; // Optional: Alt text for the image
        buttonImage.style.width = "20px"; // Set the width of the image
        buttonImage.style.height = "20px"; // Set the height of the image
        buttonImage.style.objectFit = "contain"; // Maintain aspect ratio
    
        // Append image to the button
        controlUI.appendChild(buttonImage);
    
        controlDiv.appendChild(controlUI);

        controlDiv.style.marginRight = "10px";
    
        // Add the button click event
        controlUI.addEventListener("click", fetchLocation)
        /* controlUI.addEventListener("click", () => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                map.panTo({ lat: latitude, lng: longitude });
              },
              (error) => {
                console.error("Error fetching location", error);
              }
            );
          } else {
            alert("Geolocation is not supported by this browser.");
          }
        }); */
    
        // Position the button near the zoom controls
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
      }
    }, [mapRef]);
    

    return (
      <GoogleMap
        defaultZoom={zoom}
        center={position}
        options={options}
        ref={mapRef}
      >
        <>
          {/* {console.log(typeof pickupPosition.lat)} */}
          {/* {console.log("MAP position",position)} */}
          {/* {console.log("zoom",zoom)}  */}
          {/* {console.log(path)} */}
          {driverPositions &&
            Array.isArray(driverPositions) &&
            driverPositions.map((v, i) => 
            {
              var url = "/assets/imgs/icons/black_car_marker.png";
              if (v.car_color == "White") {
                url = "/assets/imgs/icons/white_car_marker.png";
              }
              if (v.car_color == "Blue") {
                url = "/assets/imgs/icons/blue_car 1.png";
              }
              if (v.car_color == "Gray") {
                url = "/assets/imgs/icons/grey_car 1.png";
              }
              if (v.car_color == "Silver") {
                url = "/assets/imgs/icons/silver_car.png";
              }
              if (v.car_color == "Dynamic") {
                url = "https://www.riderove.com/dev_rove/uploads/" + dynamicCar;
              }
              return <MarkerWithLabel
                key={i}
                position={{
                  lat: parseFloat(v.latitude),
                  lng: parseFloat(v.longitude),
                }}
                cursor="pointer"
                icon={{
                  url: url,
                  scaledSize: new google.maps.Size(15, 30),
                }}
                labelAnchor={new google.maps.Point(0, 0)}
              >
                <div></div>
              </MarkerWithLabel>}
            )}

          {pickupPosition !== undefined && pickupPosition.text !== undefined ? (
            <MarkerWithLabel
              position={{
                lat: parseFloat(pickupPosition.lat),
                lng: parseFloat(pickupPosition.lng),
              }}
              cursor="hand"
              icon="/assets/imgs/icons/pickupmarker.png"
              draggable={true}
              onDragEnd={handlePickupDrag}
              labelAnchor={new google.maps.Point(0, 0)}
              labelStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                fontSize: "15px",
                fontWeight: "700",
                padding: "10px",
                whiteSpace: "nowrap",
                transform: "translateX(-50%)",
                zIndex: "1",
              }}
            >
              <>
                {/* <svg
                  fill="red"  // Set the fill color to red
                  className="react-datepicker__triangle"
                  aria-hidden="true"
                  width="18"
                  height="16"
                  viewBox="0 0 16 16"
                  style={{
                    position: "absolute",
                    pointerEvents: "none",
                    left: "97px",
                    transform: "rotate(180deg) translateY(-1px)",
                    bottom: "100%",
                  }}
                >
                  <path
                    clipPath="url(#:r36:)"
                    fill="red"  // Also set the fill color here to red
                    strokeWidth="3"
                    d="M0,0 H16 L8,8 Q8,8 8,8 Z"
                  ></path>
                  <path stroke="red" d="M0,0 H16 L8,8 Q8,8 8,8 Z"></path>  // Set stroke to red
                  <clipPath id=":r36:">
                    <rect x="-1" y="1" width="18" height="16"></rect>
                  </clipPath>
                </svg> */}
              <div>
                {pickupPosition.text != "" ? pickupPosition.text : <div
                    // className="d-flex justify-content-center align-items-center z-1"
                    // style={{ backgroundColor: "rgba(255,255,255,0.6)",height:"80vh" }}
                  >
                    <Spinner animation="grow" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>}
                {/* <i className="fa fa-chevron-right"></i> */}
              </div>
              </>
            </MarkerWithLabel>
          ) : (
            ""
          )}

          {destinationPosition !== undefined &&
          destinationPosition.text !== undefined ? (
            <MarkerWithLabel
              position={destinationPosition}
              cursor="hand"
              icon="/assets/imgs/icons/destination.png"
              draggable={true}
              onDragEnd={handleDestinationDrag}
              labelAnchor={new google.maps.Point(0, 0)}
              labelStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                fontSize: "15px",
                fontWeight: "700",
                padding: "10px",
                whiteSpace: "nowrap",
                transform: "translateX(-50%)",
                zIndex: "1",
              }}
            >
              <div>{destinationPosition.text}&nbsp;&nbsp;</div>
             
            </MarkerWithLabel>
          ) : (
            ""
          )}
          {routeDirections && (
            <DirectionsRenderer
              directions={routeDirections}
              defaultOptions={{
                suppressMarkers: true,
                polylineOptions: { strokeColor: "#213d4a" },
              }}
            />
          )}
          {path && (
            <>
              <Polyline
                path={path}
                options={{
                  strokeColor: "#213d4a",
                  strokeOpacity: 1.0,
                  strokeWeight: 3,
                }}
              />
              {/* <Polyline
            path={path.slice(8,13)}
            options={{
              strokeColor: '#213d4a',
              strokeOpacity: 1.0,
              strokeWeight: 3,
              }}
              /> */}
            </>
          )}

          {/*<TrafficLayer autoUpdate />*/}
        </>
      </GoogleMap>
    );
  }
);

export default MapWithAMarker;
