import React, { useState, useEffect, useRef } from "react";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { Modal, Button, ModalTitle } from "react-bootstrap";
import { driverRequestApi, getPastRidesApi, getRequestDriverApi, userWalletDetailApi , addWalletTapWebAPI, ratingApi , getProfileDataApi , paybyCardRideApi} from '../../api/api'
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import Spinner from "react-bootstrap/Spinner";
import { TapCard, Currencies, Direction, Edges, Locale, Theme, Scope, ColorStyle } from '@tap-payments/card-web'
import axios from "axios";


import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
} from "react-google-maps";
import Rating from "../common/Rating";
import MapWithAMarker from "./MapReciept"
import $, { data } from 'jquery'
import { toast } from "react-toastify";

const custom_style = require("../../custom_style.json");

const options = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  draggable: false,
  scrollwheel: false,
  zoomControl: false,
  styles: custom_style,
};

const Reasons = [
  "Driver was late",
  "Driver behavior",
  "Driving too fast",
  "Cleanliness",
  "Professionalisam",
];
const initialReasons = {
  "Driver was late": false,
  "Driver behavior": false,
  "Driving too fast": false,
  Cleanliness: false,
  Professionalisam: false,
};
const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;
const RideReciept = (props) => {

  const params = useParams();

  const navigate = useNavigate();

  const [position, setPosition] = useState({
    lat: 29.3776824,
    lng: 47.9755194,
    text: "",
  });
  const [zoom, setZoom] = useState(8);
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [showPastRides, setShowPastRides] = useState(true);
  const [rate, setRate] = useState(0);
  const [showRateFill, setShowRateFill] = useState(0);
  const [tripDetail, setTripDetail] = useState();
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });
  const [distance, setDistance] = useState("0");
  const [paymentType, setPaymentType] = useState("");
  const [rateing, setRateinf] = useState(false);
  const [rateReason, setRateReason] = useState(false);
  const [faqModal, setFaqModal] = useState(false);
  const [payByCardModal, setPayByCardModal] = useState(false);
  const [ratecount, setRateCount] = useState(0);
  const [selectRateReasone, setSelectRateReasone] = useState(initialReasons);
  const [selectedReason, setSelectedReason] = useState(null); // Track selected reason
  const [totalTime, setTotalTime] = useState("--"); // Default state for total time
  const [isLoading, setIsLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [autoSaveCard, setAutoSaveCard] = useState(false);
  const [userProfileData, setUserProfileData] = useState(null);
  const [userCardObject, setUserCardObject] = useState();
  const [statusDue, setStatusDue] = useState('');
  const [statusText, setStatusText] = useState('');
  const [isPaymentButtonVisible, setIsPaymentButtonVisible] = useState(false);


  // const userProfile = props.userProfile
  // setUserProfileData(userProfile)


  useEffect(() => {
    // if(userDetail && userDetail.user_profile_id){
      get_trip_details()
      get_profile_data()
    // }

    
  }, [userDetail ])

  const decimaltohourmin = (minutes) => {
    var sign = minutes < 0 ? "-" : "";
    var min = Math.floor(Math.abs(minutes))
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  }


  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {
        setUserProfileData(data);
        setUserCardObject(data.user_card_info)
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  
  const getButtonClass = (status, requestId) => {
    switch(status) {
      case '1': // Accepted
      case '3': // Started
        return "status_accept";
      case '2': // Cancelled
        return "btn-danger";
      case '0':
      case '5': // Unattended
        return "btn-warning"; // You can define a custom class for unattended
      default:
        return "btn-success"; // Default
    }
  };

  const get_trip_details = async () => {
    try {
      let form = new FormData();
      form.append("request_id", params.id);
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);

      setIsLoading(true);
      var res = await getRequestDriverApi(form);
      var data = res.data;
      setIsLoading(false)
      if (data.status == "1") {
        // setIsLoading(false)
        var image_path = ''
        image_path = data.driverdetail.map_image;
        var map_image = image_path ? image_path.replace('https://roveimage.s3.me-south-1.amazonaws.com/images', 'https://www.riderove.com/dev_rove/uploads') : '';
        map_image = map_image ? map_image.replace('https://rovemain.rovesolution.com/', 'https://www.riderove.com/dev_rove/') : '';

        data.driverdetail.map_image = map_image

        var driver_profile_path = 'https://www.riderove.com/dev_rove/uploads/driver/'
        data.driver_profile_image = driver_profile_path + data.driverdetail.profileimage

        var originalDate = data?.driverdetail.pickup_later == '1' ? data.driverdetail.pickup_date_time : data.driverdetail.ride_time
        originalDate = TimeZone(originalDate)
        // console.log("test:",originalDate)
        // return false
        data.driverdetail.originalDate = originalDate;

        var picktime = data?.driverdetail.ride_time
        // var picktime = data?.driverdetail.start_ride_time
        picktime = TimeZone(picktime, ('hh:mm a '))
        data.driverdetail.picktime = picktime

        var dropoff = data?.driverdetail.end_time
        dropoff = TimeZone(dropoff, ('hh:mm a'))
        data.driverdetail.dropoff = dropoff

        var dateTime = data.driverdetail.pickup_later == "1" ? data.driverdetail.pickup_date_time : data.driverdetail.ride_time
        dateTime = TimeZone(dateTime)
        data.driverdetail.dateTime = dateTime


        var enablefeatures = localStorage.getItem('enablefeatures')
        enablefeatures = JSON.parse(enablefeatures)
        
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };
        
        var pickTime = data.driverdetail.start_ride_time
        // pickTime = TimeZone(pickTime, ('YYYY MMM DD, hh:mm:ss'))
        pickTime = TimeZone(pickTime, ('YYYY-MM-DDTHH:mm:ss'))
        var destTime = data.driverdetail.end_time
        // destTime = TimeZone(destTime, ('YYYY MMM DD, hh:mm:ss'))
        destTime = TimeZone(destTime, ('YYYY-MM-DDTHH:mm:ss'))
        
        const parsedPickTime = new Date(pickTime);
        const parsedDestTime = new Date(destTime);
        console.log(parsedPickTime,destTime,parsedDestTime);

        const diffInMs = parsedDestTime.getTime() - parsedPickTime.getTime();

        const diffSeconds = Math.floor(diffInMs / 1000 % 60);
        const diffMinutes = Math.floor(diffInMs / (60 * 1000) % 60);
        const diffHours = Math.floor(diffInMs / (60 * 60 * 1000) % 24);
        const diffDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000))
        var hours, minute, sec = null;
        hours = diffHours.toString()
        minute = diffMinutes.toString()
        sec = diffSeconds.toString()
        if(destTime != "Invalid date"){
          if (hours == "0") {
            if (minute.length == 1) {
              minute = "0" + minute;
            }
            if (sec.length == 1) {
              sec = "0" + sec;
            }
            var newtime = minute + ":" + sec
            setTotalTime(newtime + " Mins.");
          } else {
            if (hours.length == 1) {
              hours = "0" + hours;
            }
            if (minute.length == 1) {
              minute = "0" + minute;
            }
            if (sec.length == 1) {
              sec = "0" + sec;
            }
            newtime = hours + ":" + minute + ":" + sec;
            setTotalTime(newtime + " Mins.");
          }
        }

        var api_key = enablefeatures.nextbillion_api_key
        if (api_key) {
          let pickup_latlong = data.driverdetail.pickup_latitude + "," + data.driverdetail.pickup_longitude
          let destination_latlong = data.driverdetail.destination_latitude + "," + data.driverdetail.destination_longitude
          var next_billion_params = {
            key: api_key,
            origins: pickup_latlong,
            destinations: destination_latlong,
            mode: "car",
          };

          // console.log()
          var query_string = "";
          for (var param_key in next_billion_params) {
            let param_value = next_billion_params[param_key]
            query_string += param_key + '=' + param_value
            query_string += '&'
          }
          query_string = query_string.substring(0, query_string.length - 1);

          fetch("https://api.nextbillion.io/distancematrix/json?" + query_string, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              var distance_value = result.rows[0]['elements'][0]['distance']['value']
              distance_value = distance_value / 1000
              setDistance(distance_value.toFixed(2))
              // var duration_value = result.rows[0]['elements'][0]['duration']['value']
              // console.log(distance_value,duration_value)
              // duration_value = duration_value / 60
              // var newtime = decimaltohourmin(duration_value)
              // setTotalTime(newtime + " Mins.");
            })
            .catch((error) => {
              setIsLoading(false);
              console.error(error)
            });
          // console.log(res)
        }
        // else {

        //   var pickTime = data.driverdetail.ride_time
        //   pickTime = TimeZone(pickTime, ('YYYY MMM DD, hh:mm:ss'))
        //   var destTime = data.driverdetail.end_time
        //   destTime = TimeZone(destTime, ('YYYY MMMM DD, hh:mm:ss'))

        //   try {
        //     const parsedPickTime = new Date(pickTime);
        //     const parsedDestTime = new Date(destTime);

        //     const diffInMs = parsedDestTime.getTime() - parsedPickTime.getTime();

        //     const diffSeconds = Math.floor(diffInMs / 1000 % 60);
        //     const diffMinutes = Math.floor(diffInMs / (60 * 1000) % 60);
        //     const diffHours = Math.floor(diffInMs / (60 * 60 * 1000) % 24);
        //     const diffDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000))
        //     var hours, minute, sec = null;
        //     hours = diffHours.toString()
        //     minute = diffMinutes.toString()
        //     sec = diffSeconds.toString()

        //     if (hours == "0") {
        //       if (minute.length == 1) {
        //         minute = "0" + minute;
        //       }
        //       if (sec.length == 1) {
        //         sec = "0" + sec;
        //       }
        //       var newtime = minute + ":" + sec
        //       setTotalTime(newtime + " Mins.");
        //     } else {
        //       if (hours.length == 1) {
        //         hours = "0" + hours;
        //       }
        //       if (minute.length == 1) {
        //         minute = "0" + minute;
        //       }
        //       if (sec.length == 1) {
        //         sec = "0" + sec;
        //       }
        //       newtime = hours + ":" + minute + ":" + sec;
        //       setTotalTime(newtime + " Mins.");
        //       //txtTotalMiles.setText(time + " mins");
        //     }

        //   } catch (error) {
        //     setIsLoading(false);
        //     console.error("Error calculating travel time:", error); // Handle errors
        //   }
        // }

        if (data.driverdetail.is_wallet_paid == "1") {
          setPaymentType("Wallet")
        } else if (data.driverdetail.payment_type == "0") {
          setPaymentType("Cash")
        } else if (data.driverdetail.payment_type == "1") {
          setPaymentType("knet")
        } else if (data.driverdetail.payment_type == "3") {
          setPaymentType("Apple Pay")
        } else {
          setPaymentType("Card(Visa/Mastercard)")
        }




        var status = data.ride_status
        if (status == "1" && data.driverdetail.is_arrived == "1") {
          setStatusText("Arrived");
        } else if (status == "1") {
          setStatusText("Accepted");
        } else if (status == "3") {
          setStatusText("Started");
        } else if (status == "4") {
          setStatusText("Finished");
        } else if (status == "2") {
          setStatusText("Cancelled");
        } else if (status == "0" || status == "5") {
          setStatusText("Unattended");
        }


        data.buttonClass = getButtonClass(status, data.request_id);

      


        const Transaction_status = data.driverdetail.transaction_status
      
        // if(Transaction_status != null && !Transaction_status == "null" && !Transaction_status == "" && !Transaction_status == "FAIL" && !Transaction_status == "PENDING"){
        //     $('#pay_by_knet').addClass('d-none')
        //     $('#needHelp')
        // }else{
        //     $('#pay_by_knet').removeClass('d-none')
        // }

        if (Transaction_status == "1" || Transaction_status == "SUCCESS") {
          getRequestDriverApi()
          // setStatusText("Paid");

          // data.driverdetail.status_text = 'Paid';

          // llPaidNotPaid.setVisibility(View.GONE);
          // llPendingAmount.setVisibility(View.GONE);
          // btnPaidUnPaidStatus.setText(getString(R.string.paid));
          // btnPayByWallet.setVisibility(View.GONE);
          // btnPayByCard.setVisibility(View.GONE);
          // imgBack.setVisibility(View.VISIBLE);
        } else {
          // imgBack.setVisibility(View.VISIBLE);
          setTimeout(() => {
            console.log(userCardObject)
            if(userCardObject && Object.keys(userCardObject).length > 0) {
            $('#pay_by_card').removeClass('d-none')
            }
              $('#pay_by_knet').removeClass('d-none')
            // $('#pay_by_wallet').removeClass('d-none')
            $('#pending,#pending_amount').removeClass('d-none')
            // llPendingAmount.setVisibility(View.VISIBLE);
            // txtPendingAmount.setText(pendingAmount);
            // btnPaidUnPaidStatus.setText(getString(R.string.payment_due));
            if (data.driverdetail.isWalletBalanceAvailable == "1") {
              if (enablefeatures.wallet) {
                $('#pay_by_wallet').removeClass('d-none')
              }
              // btnPayByWallet.setVisibility(CONSTANT.IS_SHOW_WALLET_MODULE ? View.VISIBLE : View.GONE);
            } else {
              // btnPayByWallet.setVisibility(View.GONE);
            }
          }, 200)
        }

        if (data.driverdetail.pickup_later == "0") {
          if (data.driverdetail.transaction_status !== null) {
            if (data.driverdetail.transaction_status === "PENDING" || data.driverdetail.transaction_status === "0") {
              if (status !== "0" && status !== "1" && status !== "3") {
                setStatusDue('Payment Due');
                setIsPaymentButtonVisible(true);
                // setButtonClass('btn-danger');
              }
            } else {
              if (status !== "2" && status !== "0" && status !== "1" && status !== "3") {
                setStatusText('Paid');
              }
            }
          } else {
            if (status !== "0" && status !== "1" && status !== "3") {
              setStatusDue('Payment Due');
              setIsPaymentButtonVisible(true);
              // setButtonClass('btn-danger');
            }
          }
        } else {
          if (data.driverdetail.transaction_status === "PENDING" || data.driverdetail.transaction_status === "0") {
            if (status !== "0" && status !== "1" && status !== "3") {
              setStatusDue('Payment Due');
              setIsPaymentButtonVisible(true);
              // setButtonClass('btn-danger');
            }
          } else {
            if (status != "2" && status != "0" && status != "1" && status != "3") {
              setStatusText('Paid');
            }
          }
        }
        setRate(parseInt(data.driverdetail.ride_rating))
        setTripDetail(data)

      }
      // console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.log("get_trip_details", error);
    }
  };

  const paybycard_ride_api = async () => {
    setIsLoading(true); 
    try {
      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("request_id", params.id);
  
      var res = await paybyCardRideApi(form);
      var data = res.data;
  
      if (data.status == "1") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      await get_profile_data();
      return data;
  
    } catch (error) {
      console.error("paybycard_ride_api", error);
      toast.error("An error occurred while processing payment.");
    } finally {
      setIsLoading(false);
    }
  };
  

  const TimeZone = (time, format = 'Do MMM YYYY, h:mm a') => {
    return moment.utc(time).tz("Asia/Kuwait").format(format);
  };
  // const TimeZone = (time, format = 'Do MMM YYYY, h:mm a') => {
  //   return moment(time).format(format);
  // };
  


  // const [tap, setTap] = useState(null);
  const formRef = useRef(null);
  const [error, setError] = useState('');
  const [token, setToken] = useState('');


  // useEffect(() => {
  //   // Load Tap.js library
  //   const script = document.createElement('script');
  //   script.src = 'https://your-tap-payments-library-url"';
  //   script.async = true;
  //   script.onload = () => {
  //     const tapInstance = window.Tapjsli('pk_test_EtHFV4BuPQokJT6jiROls87Y');
  //     setTap(tapInstance);
  //   };
  //   document.body.appendChild(script);
  // }, []);

  // useEffect(() => {
  //   if (!tap) return;

  //   const elements = tap.elements({});
  //   const style = {
  //     base: {
  //       color: '#535353',
  //       lineHeight: '18px',
  //       fontFamily: 'sans-serif',
  //       fontSmoothing: 'antialiased',
  //       fontSize: '16px',
  //       '::placeholder': {
  //         color: 'rgba(0, 0, 0, 0.26)',
  //         fontSize: '15px',
  //       },
  //     },
  //     invalid: {
  //       color: 'red',
  //     },
  //   };

  //   const labels = {
  //     cardNumber: 'Card Number',
  //     expirationDate: 'MM/YY',
  //     cvv: 'CVV',
  //     cardHolder: 'Card Holder Name',
  //   };

  //   const paymentOptions = {
  //     currencyCode: ['KWD', 'USD', 'SAR'],
  //     labels,
  //     TextDirection: 'ltr',
  //   };

  //   const card = elements.create('card', { style }, paymentOptions);
  //   card.mount('#element-container');

  //   card.addEventListener('change', (event) => {
  //     if (event.BIN) {
  //       console.log(event.BIN);
  //     }
  //     if (event.loaded) {
  //       console.log('UI loaded :' + event.loaded);
  //       console.log('current currency is :' + card.getCurrency());
  //     }
  //     const displayError = document.getElementById('error-handler');
  //     if (event.error) {
  //       displayError.textContent = event.error.message;
  //     } else {
  //       displayError.textContent = '';
  //     }
  //   });

  //   formRef.current.addEventListener('submit', (event) => {
  //     event.preventDefault();

  //     tap.createToken(card).then((result) => {
  //       console.log(result);
  //       if (result.error) {
  //         setError(result.error.message);
  //       } else {
  //         setToken(result.id);
  //       }
  //     });
  //   });
  // }, [tap]);





  const [emailData, setEmailData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });


  const handleClick = () => {
    const encodedSubject = encodeURIComponent("Rove Inquiry");
    const encodedBody = encodeURIComponent(`
    Name: ${userDetail?.name_ara}
    Mobile: ${props.userProfile?.user_profile.mobileno}
    Email: ${userDetail?.user_email}
    Message: 
    `);

    const url = `https://mail.google.com/mail/?view=cm&fs=1&to=info@riderove.com&su=${encodedSubject}&body=${encodedBody}`;

    window.open(url, '_blank');
  };

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  var mailtoHref = "mailto:info@riderove.com?subject=SendMail&body=Description";




  const paymentKnetHandler = async (reqID) => {

    var data1 = {
      user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
      user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
    };

    var form = new FormData();
    form.append("user_type", data1.user_type);
    form.append("user_id", data1.user_id);
    form.append("amount",   tripDetail?.driverdetail.price);

    setIsLoading(true)
    var res = await addWalletTapWebAPI(form);
    var data = res.data;
    if (data.status == "1") {
      let wallet_pay_url = tripDetail?.driverdetail.payment_url ? tripDetail?.driverdetail.payment_url : '';
      wallet_pay_url = wallet_pay_url.replace(
        "https://rovemain.rovesolution.com",
        "https://www.riderove.com/dev_rove"
      );
      setIsLoading(false)


      // window.open(wallet_pay_url, '_blank');
      // var win = window.open(wallet_pay_url, "popup", "width=600,height=600");

      var win = window.open(wallet_pay_url, "popup", "width=600,height=600");
      if (!win || win.closed || typeof win.closed === "undefined") {
        alert("Pop-up blocked! Please enable pop-ups for this site");
        return;
      }
      // win = window.open(wallet_pay_url,'_blank');


      var timer = setInterval(function () {
        if (win && win.closed) {
          clearInterval(timer);
          get_profile_data();
          get_trip_details()
          getRequestDriverApi()
          toast.success(data.message, {
            position: "top-center",
            autoClose: 2000,
          });

        }
      }, 1000);

    } else {
      console.log("Error : add wallet tap payment", data);
    }
  };
 

  // function callbackFunc(response) {
  //   console.log(response);
  // }
  // //pass your public key from tap's dashboard


  // const handleSelectReasone = (reason) => {
  //   // Set the selected reason to true and others to false

  //   const updatedReasons = {};
  //   Reasons.forEach((item) => {
  //     updatedReasons[item] = item === reason;
  //   });

  //   //  select multiple reasones

  //   // const updatedReasons = { ...selectRateReasone };
  //   // updatedReasons[reason] = !updatedReasons[reason]; // Toggle the selected reason

  //   setSelectRateReasone(updatedReasons);
  // };

  const rateHandler = () => {
    if (rate == "4" || rate == "5") {
      ratingHandlerApi()
    }
  }

  // const user_wallet_detail = async () => {
  //   try {
  //     var data1 = {
  //       user_type: userDetail.user_type, // 0 android, 1 ios
  //       user_id: userDetail.user_profile_id,
  //     };

  //     var form = new FormData();
  //     form.append("user_type", data1.user_type);
  //     form.append("user_id", data1.user_id);

  //     var res = await  userWalletDetailApi(form);
  //     var data = res.data;
  //     setWalletDetail(data);
  //     return data;
  //   } catch (error) {
  //     console.log("USERWALLETDETAIL", error)
  //   }
  // }

  const handleSelectReasone = (item) => {
    // Update all reasons to false, then set the clicked item to true
    setSelectRateReasone({
      ...Object.fromEntries(
        Object.entries(selectRateReasone).map(([key, value]) => [key, key === item ? true : false])
      ),
    });
    setSelectedReason(item); // Track selected reason
  };


  const saveCardHandler = () =>{
    if(saveCardDetails){
    console.log('card saved')
    setSaveCard(true)
    setAutoSaveCard(true)
    } else{
      console.log('Save card option is not selected');

    }
  }
  const [saveCardDetails, setSaveCardDetails] = useState(false);

  const handleSaveCardChange = (data) => {
    console.log('onChangeSaveCardLater', data);
    setSaveCardDetails(data.saveCardDetails); // Assuming the callback returns an object with saveCard property
  };
  const ratingHandlerApi = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("customer_id", data1.user_id);
      form.append("driver_id", tripDetail.driverdetail.driver_id)
      form.append("request_id", tripDetail.driverdetail.request_id)
      form.append("ratting", rate)
      form.append("type", 1) // 1 for user 
      form.append("reason", selectedReason ? selectedReason : '')
      var res = await ratingApi(form);
      var data = res.data;
      if (data.status == "1") {
        // setRateinf(data)
      } else {
        console.log("Error : while adding ratings", data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }


 const handleModalRate = () => {
  // Check if the rating is already set
  if (tripDetail?.driverdetail.ride_rating !== '0.0' || rate > 0) {
    setRateinf(false); // Don't open the modal if already rated
  } else {
    setRateinf(true); // Open the modal if no rating has been set
  }
};


  const handleRatingClose = () => {
    setRateinf(false);
  };
  const handleRateReasonClose = () => {
    setRateReason(false);
  };
  const handleFaqModal = () => {
    setFaqModal(false);
  };

  const handleCardModal = () => {
    setPayByCardModal(false)
  }

  const [response, setResponse] = useState(null);

  const payByCardHandler = async () => {
    setPayByCardModal(true)
  }




  const DirectionsService = new google.maps.DirectionsService();

  const aftersetDestinationPosition = (pickup, destination) => {
    if (pickup && destination) {
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(pickup),
          destination: new google.maps.LatLng(destination),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log(status);
          if (status === google.maps.DirectionsStatus.OK) {
            console.log("ok", result);
            setRouteDirections(result);
            // setRequestRide(true);
          } else {
            console.log("err", result);
          }
          console.log(routeDirections);
        }
      );
    }
  };

  useEffect(() => {
    var pickup = localStorage.getItem("pickupPosition");
    var destination = localStorage.getItem("destinationPosition");
    var pp = {
      lat: 29.2574643,
      lng: 47.9404039,
      text: "Liberation Tower, Abdulla Al Salem Street, Kuwait City, Kuwait",
    };
    // var pp = JSON.parse(pickup);
    setPickupPosition(pp);
    setPosition(pp);
    setZoom(18);

    var dp = {
      lat: 29.3033426,
      lng: 47.9605811,
      text: "Al Hassan Bin Ali, Al Kumait Street, State of Kuwait",
    };
    // var dp = JSON.parse(destination);
    setDestinationPosition(dp);
    aftersetDestinationPosition(pp, dp);
  }, []);
  return (
    <>

      <main className="main">
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        {
          isLoading ? (
            <div className="d-flex justify-content-center align-items-center  ">
              <Spinner animation="border" className="mt-5" role="status">
                <span className="visually-hidden ">Loading...</span>
              </Spinner>
            </div>) : (
            <section className="section mt-40 mb-100 " id="my-trip">
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-md-1 col-lg-1 "></div>
                  <div className="col-md-2 col-lg-2 ">
                    <Link to={"/my-trips"} className={`btn text-profile activ`}>
                      Past Rides
                    </Link>
                    <Link
                      to={{
                        pathname: "/my-trips",
                        search: `?${createSearchParams({
                          upcoming: "1",
                        })}`,
                      }}
                      className={`btn text-profile`}
                    >
                      Upcoming Rides
                    </Link>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center mb-20">
                        <h2 className="text-rove fw-bold">Ride Details</h2>
                      </div>
                      <div className="_css-imdnMt mb-5">
                        {console.log}
                        <img
                          className="map_image"
                          alt="map not available"
                          src={tripDetail?.userdetails.map_image.replace(
                              "https://rovemain.rovesolution.com/uploads",
                              "https://www.riderove.com/dev_rove/uploads"
                          )}
                         
                          onLoad={(e) => {
                            console.log("Image loaded successfully"); // Optional: Debug or handle loading state
                          }}                        />
                        <div className="_css-cAseJQ d-flex justify-content-between">
                          <div className="d-flex w-50">
                            <img
                              src={tripDetail?.driver_profile_image}
                              className="dropbtn"
                              style={{ height: "55px", width: "55px" }}
                              alt="person"
                            />
                            <div className="mx-3">
                              <h6 className="fw-bold text-secondary">{tripDetail?.driverdetail.driver_name}</h6>
                              <p className="fw-bold text-rove">{tripDetail ? tripDetail.driverdetail.car_name_en : tripDetail?.driverdetail.car_name_ar}</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end w-50 mx-3">
                            <div>
                              <h6 className="fw-bold text-secondary">
                                {tripDetail?.driverdetail.originalDate}
                              </h6>
                              <p className="text-end fw-bold text-rove">
                                KD <span className="fs-5">{tripDetail?.driverdetail.price}</span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="mx-3 col-12 d-flex mb-3">
                            <div className="col-sm-1 col-md-1">
                              <span>
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  data-baseweb="icon"
                                >
                                  <title>search</title>
                                  <path
                                    fillRule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm5-2a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <div className="col-sm-11-col-md-11">
                              <p className="mb-0 text-secondary">
                                Pickup ({tripDetail?.driverdetail.picktime})
                              </p>
                              <h6 className="fw-bold text-rove  mb-0">
                                {tripDetail?.driverdetail.pickup_address}
                              </h6>
                            </div>
                          </div>
                          <div className="mx-3 col-12 d-flex mb-3">
                            <div className="col-sm-1 col-md-1">
                              <span>
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  data-baseweb="icon"
                                >
                                  <title>search</title>
                                  <path
                                    fillRule="evenodd"
                                    clip-rule="evenodd"
                                    d="M14 10h-4v4h4v-4ZM7 7v10h10V7H7Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <div className="col-sm-11-col-md-11">
                              <p className="mb-0 text-secondary">
                                Drop Off ({tripDetail?.driverdetail.dropoff})
                              </p>
                              <h6 className="fw-bold text-rove  mb-0">
                                {tripDetail?.driverdetail.destination_address}
                              </h6>
                            </div>
                          </div>

                          <div className="row">
                            <div className="mx-3 mb-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50 ">
                                <h5 className="fw-bold text-rove">Status</h5>
                              </div>
                              <div className="text-end w-50">
                                {/* <h5>
                                  <span class="badge text-bg-danger p-2">
                                    Unpaid
                                  </span>
                                </h5> */}
                                {statusDue != ''  ? (
                                <button
                                  className={`btn btn-danger py-1 `}
                                  id={`btn-payment-due-` + params.id}
                                >
                                  {/* {tripDetail?.driverdetail.status_due} */}
                                  {statusDue}
                                  {/* Unpaid */}
                                </button>

                                ) :
                                
                                <button
                                  className={`btn btn-success border-none py-1`}
                                  id={`btn-payment-sucess-` + params.id}
                                >
                                  {/* paid2 */}
                                  {/* {tripDetail?.driverdetail.status_text} */}
                                  {statusText}
                                </button>
                                }
                              </div>
                            </div>
                            <div className="mx-3 mb-4 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-30">
                                <h5 className="fw-bold text-rove">You Rated</h5>
                              </div>
                              <div
                                className="text-end w-70"
                                onClick={handleModalRate}
                              >
                                {[...Array(5)].map((item, index) => {
                                  const givenRating = index + 1;
                                  return (
                                    <img
                                      key={index}
                                      src={`/assets/imgs/icons/${givenRating < rate || givenRating === rate
                                        ? "star-fill"
                                        : "star"
                                        }.png`}
                                      // onClick={() => { setRate(givenRating); }}
                                      className="dropbtn rateing-img"
                                      alt="start"
                                      width={40}
                                      height={20}
                                    />
                                  );
                                })}
                                {/* <Rating width={40} height={40} ></Rating> */}
                              </div>
                            </div>
                            <div className="mb-3 mx-3 col-12 col-md-12 col-lg-12 col-sm-12 ">
                              <h5 className="fw-bold text-rove">Invoice Detail</h5>
                            </div>

                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">
                                  Date & Time
                                </h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove">
                                  {tripDetail?.driverdetail.dateTime}
                                </h6>
                              </div>
                            </div>
                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">Trip ID</h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove">{tripDetail?.driverdetail.rand_ride_id}</h6>
                              </div>
                            </div>
                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">Distance</h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove">{distance}Km</h6>
                              </div>
                            </div>
                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">
                                  Car Plate
                                </h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove">{tripDetail?.driverdetail.licence_number}</h6>
                              </div>
                            </div>
                            {totalTime != "--" && <> <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">Duration</h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove"> {totalTime}</h6>
                              </div>
                            </div></>}
                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">
                                  Payment Type
                                </h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove">{paymentType}</h6>
                              </div>
                            </div>
                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">
                                  Base Fare
                                </h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove">
                                  KD <span className="fs-5">{tripDetail?.userdetails.basefare}</span>
                                </h6>
                              </div>
                            </div>
                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50">
                                <h6 className="fw-bold text-secondary">
                                  Distance Charge
                                </h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove">
                                  KD <span className="fs-5">{tripDetail?.driverdetail.ridefare ? tripDetail?.driverdetail.ridefare : '--'}</span>
                                </h6>
                              </div>
                            </div>

                            {tripDetail?.driverdetail.waiting_charge && tripDetail?.driverdetail.waiting_charge != '0' && tripDetail?.driverdetail.waiting_charge != '0.000' && tripDetail?.driverdetail.waiting_charge != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3  col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50 ">
                                    <h6 className="fw-bold text-secondary">
                                      Waiting Charge
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove ">
                                      KD <span className="fs-5">{tripDetail?.driverdetail.waiting_charge}</span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                              : ''}
                            {tripDetail?.driverdetail.cancelation_fee && tripDetail?.driverdetail.cancelation_fee != '0' && tripDetail?.driverdetail.cancelation_fee != '0.000' && tripDetail?.driverdetail.cancelation_fee != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50">
                                    <h6 className="fw-bold text-secondary">
                                      Cancellation Charge
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove">
                                      KD <span className="fs-5">{tripDetail?.driverdetail.cancelation_fee ? tripDetail?.driverdetail.cancelation_fee : '--'}</span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                              : ''}

                            {tripDetail?.driverdetail.discount_charge && tripDetail?.driverdetail.discount_charge != '0' && tripDetail?.driverdetail.discount_charge != '0.000' && tripDetail?.driverdetail.discount_charge != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50 ">
                                    <h6 className="fw-bold text-secondary">
                                      Discount Charge
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove ">
                                      KD <span className="fs-5">{tripDetail?.driverdetail.discount_charge ? tripDetail?.driverdetail.discount_charge : '--'}</span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                              : ''}

                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50 ">
                                <h6 className="fw-bold text-rove ">Sub Total</h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold text-rove ">
                                  KD <span className="fs-5">{tripDetail?.driverdetail.price}</span>
                                </h6>
                              </div>
                            </div>

                            {tripDetail?.driverdetail.driver_collect_cash && tripDetail?.driverdetail.driver_collect_cash != '0' && tripDetail?.driverdetail.driver_collect_cash != '0.000' && tripDetail?.driverdetail.driver_collect_cash != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50">
                                    <h6 className="fw-bold text-secondary">
                                      Cash
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove">
                                      KD <span className="fs-5">{tripDetail?.driverdetail.driver_collect_cash ? tripDetail?.driverdetail.driver_collect_cash : '--'}</span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                              : ''}
                            {tripDetail?.driverdetail.online_knet_debit && tripDetail?.driverdetail.online_knet_debit != '0' && tripDetail?.driverdetail.online_knet_debit != '0.000' && tripDetail?.driverdetail.online_knet_debit != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50">
                                    <h6 className="fw-bold text-secondary">
                                      KNET
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove">
                                      KD <span className="fs-5">{tripDetail?.driverdetail.online_knet_debit ? tripDetail?.driverdetail.online_knet_debit : '--'}</span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                              : ''}
                            {tripDetail?.driverdetail.card_debit && tripDetail?.driverdetail.card_debit != '0' && tripDetail?.driverdetail.card_debit != '0.000' && tripDetail?.driverdetail.card_debit != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50">
                                    <h6 className="fw-bold text-secondary">
                                      Card
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove">
                                      KD <span className="fs-5">{tripDetail?.driverdetail.card_debit ? tripDetail?.driverdetail.card_debit : '--'}</span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                              : ''}
                            {tripDetail?.driverdetail.apple_pay_debit && tripDetail?.driverdetail.apple_pay_debit != '0' && tripDetail?.driverdetail.apple_pay_debit != '0.000' && tripDetail?.driverdetail.apple_pay_debit != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50">
                                    <h6 className="fw-bold text-secondary">
                                      Apple Pay
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove">
                                      KD <span className="fs-5">{tripDetail?.driverdetail.apple_pay_debit ? tripDetail?.driverdetail.apple_pay_debit : '--'}</span>
                                    </h6>
                                  </div>
                                </div>
                              </> : ''}
                            {tripDetail?.driverdetail.wallet_debit && tripDetail?.driverdetail.wallet_debit != '0' && tripDetail?.driverdetail.wallet_debit != '0.000' && tripDetail?.driverdetail.wallet_debit != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50">
                                    <h6 className="fw-bold text-secondary">
                                      Wallet Debit
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove">
                                      KD <span className="fs-5">{tripDetail?.userdetails.wallet_debit ? tripDetail?.userdetails.wallet_debit : ''}</span>
                                    </h6>
                                  </div>
                                </div>
                              </>
                              : ''}
                            {tripDetail?.driverdetail.wallet_credit && tripDetail?.driverdetail.wallet_credit != '0' && tripDetail?.driverdetail.wallet_credit != '0.000' && tripDetail?.driverdetail.wallet_credit != '' ?
                              <>
                                <hr className="mx-3" />
                                <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                                  <div className="w-50">
                                    <h6 className="fw-bold text-secondary">
                                      Wallet Credit
                                    </h6>
                                  </div>
                                  <div className="text-end w-50">
                                    <h6 className="fw-bold text-rove">
                                      KD <span className="fs-5">{tripDetail?.userdetails.wallet_credit ? tripDetail?.userdetails.wallet_credit : ''}</span>
                                    </h6>
                                  </div>
                                </div>
                              </> : ''}
                            <hr className="mx-3" />
                            <div className="mx-3 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-between">
                              <div className="w-50 d-none" id="pending">
                                <h6 className="fw-bold  text-rove">
                                  Pending
                                </h6>
                              </div>
                              <div className="text-end w-50">
                                <h6 className="fw-bold d-none -txt text-rove" id="pending_amount" >
                                  KD <span className="fs-5">{tripDetail?.userdetails.payable_amount}</span>
                                </h6>
                              </div>
                            </div>




                            <div className="mx-3 row mt-5">
                              <div className="d-flex justify-content-center">
                                {/* <div className="col-md-4 col-12 mb-10">
                                      Pay &nbsp;by &nbsp;Wallet
                                      <button className="btn btn-primary w-100  fw-bold">
                                    </button>
                                  </div> */}

                                <div className="col-md-4 d-none mx-2 col-12 mb-10" id="pay_by_wallet">
                                  <button
                                    className="btn btn-primary  w-100 fw-bold"
                                  >
                                    Pay &nbsp;by &nbsp;Wallet
                                  </button>
                                </div>
                                <div className="col-md-4 mx-2 d-none col-12 mb-10" id="pay_by_knet">
                                  <button className="btn btn-primary w-100 fw-bold" onClick={paymentKnetHandler}>
                                    Pay &nbsp;by &nbsp;Knet
                                  </button>
                                </div>
                                <div className="col-md-4 mx-2 d-none col-12 mb-10" id="pay_by_card">
                                  <button className="btn btn-primary w-100 fw-bold" onClick={paybycard_ride_api}>
                                    {/* {response && <pre>{JSON.stringify(response, null, 2)}</pre>} */}
                                    Pay &nbsp;by &nbsp;Card
                                  </button>
                                </div>
                              </div>
                              {/* <TapCard
                                publicKey='pk_test_EtHFV4BuPQokJT6jiROls87Y'
                                // merchant={{
                                //   id: 'merchant id'
                                // }}
                                transaction={{
                                  amount: 1,
                                  currency: Currencies.SAR
                                }}
                                customer={{
                                  id: 'cus_TS02A5820241445e2B60406040',
                                  name: [
                                    {
                                      lang: Locale.EN,
                                      first: 'Ahmed',
                                      last: 'Sharkawy',
                                      middle: 'Mohamed'
                                    }
                                  ],
                                  nameOnCard: 'Ahmed Sharkawy',
                                  editable: true,
                                  contact: {
                                    email: 'ahmed@gmail.com',
                                    phone: {
                                      countryCode: '20',
                                      number: '1000000000'
                                    }
                                  }
                                }}
                                acceptance={{
                                  supportedBrands: ['AMEX', 'VISA', 'MASTERCARD', 'MADA'],
                                  supportedCards: ['CREDIT', 'DEBIT']
                                }}
                                fields={{
                                  cardHolder: true
                                }}
                                addons={{
                                  displayPaymentBrands: true,
                                  loader: true,
                                  saveCard: true
                                }}
                                interface={{
                                  locale: Locale.EN,
                                  theme: Theme.LIGHT,
                                  edges: Edges.CURVED,
                                  direction: Direction.LTR
                                }}
                                onReady={() => console.log('onReady')}
                                onFocus={() => console.log('onFocus')}
                                onBinIdentification={(data) => console.log('onBinIdentification', data)}
                                onValidInput={(data) => console.log('onValidInputChange', data)}
                                onInvalidInput={(data) => console.log('onInvalidInput', data)}
                                onError={(data) => console.log('onError', data)}
                                onSuccess={(data) => console.log('onSuccess', data)}
                              /> */}
                              <div className="d-flex justify-content-center col-md-12 mt">
                                <div className="col-md-4 col-12 mb-10">
                                  <button
                                    className="btn btn-help w-100 fw-bold"
                                    id="needHelp"
                                    onClick={() => { setFaqModal(true) }}
                                  >
                                    Need  &nbsp;Help ?
                                  </button>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 px-5 trip-last-section">
                    <img
                      // src="/assets/imgs/icons/my-trip-2.svg"
                      src="/assets/imgs/bg/cars2.jpg"
                      alt="my-trip-2"
                      className="mb-3"
                    />
                    <h6>Get a ride in minutes</h6>
                    <p>
                      Book an Rove from a web browser, no app install necessary.
                    </p>
                    <div className="mt-20 w-70">
                      <Link
                        className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                        to="/dashboard"
                      >
                        Request a Ride
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

        <Modal show={rateing} onHide={handleRatingClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Rate Your Trip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-center">
              {[...Array(5)].map((item, index) => {
                const givenRating = index + 1;
                return (
                  <img
                    key={index}
                    src={`/assets/imgs/icons/${givenRating < rate || givenRating === rate
                      ? "star-fill"
                      : "star"
                      }.png`}

                    onClick={() => {
                      setRate(givenRating);
                    }}
                    className="dropbtn rateing-img"
                    alt="start"
                    width={40}
                    height={20}
                  />
                );
              })}
              {/* <Rating width={30} height={30}></Rating> */}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <button className="btn btn-light w-45" onClick={handleRatingClose}>
              Cancel
            </button>
            <button
              className="btn btn-primary w-45"
              onClick={() => {
                setRateinf(false);
                rateHandler()
                // ratingHandlerApi()
                if (rate <= 3) {
                  setRateReason(true);
                }
              }}
            >
              Done
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={rateReason} onHide={handleRateReasonClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Rate Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-center mb-4">
                {[...Array(5)].map((item, index) => {
                  const givenRating = index + 1;
                  return (
                    <img
                      key={index}
                      src={`/assets/imgs/icons/${givenRating < rate || givenRating === rate
                        ? "star-fill"
                        : "star"
                        }.png`}
                      // defaultChecked={tripDetail?.driverdetail.ride_rating}
                      onClick={() => { setRate(givenRating); }}
                      className="dropbtn rateing-img"
                      // data-rate = {console.log(rate)}
                      alt="start"
                      width={40}
                      height={20}
                    />
                  );
                })}

                {/* <Rating width={30} height={30} initialState={3}></Rating> */}
              </div>

              <h6 className="fw-bold">Why did you rate {rate} stars ?</h6>

              <div className="row my-3 px-3">
                {Reasons.map((item, inex) => (
                  <div
                    key={inex}
                    id="reason"
                    className={`col-12 col-md-12 form-control ${selectRateReasone[item] ? "active" : ""
                      } my-2 pt-3 text-secondary`}
                    onClick={() => {
                      handleSelectReasone(item);
                      // console.log(selectRateReasone)
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <button
              className="btn btn-light w-45"
              onClick={handleRateReasonClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary w-45"
              onClick={() => {
                setRateReason(false);
                ratingHandlerApi()
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={faqModal} onHide={handleFaqModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Need Help?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex   col-md-12 flex-column">
              <div className="image-container  col-md-4 mb-2">
                <img src="/assets/imgs/ride_icons/call_icon.png" style={{ border: '1px solid gray', borderRadius: '4rem', padding: '4px', height: '40%', width: '30%' }} />
                <h4 className="need_help_txt">+965 1812 888</h4>
              </div>
              <div className=" image-container col-md-4 mb-2" onClick={handleClick}>
                <img src="/assets/imgs/ride_icons/email.png" style={{ border: '1px solid gray', borderRadius: '4rem', padding: '4px', height: '40%', width: '30%' }} />
                {/* <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@riderove.com&cc=someone@ola.example&bcc=someone.else@example.com&su=Rove Inquiry&body=Name: ,Mobile: , Email:, Message: " target="_blank">Mail Us</a> */}
                {/* const url = `https://mail.google.com/mail/?view=cm&fs=1&to=info@riderove.com&cc=someone@ola.example&bcc=someone.else@example.com&su=${encodedSubject}&body=${encodedBody}`; */}
                <h4 className="need_help_txt">Email</h4>
              </div>
            </div>
            <div className="">
              <p>You may also took a look at FAQ page</p>
            </div>
            <Link
              to='/faqs'
              className="btn btn-primary   w-100"
            // onClick={handleRateReasonClose}
            >
              Faqs
            </Link>
          </Modal.Body>
          {/* <Modal.Footer className="justify-content-between">
          </Modal.Footer> */}
        </Modal>
        {/* <Modal show={payByCardModal} onHide={handleCardModal} cente>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Enter Card Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TapCard
              scope={Scope.TOKEN}
              operator={{
                publicKey: 'pk_test_QKYn2pTCRjaEVZ7MbIcXHGW6'
              }}
              transaction={{
                reference: 'transaction_xxx',
                paymentAgreement: {
                  id: '',
                  contract: {
                    id: ''
                  }
                }
              }}
              order={{
                id: '',
                amount: 1,
                currency: Currencies.KWD,
                description: 'description',
                reference: '',
                metadata: { key: 'value' }
              }}
              invoice={{
                id: 'invoice_xxx'
              }}
              customer={{
                // id: 'cus_TS01A1120241425Ju991206409',
                id: userProfileData?.user_profile.tap_customer_id,
                name: [
                  {
                    lang: Locale.EN,
                    first: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[0] : '',
                    last: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[1] : '',
                    middle: ''
                  }
                ],
                nameOnCard:  userProfileData?.user_profile.name_eng,
                editable: true,
                contact: {
                  email: userProfileData?.user_profile.user_email,
                  phone: {
                    countryCode: userProfileData?.user_profile.country_code,
                    number: userProfileData?.user_profile.mobileno
                  }
                }
              }}
              merchant={{
                id: ''
              }}
              interface={{
                locale: Locale.EN,
                cardDirection: Direction.DYNAMIC,
                edges: Edges.CURVED,
                theme: Theme.DYNAMIC,
                powered: true,
                colorStyle: ColorStyle.COLORED,
                loader: true
              }}
              features={{
                acceptanceBadge: true,
                customerCards: {
                  saveCard: true,
                  autoSaveCard: true,
                }
              }}
              fieldsVisibility={{
                card: {
                  cardHolder: true
                }
              }}
              acceptance={{
                supportedSchemes: ['AMEX', 'VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
                supportedFundSource: ['CREDIT', 'DEBIT'],
                supportedPaymentAuthentications: ['3DS']
              }}
              post={{
                url: "https://tap.company"
              }}
              onReady={() => console.log('onReady')}
              onFocus={() => console.log('onFocus')}
              onBinIdentification={(data) => console.log('onBinIdentification', data)}
              onValidInput={(data) => console.log('onValidInputChange', data)}
              onError={(data) => console.log('onError', data)}
              // onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
              onChangeSaveCardLater={handleSaveCardChange}
              onSuccess={(data) => console.log('onSuccess', data)}
            />
            <button onClick={saveCardHandler}>Save</button>
          </Modal.Body>
        </Modal> */}
      </main>
    </>
  );
};

export default RideReciept;
