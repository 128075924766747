import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
} from "react-google-maps";

// import MapWithAMarker from "./MapReciept";
import Spinner from "react-bootstrap/Spinner";
// import moment from 'moment-timezone';
import {
  getPastRidesApi,
  getProfileDataApi,
  removeHistoryApi,
  nearSearchLocationApi,
  enableFeaturesApi,
  getDriverApi,
  fareBreakDownApi,
  priceCalculateApi,
  updateUpcomingRideApi,
  pickupLaterUpdateApi,
  getLongRentalCarApi,
  addWalletTapWebAPI,
  airportCarSelectionApi,
  saveTapCardApi,
  retrieveTapCustomerApi,
  paybyCardRideApi,
  addCardWalletAPI,
  addCardApi,
  cancleRideRequestApi
} from "../../api/api";
import moment from "moment-timezone";
import Pagination from "react-js-pagination";
// import "bootstrap-less";
import $ from "jquery";
// import Pagination from 'react-bootstrap/Pagination';
// require("bootstrap/less/bootstrap.less");
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Modal,
  Offcanvas,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TapCard, Currencies, Direction, Edges, Locale, Theme, Scope, ColorStyle, tokenize } from '@tap-payments/card-web'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FullScreenLoader from "../loader/FullScreenLoader";
import { UserContext } from "../../utils/userContext";

// require("bootstrap/less/bootstrap.less");


const google = window.google;

const MyTrips = ({ enableFeatures }) => {
  const navigate = useNavigate();

  const [position, setPosition] = useState({
    lat: 29.3776824,
    lng: 47.9755194,
    text: "",
  });
  const [zoom, setZoom] = useState(8);
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [showPastRides, setShowPastRides] = useState(true);
  const [searchPrams, setSearchParms] = useSearchParams();

  const [userDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });
  const [userProfileData, setUserProfilesData] = useState();
  const [isVipUser, setIsVipUser] = useState(0);
  const [pastRideData, setPastRideData] = useState();
  const [upcomingData, setUpcomingData] = useState();
  const [pastRideResponse, setPastRideResponse] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingUpdateBtn, setIsLoadingUpdateBtn] = useState(false);
  // const { isLoadingUpdateBtn, setIsLoadingUpdateBtn } = useContext(UserContext)
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [statusDue,setStatusDue] = useState("");
  const [filterText, setFilterText] = useState("All Trips");
  const [filterStatus, setFilterStatus] = useState(0);
  const [filterPaidStatus, setFilterPaidStatus] = useState(0);
  const [receiptNavigate, setReceiptNavigate] = useState(false);
  // const [filterPaidStatus, setFilterPaidStatus] = useState(0)
  const [noDataAvailable, setNoDataAvailable] = useState(false); // Flag for no data
  const [totalPage, setTotalPage] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pickuplater, setPickupLater] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(0);
  const [selectPayMode, setSelectPayMode] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [payInAdvanceModal, setPayInAdvanceModal] = useState(false);
  const [fareBreakDownModal, setFareBreakDownModal] = useState(false);
  const [requestID, setRequestID] = useState(null);
  const [currantPosition, setCurrantPosition] = useState();
  const [nearSearchLocation, setNearSearchLocation] = useState();
  const [getDriverDetail, setGetDriverDetail] = useState();
  const [pickupLaterUpdatedData, setPickupLaterUpdatedData] = useState();
  const [longRideData, setLongRideData] = useState();
  const [priceCalculation, setPriceCalculation] = useState();
  const [estimatedprice, setEstimatedprice] = useState("Loading...");
  const [enableFeaturesList, setEnableFeaturesList] = useState();
  const [path, setPath] = useState();
  const [requestRide, setRequestRide] = useState(false);
  const [noOfCars, setNoOfCars] = useState(false);
  const [startDate, setStartDate] = useState();
  const [paymentDueModal, setPaymentDueModal] = useState(false);
  const [reqIdForPayment, setReqIdForPayment] = useState('');
  const [carFareBreakdown, setCarFareBreakdown] = useState();
  const [longRentalCarData, setLongRentalCarData] = useState("");
  const [longRentalData, setLongRentalData] = useState("");
  const [longRentalCarCapacity, setLongRentalCarCapacity] = useState("");
  const [longRentalCarName, setLongRentalCarName] = useState("");
  const [longRentalDataImage, setLongRentalImage] = useState("");
  const [carSelectionId, setCarSelectionId] = useState("0");
  const [promoCode, setPromoCode] = useState("");
  const [carType, setCarType] = useState("");
  const [carTypes, setCarTypes] = useState("");
  const [pickupLaterCount, setPickupLaterCount] = useState("");
  const [selectedHourid, setSelectedHourId] = useState("");
  const [selectedAdvanceHourPrice, setSelectedAdvanceHourPrice] = useState("");
  const [rentalCarBrandType, setRentalCarBrandType] = useState("");
  const [selectedHourPrice, setSelectedHourPrice] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [nearLocationValue, setNearLocationValue] = useState("");
  const [cardDetail, setCardDetail] = useState("");
  const [payByCardModal, setPayByCardModal] = useState(false);
  const [walletDiff, setWalletDiff] = useState(0);
  const [advanceFare, SetAdvanceFare] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [longRideCarImage, setLongRideCarImage] = useState(null);
  const handleForCreditCardClose = () => setForCreditCardShow(false);
  const [forCreditCardShow, setForCreditCardShow] = useState(false);
  const [createEnabled, setCreateEnabled] = useState(false);
  const [customerObj, setCustomerObj] = useState({});
  const [userWalletAmount, setUserwalletAmount] = useState("");
  const [minimumBalanceModal, setMinimumBalanceModal] = useState(false);



  var minDate = new Date();
  // var maxDate = new Date();
  // maxDate.setDate(maxDate.getDate() + 6);

  // const [noOfCarForRide, setNoOfCarForRide] = useState({
  //   1: false,
  //   2: false,
  //   3: false,fg
  //   4: false,
  // });
  // const [noOfCarForRide, setNoOfCarForRide] = useState('')
  const [noOfCarForRide, setNoOfCarForRide] = useState("1");

  const autoCompleteRef = useRef();
  const inputRef2 = useRef();
  const autoCompleteRef2 = useRef();
  const ctx = useContext(UserContext);

  useEffect(() => {
    ctx.setLoader(false);
  }, [])

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  const tap_public =
    process.env.REACT_APP_TAP_PAYMENT_LIVE == "0"
      ? process.env.REACT_APP_TAP_PAYMENT_PUBLIC_TEST
      : process.env.REACT_APP_TAP_PAYMENT_PUBLIC;
  // var user_profile_data = props.userProfile;

  // useEffect(() => {
  //   const user = localStorage.getItem("user_data");
  //   if (user) {
  //     setUserDetail(JSON.parse(user));
  //   }
  // }, [user_profile_data]);

  // useEffect(() => {
  //   const user = localStorage.getItem("user_data");
  //   if (user) {
  //     setUserDetail(JSON.parse(user));
  //     // setUserProfilesData(props.userProfile)
  //   }
  // }, []);
  const [isCustomerFetched, setIsCustomerFetched] = useState(false);

  const maxDate = useMemo(() => {
    const date = new Date();
    // console.log(enableFeaturesList)
    if (enableFeaturesList) {
      date.setDate(
        date.getDate() + parseInt(enableFeaturesList.maximumDays_For_FutureRides)
      );
    } else {
      date.setDate(date.getDate() + 6);
    }
    return date;
  }, [enableFeaturesList]);

  useEffect(() => {
    if (enableFeaturesList) {
      const updatedDate = new Date();
      var diff = enableFeaturesList.pickup_later_time_min;
      if (isRentalCar == 1) { //0 = pickup later 1 = long ride
        diff = enableFeaturesList.rental_car_time_min
      }
      updatedDate.setTime(
        updatedDate.getTime() + parseInt(diff) * 60 * 1000
      );
      setStartDate(updatedDate);
    }
  }, [enableFeaturesList]);


  useEffect(() => {
    if (userDetail) {
      // Ensure `enableFeaturesList` is updated
      if (enableFeatures) {
        setEnableFeaturesList(enableFeatures);
        // findBlockUserMessage(enableFeatures);
      } else {
        if (ctx.enableFeaturesList) {
          setEnableFeaturesList(ctx.enableFeaturesList);
          // findBlockUserMessage(ctx.enableFeaturesList);
        } else {
          get_enable_features_list();
        }
      }



      // Fetch profile data if not available
      if (!userProfileData) {
        get_profile_data().then(() => {
          console.log("Profile data fetched:", userProfileData);
        });
      }
      if (userProfileData && Object.keys(customerObj).length == 0 && !isCustomerFetched) {
        console.log("Fetching customer data...");
        // fetchCustomer()
        //   .then(() => {
        //     setIsCustomerFetched(true); // Mark as fetched
        //   })
        //   .catch((error) => {
        //     console.error("Error fetching customer data:", error);
        //   });
      }
    }
  }, [userDetail, enableFeatures, userProfileData]); // Dependencies ensure re-runs when these change

  // Separate useEffect for profile-dependent actions
  const handleShowModal = (action) => {

    setConfirmAction(() => action);
    if (isRentalCar !== "1") {
      setShowModal(true);
    }

  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseMinimumBalanceModal = () => {
    setMinimumBalanceModal(false);
  };

  const handleConfirmAction = () => {
    if (confirmAction) confirmAction();
    handleCloseModal();
  };
  const fetchCustomer = async (data) => {
    if (data || (userProfileData && userProfileData.user_profile.tap_customer_id != '')) {

      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append("customer_id", data ? data.user_profile.tap_customer_id : userProfileData.user_profile.tap_customer_id);

      var res = await retrieveTapCustomerApi(form);
      var data = res.data;
      // console.log(data)  
      setCustomerObj(data);
    }
  }

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {

        setUserProfilesData(data);
        setUserwalletAmount(parseFloat(data.user_profile.wallet))
        setIsVipUser(data.is_vip_user);
        if (Object.keys(customerObj).length == 0) {
          fetchCustomer(data);
        }
        console.log(data)
        // SetCardDetail(data.user_card_info)
        var wallet = data.user_profile.wallet;
        var card_detail = data.user_card_info;
        if (card_detail) {
          var exp = new Date(card_detail.expire_date.slice(0, 7) + "-01");
          var M = exp.getMonth() + 1;
          var Y = exp.getFullYear();
          card_detail.expire_date = (M < 10 ? "0" : "") + M + "/" + Y;
        }

        setCardDetail(card_detail);
        return data;
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(userProfileData)

  // const get_user_wallet_details = async() => {
  //   try{
  //     var form = new FormData();  
  //     form.append('user_id' , userDetail.user_profile_id);
  //     form.append('user_type', userDetail.user_type);

  //     var res = await getUserWalletDetails (form);
  //     var data = res.data;
  //     if(data.status == "1"){
  //       setUserWalletDetails(data)
  //     }
  //   }catch(error){
  //     console.log(error)
  //   }
  // }

  // console.log(userProfileData)

  const selectedCardTopup = async (reqID, data) => {
    setIsLoading(true); // Start loader
    console.log(reqID, data);
    try {
      if (!userProfileData || !userProfileData.user_profile) {
        throw new Error("User profile data is missing.");
      }

      var form = new FormData();
      form.append("user_id", userDetail.user_profile_id);
      form.append("user_type", userDetail.user_type);
      form.append(
        "tap_customer_id", data ? data.user_profile.tap_customer_id :
        userProfileData.user_profile.tap_customer_id
      );
      form.append("tap_card_id", data ? data.user_card_info.tap_card_id : userProfileData.user_card_info.tap_card_id);
      form.append("amount", walletDiff);

      var expired_date = data ? data.user_card_info.expire_date : userProfileData.user_card_info.expire_date


      if (expired_date == "NaN/NaN") {
        toast.error("Unfortunately, the transcation has been declined by your bank.Please contact yoour bank or update the card details", {
          position: "top-center",
          autoClose: 2000,
        })

      }
      // setIsLoadingBtn(true); 
      var res = await addCardWalletAPI(form);
      var data = res.data;
      // setIsLoadingBtn(false); 


      if (data.status == "1") {
        await get_profile_data();
        await long_ride_update(reqID);
        // await handleLongRideUpdate(reqID);
        toast.success(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("add wallet api issue", error);
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  // const paybycard_ride_api = async () => {
  //   try {

  //     var form = new FormData();
  //     form.append("user_type", userDetail.user_type);
  //     form.append("user_id", userDetail.user_profile_id);
  //     form.append("request_id", paymentDueData.unpaid_request_id[0].ride_request_id);

  //     var res = await paybyCardRideApi(form);
  //     var data = res.data;

  //     await get_profile_data();
  //     return data;

  //   } catch (error) {
  //     console.error("paybycard_ride_api", error);
  //   }
  // }




  const get_enable_features_list = async (e) => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      if (!enableFeatures && !ctx.enableFeaturesList) {

        var res = await enableFeaturesApi(form);
        var data = res.data;
        if (data.status == "1") {
          setEnableFeaturesList(data.enable_features);
          // findBlockUserMessage(data.enable_features);
        } else {
          console.log("Error : get_enable_features_list", data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  setTimeout(function () {
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options1
    );
    /*autoCompleteRef.current.setComponentRestrictions({
      country: ["us", "pr", "vi", "gu", "mp"],
    });*/

    autoCompleteRef2.current = new google.maps.places.Autocomplete(
      inputRef2.current,
      options1
    );

    autoCompleteRef.current.addListener("place_changed", async function (e) {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();
      var add;
      var add = place.name + ",";
      place.address_components.forEach((element) => {
        add = add + element.long_name + ", ";
      });
      near_search_location(lat, lng).then((data) => {
        var userLocation = data.user_location_data;
        // inputRef.current.value = userLocation.title_address;
        var pp = {
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
          // text: near_search_condition(data),
          add: add,
        };
        setPickupPosition(pp);
        setPosition({
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
        });
        // setZoom(13);
        // if (destinationPosition)   {
        //   aftersetDestinationPosition(pp, destinationPosition);
        // }
      });
    });
    // autoCompleteRef.current.addListener("place_changed", async function (e) {
    //   var place = this.getPlace();
    //   var geometry = place.geometry;
    //   var lat = geometry.location.lat();
    //   var lng = geometry.location.lng();
    //   console.log("pickuplat", lat)
    //   console.log("pickuplan",lng)
    //   var add;
    //   // var add = place.name + ","
    //   // place.address_components.forEach(element => {
    //   //   add = add + element.long_name + ", "
    //   // });
    //   near_search_location(lat, lng).then((data) => {
    //     console.log( data.user_location_data)

    //     var userLocation = data.user_location_data;
    //     // var userLocation = pickupLocation ;
    //     inputRef.current.value =  userLocation?.title_address;
    //     var pp = {
    //       lat: parseFloat(userLocation.lat),
    //       lng: parseFloat(userLocation.lng),
    //       // text: near_search_condition(data)
    //       add:userLocation.title_address,
    //       // add:  userLocation.add ,
    //     };
    //     // var pp = {
    //     //   lat: parseFloat(userLocation.latitude),
    //     //   lng: parseFloat(userLocation.longitude),
    //     //   // text: "KD " + esti_pr,
    //     //   add:userLocation.title_address,
    //     // };
    //     setPickupPosition(pp);

    //     // setZoom(13);
    //     // if (destinationPosition) {
    //     //   aftersetDestinationPosition(pp, destinationPosition);
    //     // }
    //   });

    //   // if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
    //   //   add = await getAddressFromNextBillion(
    //   //     enableFeaturesList.nextbillion_api_key,
    //   //     lat,
    //   //     lng
    //   //   );
    //   //   add = add.places[0].address;
    //   // } else {
    //   //   add = await getAddressFromLat(lat, lng);
    //   // }
    //   // var latlng = { lat: lat, lng: lng, text: place.name, add: add };
    //   // console.log(latlng)
    //   // setPickupPosition(latlng);
    //   // setPosition({ ...position, lat, lng, text: place.name, add: add });
    // });

    autoCompleteRef2.current.addListener("place_changed", async function () {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();
      // console.log("enableFeaturesList", enableFeaturesList);
      // console.log(lat)
      // console.log(lng)

      $("#updateRideBtn").attr("disabled", "disabled");
      var add;
      var p_r = await price_calculation(
        pickupPosition.lat,
        pickupPosition.lng,
        lat,
        lng
      );
      var esti_pr =
        parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
      esti_pr = esti_pr.toFixed(3);
      near_search_location(lat, lng).then((data) => {
        var userLocation = data.user_location_data;
        // inputRef.current.value = userLocation.title_address;
        var dp = {
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
          text: "KD " + esti_pr,
          add: userLocation.title_address,
        };
        // console.log(pickupPosition)
        // setDestinationPosition(dp);
        setDestinationPosition(dp);
        $("#updateRideBtn").removeAttr("disabled");
        // setZoom(13);
        // aftersetDestinationPosition(pickupPosition, dp);
      });
    });
  }, 100);

  /*  useEffect(() => {
     const rebookData = localStorage.getItem("rebookdata");
     if (rebookData) {
       var rebook_data = JSON.parse(rebookData);
       var pickup_data = rebook_data.pickup;
       var destination_data = rebook_data.destination;
       var pickup_lat = pickup_data.split(",")[0];
       var pickup_long = pickup_data.split(",")[1];
       var destination_lat = destination_data.split(",")[0];
       var destination_long = destination_data.split(",")[1];
       setPosition({
         lat: parseFloat(pickup_lat),
         lng: parseFloat(pickup_long),
       });
       setDestinationPosition({
         lat: parseFloat(destination_lat),
         lng: parseFloat(destination_long),
       });
       setPickupPosition({
         lat: parseFloat(pickup_lat),
         lng: parseFloat(pickup_long),
       });
       near_search_location(destination_lat, destination_long).then((data) => {
         var userLocation = data?.user_location_data;
         inputRef2.current.value = userLocation?.title_address;
       });
       near_search_location(pickup_lat, pickup_long).then((data) => {
         var userLocation = data?.user_location_data;
 
         inputRef.current.value = userLocation?.title_address;
         var p = {
           lat: parseFloat(userLocation.latitude),
           lng: parseFloat(userLocation.longitude),
           text: near_search_condition(data),
         };
         setPickupPosition(p);
         get_driver(pickup_lat, pickup_long);
         var dest_position = {
           lat: parseFloat(destination_lat),
           lng: parseFloat(destination_long),
         };
         if (Object.keys(dest_position).length && p) {
           price_calculation(
             pickup_lat,
             pickup_long,
             destination_lat,
             destination_long
           ).then((p_r) => {
             var esti_pr =
               parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
             esti_pr = esti_pr.toFixed(3);
             setDestinationPosition({
               ...dest_position,
               text: "
                " + esti_pr,
             });
           });
           // aftersetDestinationPosition(p, dest_position);
         }
       });
       localStorage.removeItem("rebookdata");
     } else {
       fetchLocation();
     }
   }, []); */

  const get_fare_break_down = async () => {
    try {
      var data1 = {
        no_of_car: noOfCarForRide,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: pickupPosition.lat,
        is_airport_ride: isAirportRide ? "1" : "0",
        pickup_longitude: pickupPosition.lng,
        car_selection_id: carSelectionId,
        destination_latitude: destinationPosition ? destinationPosition.lat : 0,
        destination_longitude: destinationPosition
          ? destinationPosition.lng
          : 0,
        promo_code: promoCode ? promoCode : "",
        car_type: carType ? carType : 0,
        pickup_later: pickupLaterCount ? pickupLaterCount : 1,
        surcharges_value: 0,
        is_rental_car: isRentalCar ? isRentalCar : 1,
        ride_fare: "",
        rental_hours_id: selectedHourid ? selectedHourid : 4,
      };

      let form = new FormData();
      form.append("no_of_car", data1.no_of_car);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("is_airport_ride", data1.is_airport_ride);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("pickup_later", data1.pickup_later);
      form.append("surchages_value", data1.surcharges_value);
      form.append("is_rental_car", data1.is_rental_car);
      form.append("ride_fare", data1.ride_fare);
      form.append("rental_hours_id", data1.rental_hours_id);

      var res = await fareBreakDownApi(form);
      var data = res.data;
      // console.log(data)
      if (data.status == "1") {
        setCarFareBreakdown(data.car_fare_breakdown);
      } else {
        console.log("get_fare_break_down", data);
      }
    } catch (error) {
      console.log("get_fare_break_down" + error);
    }
  };

  const price_calculation = async (p_lat, p_lng, d_lat, d_lng, c_p) => {
    try {
      var data1 = {
        is_new_app: 1,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: p_lat ?? pickupPosition.lat,
        pickup_longitude: p_lng ?? pickupPosition.lng,
        car_selection_id: 0,
        is_get_eta: 0,
        waiting_charge: 0,
        destination_latitude: d_lat ?? destinationPosition.lat,
        destination_longitude: d_lng ?? destinationPosition.lng,
        promo_code: c_p ?? "",
        car_type: 17,
        offer_price: 0,
        pickup_later: 0,
      };

      let form = new FormData();
      form.append("is_new_app", data1.is_new_app);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("is_get_eta", data1.is_get_eta);
      form.append("waiting_charge", data1.waiting_charge);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("offer_price", data1.offer_price);
      form.append("pickup_later", data1.pickup_later);

      var res = await priceCalculateApi(form);
      var data = res.data;
      if (data.status == "1") {
        setPriceCalculation(data);
        var esti_pr =
          parseFloat(data.estimatedprice) - parseFloat(data.discount_price);
        esti_pr = esti_pr.toFixed(3);
        setEstimatedprice(esti_pr);
        return data;
      } else {
        console.log("price_calculation", data);
        setPriceCalculation();
        setEstimatedprice("Loading...");
      }
    } catch (error) {
      console.log("price_calculation", error);
    }
  };

  const aftersetDestinationPosition = async (pickup, destination) => {
    if (pickup && destination) {
      if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
        var data = await getDirectionFromNextBillion(
          enableFeaturesList.nextbillion_api_key,
          pickup,
          destination
        );
        const route = data.routes[0].legs[0];
        const path = route.steps.map((step) => ({
          lat: step.start_location.latitude,
          lng: step.start_location.longitude,
        }));
        setPath(path);
      } else {
        console.log("Google directions");
        DirectionsService.route(
          {
            origin: new google.maps.LatLng(pickup),
            destination: new google.maps.LatLng(destination),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              setRouteDirections(result);
              // setRequestRide(true);
            } else {
              console.log("err", result);
            }
            // console.log(routeDirections);
          }
        );
      }
    }
  };

  const get_driver = async (lat, lng) => {
    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: lat ? lat : pickupPosition.lat,
        pickup_longitude: lng ? lng : pickupPosition.lng,
      };
      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);

      var res = await getDriverApi(form);
      var data = res.data;
      if (data.status == "1") {
        setGetDriverDetail(data);
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear();
          navigate("/login");
        }
        setGetDriverDetail({ ...data, isDriverAvailable: 0 });
      }
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  const fetchCarTypes = async () => {
    try {
      // Assuming you need user type and user ID from state or localStorage
      const userDetail = JSON.parse(localStorage.getItem("user_data"));

      const data1 = {
        user_type: userDetail?.user_type || "0",
        user_id: userDetail?.user_profile_id || "",
      };

      const form = new FormData();
      Object.keys(data1).forEach(key => form.append(key, data1[key]));

      const res = await airportCarSelectionApi(form);
      const data = res.data;
      // console.log(res)

      if (data.status == "1") {
        return data.airport_car_selection;
      } else {
        console.log("Error fetching car types:", data.message);
        return [];
      }
    } catch (error) {
      console.log("Error fetching car types:", error);
      return [];
    }
  };

  const getDirectionFromNextBillion = async (key, pickup, destination) => {
    try {
      var origin = pickup.lat + "," + pickup.lng;
      var des = destination.lat + "," + destination.lng;
      var api =
        "https://api.nextbillion.io/directions/json?key=" +
        key +
        "&origin=" +
        origin +
        "&destination=" +
        des +
        "&mode=car&steps=true&overview=simplified";

      var res = await fetch(api, {
        method: "GET",
      });
      var data = await res.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch past places based on filters and update loading/no data states
  // useEffect(() => {
  //   const fetchPastPlaces = async (filter = 0, paidStatus = 0) => {
  //     setIsLoading(false);
  //     setNoDataAvailable(false); // Reset no data flag

  //     try {
  //       conzst response = await get_past_places(filter, paidStatus = 0); // Replace with your API call
  //       setPastPlaces(response.data || []); // Handle potential response data structure
  //     } catch (error) {
  //       console.error('Error fetching past places:', error);
  //     } finally {
  //       setIsLoading(false); // Clear loading state
  //       if (pastPlaces.length === 0) {
  //         setNoDataAvailable(true); // Set no data flag if no results
  //       }
  //     }
  //   };

  //   if (userDetail) { // Ensure user data is fetched before fetching past places
  //     fetchPastPlaces();
  //   }
  // }, [ userDetail]);

  useEffect(() => {
    let upcoming = searchPrams.get("upcoming");
    setShowPastRides(upcoming ? false : true);
    if (upcoming) {
      get_past_places(0, 0, 1, 1);

    }
  }, []);

  useEffect(() => {
    get_past_places(0, 0, activePage);
    get_past_places();
    get_long_rental_car_data();
  }, [userDetail, activePage]);

  // console.log(date)

  // console.log(destinationPosition)


  const saveCardHandler = () => {
    if (saveCardDetails) {
    } else {
      console.log("Save card option is not selected");
    }
  };

  const [saveCardDetails, setSaveCardDetails] = useState(false);

  const handleSaveCardChange = (data) => {
    setSaveCardDetails(data.saveCardDetails); // Assuming the callback returns an object with saveCard property
  };

  const [pickupLocation, setPickupLocation] = useState("");
  const [destinationLocation, setdestinationLocation] = useState("");
  const [pickupUpdateTime, setPickupUpdateTime] = useState("");
  const [isRentalCar, setIsRentalCar] = useState("");
  const [fare, setFare] = useState("");
  const [rentalCarHour, setRentalCarHour] = useState("");
  const [updateRequestID, setUpdateRequestID] = useState("");
  const [localTimeZone, setLocalTimeZone] = useState("");
  const [isAirportRide, setIsAirportRide] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const [selectedCarTypeName, setSelectedCarTypeName] = useState("");
  const [SelectedAirportCarName, setSelectedAirportCarName] = useState("");
  const [selectedCarType, setSelectedCarType] = useState("");

  // Handler to update the selected car type
  const handleCarTypeChange = (event) => {
    setSelectedCarType(event.target.value);
  };

  const handleCardToken = async (token) => {
    if (token.status == "ACTIVE") {
      setForCreditCardShow(false);
      setIsLoading(true);
      try {
        var token_name = token.card.name
        var token_name_split = token_name.split(' ')
        var token_first_name = ''
        var token_middle_name = ''
        var token_last_name = ''
        if (token_name_split.length > 2) {
          token_first_name = token_name_split[0]
          token_middle_name = token_name_split[1]
          token_last_name = token_name_split[2]
        }
        else if (token_name_split.length > 1) {
          token_first_name = token_name_split[0]
          token_last_name = token_name_split[1]
        }

        var save_card_form = new FormData();
        // save_card_form.append("tap_secret", tap_secret);
        save_card_form.append("currency", customerObj.currency);
        save_card_form.append("threeDSecure", "1");
        save_card_form.append("save_card", "1");
        save_card_form.append("token", token.id);
        save_card_form.append("customer_id", customerObj.id);
        // save_card_form.append("first_name", customerObj.first_name);
        // save_card_form.append("middle_name", customerObj.middle_name);
        // save_card_form.append("last_name", customerObj.last_name);
        save_card_form.append("first_name", token_first_name);
        save_card_form.append("middle_name", token_middle_name);
        save_card_form.append("last_name", token_last_name);
        save_card_form.append("email", customerObj.email);
        // console.log(customerObj)
        save_card_form.append("country_code", customerObj.phone.country_code.replace('+', ''));
        save_card_form.append("number", customerObj.phone.number);
        save_card_form.append("redirect_url", token.url);

        let formDataObject = {};
        for (let pair of save_card_form.entries()) {
          formDataObject[pair[0]] = pair[1];
        }
        console.log(formDataObject);

        var save_card_res = await saveTapCardApi(save_card_form);

        // var save_card_data = save_card_res.data;

        var form = new FormData();
        // form.append("tap_secret", tap_secret);
        form.append("userId", userDetail.user_profile_id);
        form.append("user_type", userDetail.user_type);
        form.append("tap_card_id", token.card.id);
        form.append("tap_customer_id", userProfileData.user_profile.tap_customer_id);
        form.append("card_number", token.card.last_four);
        form.append("card_name", token.card.name);
        form.append("card_brand", token.card.brand);
        form.append("card_responce", "");

        setIsCustomerFetched(false); // Reset flag to re-fetch

        for (let pair of form.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }
        var res = await addCardApi(form);




        //       await get_profile_data();
        //       setTimeout(() => {
        //         selectedCardTopup()
        // await get_profile_data();
        // selectedCardTopup()


        if (res.data.status == "1") {
          get_profile_data().then(res => {
            // setTimeout(() => {
            if (reqIdForPayment) {
              console.log(userProfileData.user_card_info)
              selectedCardTopup(reqIdForPayment, res);
            }
            // },5000)
          }).catch(e => console.log(e))
        }

        // setTimeout(() => {
        //   if (reqIdForPayment && userProfileData.user_card_info) {
        //     selectedCardTopup(reqIdForPayment)
        //   } else {
        //     // Handle the case where either reqIdForPayment or user_card_info is not available
        //     toast.error("Payment method or card info is not available.", {
        //       position: "top-center",
        //       autoClose: 2000,
        //     })}

        // }, 3000);
        // var data = res.data;

        setIsLoading(false);
        // fetchCustomer()
      } catch (error) {
        console.error("Card token handling failed:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }


  const walletAddTapHandler = async (reqID) => {

    setUpdateModal(false)
    var data1 = {
      user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
      user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
    };

    var form = new FormData();
    form.append("user_type", data1.user_type);
    form.append("user_id", data1.user_id);
    form.append("amount", walletDiff);

    setIsLoading(true)
    var res = await addWalletTapWebAPI(form);
    var data = res.data;
    if (data.status == "1") {
      let wallet_pay_url = data.wallet_pay_url;
      wallet_pay_url = wallet_pay_url.replace(
        "https://rovemain.rovesolution.com",
        "https://www.riderove.com/dev_rove"
      );
      setIsLoading(false)


      // window.open(wallet_pay_url, '_blank');
      // var win = window.open(wallet_pay_url, "popup", "width=600,height=600");

      var win = window.open(wallet_pay_url, "popup", "width=600,height=600");
      if (!win || win.closed || typeof win.closed === "undefined") {
        alert("Pop-up blocked! Please enable pop-ups for this site");
        return;
      }
      // win = window.open(wallet_pay_url,'_blank');


      var timer = setInterval(function () {
        if (win && win.closed) {
          clearInterval(timer);
          get_profile_data();
          long_ride_update(reqID);
          toast.success(data.message, {
            position: "top-center",
            autoClose: 2000,
          });
          setUserwalletAmount(0)


        }
      }, 1000);

    } else {
      console.log("Error : add wallet tap payment", data);
    }
  };


  const paymentHandler = (reqID) => {
    if (selectPayMode == 0) {
      selectedCardTopup(reqID);
      setPaymentDueModal(false);
      setUpdateModal(false)
    }
    if (selectPayMode == 1) {
      setForCreditCardShow(true);
      setPaymentDueModal(false);
      // var expired_date = userProfileData.user_card_info.expire_date
      setReqIdForPayment(reqID)
    }
    if (selectPayMode == 2) {
      setPaymentDueModal(false);
      walletAddTapHandler(reqID)
    }

  }

  const handleUpdateModal = (data) => {
    let pickup_date_time = moment
      .utc(data.pickup_date_time)
      .tz("Asia/Kuwait")
      .format("YYYY-MM-DD HH:mm:ss");
    let pickup_date_split = pickup_date_time.split(" ")[0];
    let pickup_time = pickup_date_time.split(" ")[1];

    let pickup_date_year = pickup_date_split.split("-")[0];
    let pickup_date_month = pickup_date_split.split("-")[1];
    let pickup_date_day = pickup_date_split.split("-")[2];

    let pickup_hour = pickup_time.split(":")[0];
    let pickup_minute = pickup_time.split(":")[1];
    let pickup_second = pickup_time.split(":")[2];

    let pickup_date = new Date();
    pickup_date.setDate(pickup_date_day);
    pickup_date.setMonth(pickup_date_month - 1);
    pickup_date.setYear(pickup_date_year);
    pickup_date.setHours(pickup_hour);
    pickup_date.setMinutes(pickup_minute);
    pickup_date.setSeconds(pickup_second);

    setStartDate(pickup_date);
    setUpdateModal(true);
    setPickupPosition({
      lat: data.pickup_latitude,
      lng: data.pickup_longitude,
      add: data.pickup_address,
    });

    setUpdateData(data);
    setDestinationPosition({
      lat: data.destination_latitude,
      lng: data.destination_longitude,
      add: data.destination_address,
    });

    setTimeout(() => {
      if (data.pickup_address) {
        inputRef.current.value = data.pickup_address;
      }
      if (data.destination_address) {
        inputRef2.current.value = data.destination_address;
      }
    }, 300);

    fetchAndSetCarTypes()

    // console.log()

    // fetchCarTypes();
    // console.log()

    setNoOfCarForRide(data.multi_pickup_later_car_count);
    setNumber(data.multi_pickup_later_car_count);
    setIsRentalCar(data.is_rental_car);
    // console.log(data)
    setSelectedCarTypeName(data.selected_carType_name)
    setSelectedAirportCarName(data.selected_airport_car_name)
    setRequestID(data.request_id)

    if (data.is_rental_car == "1") {
      longRentalData.forEach((data) => {
        var longRentalCarImage = data.multi_car_image

        // console.log(longRentalCarImage)
        setLongRentalImage(longRentalCarImage)
        setLongRentalCarCapacity(data.seating_capacity)
        setLongRentalCarName(data.display_name)
      })
    }



    if (data.is_rental_car == "1") {
      var long_ride_fare = data.fare * data.multi_pickup_later_car_count;
      setFare(long_ride_fare);


      // data.fare * data.multi_pickup_later_car_count).toFixed(3)

      longRentalCarData.forEach((rental_hour) => {
        if (rental_hour.id == data.rental_car_hour) {
          let multiple_car_fare = 0;
          let advance_car_fare = 0;

          if (data.multi_pickup_later_car_count > 1) {
            multiple_car_fare =
              rental_hour.advance_payment_amount * data.multi_pickup_later_car_count;
            rental_hour.multi_car_data.forEach((multi_car_data) => {
              if (multi_car_data.multi_car_type_id == data.car_selection_id) {
                advance_car_fare =
                  multi_car_data.advance_payment_amount *
                  data.multi_pickup_later_car_count;
                // SetAdvanceFare(advance_car_fare);
              }
              SetAdvanceFare(multiple_car_fare);
            });

            setSelectedHourPrice(multiple_car_fare);
          } else {
            // SetAdvanceFare(data.car_rental_advance_payment);
            // SetAdvanceFare(data.fare);
            // console.log('1', advance_car_fare, multiple_car_fare, data.car_rental_advance_payment, rental_hour.hours_price)
            // setSelectedHourPrice(rental_hour.hours_price);
            setSelectedHourPrice(rental_hour.hours_price);
          }
        }
      });
    } else {
      setFare(data.fare);
    }

    setCarType(data.car_type);
    setRentalCarHour(data.rental_car_hour);
    setPromoCode(data.promo_code);
    setPickupLaterCount(data.pickup_later);
    setRentalCarBrandType(data.rental_car_brand_type);
    setNearLocationValue(data.near_location);
    setIsAirportRide(data.is_airport_ride == "1");
    setCarSelectionId(data.car_selection_id);
    setUpdateRequestID(data.request_id);

    let time_zone = moment.tz.guess();
    setLocalTimeZone(time_zone);
  };


  useEffect(() => {
    const initialFormattedDate = moment(startDate)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    setPickupUpdateTime(initialFormattedDate);
  }, [startDate]);

  const calculateFinalFare = (data) => {


    // Base fare price calculation
    let baseFarePrice = 0;

    // Base fare price calculation
    if (data.multi_pickup_later_car_count > 1) {
      baseFarePrice =
        data.pickup_later_base_fare *
        (data.multi_pickup_later_car_count - 1); // Subtract 1 from car count
    }

    // Total price calculation
    const totalPrice = data.estimated_price * data.multi_pickup_later_car_count;

    // Final price calculation
    const finalPrice = isVipUser == 1 ? totalPrice : totalPrice - baseFarePrice;

    // VIP user condition
    // const finalPrice = is_vip_user ? totalPrice : totalPrice - baseFarePrice;

    // Return the final fare formatted with 'KD'
    return `${finalPrice.toFixed(3)} KD`;
  };

  const handleDateChange = (date) => {
    if (enableFeaturesList) {
      const updatedDate = new Date();
      var diff = enableFeaturesList.pickup_later_time_min;
      if (isRentalCar == 0) {
        diff = enableFeaturesList.rental_car_time_min
      }
      updatedDate.setTime(
        updatedDate.getTime() + parseInt(diff) * 60 * 1000
      );
      if (date < updatedDate) {
        var message = "Please, select correct date."
        if (isRentalCar == "0") {
          message = enableFeaturesList.pickupLatterMessage;
        }
        if (isRentalCar == "1") {
          message = enableFeaturesList.LongRideMessage;
        }
        toast.error(message, {
          position: "top-center",
          autoClose: 1500,
        })
      } else {
        setStartDate(date);
      }
    }
  };

  // const payInAdvanceHandler = () => {
  //   var userWallet = userProfileData?.user_profile.wallet;
  //   var lastRentalAmount = fare;
  //   var numberOfcars = noOfCarForRide;

  //   console.log(userWallet, lastRentalAmount, numberOfcars);
  //   var walletAmount = parseFloat(userWallet) + (parseFloat(lastRentalAmount) * numberOfcars);
  //   var finalPayment = parseFloat(selectedHourPrice) * numberOfcars;
  //   const retval = walletAmount === finalPayment ? 0 : (walletAmount < finalPayment ? -1 : 1);

  //   console.log(walletAmount, finalPayment, retval);
  //   const size = finalPayment - walletAmount;

  //   console.log(`Difference: ${size.toFixed(3)}`);
  //   console.log(size)

  //   if (retval < 0) {
  //     // showDialogForPaySpecialRate(size.toFixed(3));
  //     console.log('no money in wallet')
  //   } else {
  //     // toast.success("10.00 Kd is debited", {
  //     //   position: "top-center",
  //     //   autoClose: 2000,
  //     // });
  //     // console.log('ride_updated')
  //     // return false
  //     long_ride_update();
  //   }
  //   // setIsSpecialPriceDialogShowed(true);
  // };

  const filterSelectHandler = (filter, paid_key, filter_text) => {
    // console.log(filter,paid_key,filter_text)
    // return false
    // setFilterStatus(filter)
    // setFilterPaidStatus(paid_key)
    setFilterText(filter_text);
    setActivePage(1);
    // if(filter == "2")
    // useEffect(() =>{
    // },[])
    setTimeout(async () => {
      setIsLoading(true);
      setIsLoadingPagination(true);
      // $('#pagination').addClass('d-none')
      await get_past_places(filter, paid_key);
      setIsLoadingPagination(false);
      setIsLoading(false);
    }, 300);
  };

  // console.log(selectedCarType)

  const pickup_later_update = async () => {
    setIsLoadingUpdateBtn(true)
    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat,
        destination_longitude: destinationPosition.lng,
        pickup_address: pickupPosition.add,
        destination_addrss: destinationPosition.add,
        isAirportRide: isAirportRide ? "1" : "0",
        updateRequestID: updateRequestID,
        // pickup_latitude: lat ? lat : pickupPosition.lat,
        // pickup_longitude: lng ? lng : pickupPosition.lng,
      };

      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("request_id", data1.updateRequestID);
      form.append("timezone", localTimeZone);
      form.append("pickup_date_time", pickupUpdateTime);
      // form.append("pickup_date_time", pickup);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("pickup_address", data1.pickup_address);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("destination_address", data1.destination_addrss);

      form.append("airport_car_selection_id", selectedCarType);
      // form.append("selected_airport_car_name", selectedCarType );

      console.log(selectedCarType)


      if (data1.isAirportRide == "1") {
        form.append("is_airport_ride", data1.isAirportRide);
        form.append("no_person", "0-1");
        form.append("no_luggage_bags", "1");
        form.append("pickup_later_car_count", number);
      } else {
        form.append("is_airport_ride", data1.isAirportRide);
        form.append("no_person", "0-1");
        form.append("no_luggage_bags", "1");
        form.append("pickup_later_car_count", number);
      }


      //   if (data1. i == "1") {
      //     map.put("is_airport_ride", "1");
      //     map.put("no_person", "0-1");
      //     map.put("no_luggage_bags", "1");
      //     map.put("pickup_later_car_count", tripHistory.getMultiPickupLaterCarCount());
      //     if (CONSTANT.IS_CAR_BRAND_SELECTION_AIRPORT_RIDE) {
      //         map.put("airport_car_selection_id", tripHistory.getSelectedAirportCarId());
      //     }
      // } else {
      //     map.put("is_airport_ride", "0");
      //     map.put("no_person", "0");
      //     map.put("no_luggage_bags", "0");
      //     if (isPickupLaterRideCount()) {
      //         map.put("pickup_later_car_count", tripHistory.getMultiPickupLaterCarCount());
      //     }

      var res = await pickupLaterUpdateApi(form);
      var data = res.data;
      setIsLoadingUpdateBtn(false)
      if (data.status == "1") {
        get_past_places(0, 0, 1, 1);

        setPickupLaterUpdatedData(data);
        toast.success("Updated Ride details successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        setUpdateModal(false);
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const long_ride_update = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat,
        destination_longitude: destinationPosition.lng,
        pickup_address: pickupPosition.add,
        destination_addrss: destinationPosition.add,
        isAirportRide: isAirportRide ? "1" : "0",
        updateRequestID: updateRequestID,
        is_rental_car: isRentalCar ? isRentalCar : 1,
        rental_hours_id: selectedHourid ? selectedHourid : 4,
        rentalCarBrandType: rentalCarBrandType ? rentalCarBrandType : 0,
        nearLocationValue: nearLocationValue ? nearLocationValue : "",
        // pickup_latitude: lat ? lat : pickupPosition.lat,
        // pickup_longitude: lng ? lng : pickupPosition.lng,
      };

      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("request_id", data1.updateRequestID);
      form.append("timezone", localTimeZone);
      // form.append("pickup_date_time", pickupUpdateTime);
      form.append("pickup_date_time", pickupUpdateTime);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("pickup_address", data1.pickup_address);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("destination_address", data1.destination_addrss);
      form.append("rental_car_brand_type", data1.rentalCarBrandType);
      form.append("rental_car_hour", data1.rental_hours_id);
      form.append("near_location", data1.nearLocationValue);

      if (data1.isAirportRide == "1") {
        form.append("is_airport_ride", data1.isAirportRide);
        form.append("no_person", "0-1");
        form.append("no_luggage_bags", "1");
        form.append("pickup_later_car_count", "1");
      } else {
        form.append("is_airport_ride", data1.isAirportRide);
        form.append("no_person", "0-1");
        form.append("no_luggage_bags", "1");
        form.append("pickup_later_car_count", number);
      }

      setIsLoading(true); // Start loader
      var res = await updateUpcomingRideApi(form);
      var data = res.data;
      setIsLoading(false); // Start loader


      if (data.status == "1") {
        console.log(data)
        get_past_places(0, 0, 1, 1);
        setLongRideData(data);
        // setUserwalletAmount(0); 
        setUserwalletAmount(0)


        toast.success("Updated Ride details successfully!", {
          position: "top-center",
          autoClose: 2000,
        });

        setUpdateModal(false);
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        return;
      }
    } catch (error) {
      console.log("Error calling long_ride_update", error);
    }
  };

  const cardHandler = () => {
    setForCreditCardShow(true);
  }

  const KuwaitTimeZone = (time, format = "Do MMM YYYY, h:mm:ss a ") => {
    return moment.utc(time).tz("Asia/Kuwait").format(format);
  };

  const updateModalHandler = () => {
    setUpdateModal(false);
  };
  const cancelModalHandler = () => {
    setCancelModal(false);
  };
  const paymentModalHandler = () => {
    setPaymentDueModal(false);
  };
  // const showPayInAdvanceModal = () => {
  //   setPayInAdvanceModal(false)
  // }
  const fareModal = () => {
    setFareBreakDownModal(false);
  };

  const handleCardModal = () => {
    setPayByCardModal(false);
  };

  const handleClick = (carNumber) => {
    setNoOfCarForRide((prevNumber) => ({
      1: carNumber === 1,
      2: carNumber === 2,
      3: carNumber === 3,
      4: carNumber === 4,
    }));
  };

  const [number, setNumber] = useState();

  const increase = () => {
    setNumber((prevNumber) =>
      prevNumber < 4 ? parseInt(prevNumber) + 1 : prevNumber
    );
  };

  // if (number == 1) {
  //   // document.getElementsByClassName('fa-minus').addClass('disabled')
  //   $('.fa-minus').attr('disabled')
  // }
  const decrease = () => {
    setNumber((prevNumber) =>
      prevNumber > 1 ? parseInt(prevNumber) - 1 : prevNumber
    );
  };

  const TimeZone = (time, format = 'Do MMM YYYY, h:mm a') => {
    return moment.utc(time).tz("Asia/Kuwait").format(format);
  };


  const ConfirmationModal = ({ show, handleClose, handleConfirm, message }) => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold" style={{ color: "#3d565f" }}>
            Multiple Cars Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-cancel-trip w-45 mx-2" onClick={handleClose}>
            Reject
          </Button>
          <Button className="btn btn-primary w-45 mx-2" onClick={handleConfirm}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const updateUpcomingHandler = async (req_ID) => {
    try {

      if (isRentalCar === "0") {
        setIsLoading(true);
        console.log("Calling pickup_later_update");
        if (userWalletAmount == "0" || userWalletAmount == "0.00" || userWalletAmount == "undefined") {
          setMinimumBalanceModal(true)
        } else {
          await pickup_later_update(req_ID);
        }
        setIsLoading(false);
      } else if (isRentalCar === "1") {
        console.log("Calling handleLongRideUpdate");
        setIsLoading(true)
        await handleLongRideUpdate(req_ID);
        setIsLoading(false)
      } else {
        console.log("Unhandled isRentalCar value:", isRentalCar);
      }
    } catch (error) {
      console.error("Error during update:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleLongRideUpdate = async (req_ID) => {
    console.log('Inside handleLongRideUpdate');

    try {
      if (isRentalCar === "1") {
        console.log(isRentalCar)
        if (selectedValue != rentalCarHour) {
          console.log(selectedValue, rentalCarHour)
          if (userProfileData && userProfileData.user_profile && userProfileData.user_profile.wallet != undefined) {
            console.log(userProfileData, userProfileData.user_profile, userProfileData.user_profile.wallet)
            if (longRentalCarData.length) {
              longRentalCarData.forEach(rental_hour => {
                if (rental_hour.id === selectedHourid) {
                  let multiple_car_fare = 0;
                  let advance_car_fare = 0;

                  if (updateData.multi_pickup_later_car_count > 1) {
                    multiple_car_fare = updateData.car_rental_advance_payment * updateData.multi_pickup_later_car_count;

                    rental_hour?.multi_car_data.forEach(multi_car_data => {
                      if (multi_car_data.multi_car_type_id === updateData.car_selection_id) {
                        advance_car_fare = multi_car_data.advance_payment_amount * updateData.multi_pickup_later_car_count;
                        SetAdvanceFare(advance_car_fare);
                      }
                    });
                    console.log(advance_car_fare)

                    setSelectedHourId(rental_hour.id);
                    setSelectedValue(rental_hour.hours_value);
                    setSelectedHourPrice(multiple_car_fare);
                  } else {
                    setSelectedHourId(rental_hour.id);
                    SetAdvanceFare(updateData.car_rental_advance_payment);
                    setSelectedValue(rental_hour.hours_value);
                    setSelectedHourPrice(rental_hour.hours_price);
                    //   const updateUpcomingHandler = (req_ID) => {
                    //     if (isRentalCar == "0") {
                    //       pickup_later_update(req_ID);
                    //     }
                    //     if (isRentalCar == "1") {
                    //       if (selectedValue != rentalCarHour) {
                    //         if (
                    //           userProfileData &&
                    //           userProfileData.user_profile &&
                    //           userProfileData.user_profile.wallet !== undefined
                    //         ) {
                    //           if (longRentalCarData.length) {
                    //             longRentalCarData.forEach((rental_hour) => {
                    //               if (rental_hour.id == selectedHourid) {
                    //                 console.log(
                    //                   "hour, selected hour id:",
                    //                   updateData,
                    //                   selectedHourid
                    //                 );

                    //                 let multiple_car_fare = 0;
                    //                 let wall_car_fare = 0;

                    //                 if (updateData.multi_pickup_later_car_count > 1) {
                    //                   multiple_car_fare =
                    //                     updateData.car_rental_advance_payment *
                    //                     updateData.multi_pickup_later_car_count;
                    //                   // console.log("old advance key", updateData.car_rental_advance_payment);

                    //                   rental_hour?.multi_car_data.forEach((multi_car_data) => {
                    //                     if (
                    //                       multi_car_data.multi_car_type_id ==
                    //                       updateData.car_selection_id
                    //                     ) {
                    //                       // console.log("data:", multi_car_data);
                    //                       advance_car_fare =
                    //                         multi_car_data.advance_payment_amount *
                    //                         updateData.multi_pickup_later_car_count;
                    //                       // console.log('advance car:', advance_car_fare);
                    //                       SetAdvanceFare(advance_car_fare);
                    //                       console.log(advance_car_fare);
                  }
                }
              });
            }



            // const userWallet = parseFloat(userProfileData.user_profile.wallet);

            // const lastRentalAmount   = parseFloat(selectedHourPrice);
            // const  finalPayment = parseFloat(selectedHourPrice);
            const finalPayment = parseFloat(selectedAdvanceHourPrice)

            //latest
            // const lastRentalAmount = noOfCarForRide > 1 ? parseFloat(advanceFare) : parseFloat(fare);
            const lastRentalAmount = updateData.car_rental_advance_payment * noOfCarForRide
            // console.log(lastRentalAmount , updateData.car_rental_advance_payment)

            const walletAmount = parseFloat(userWalletAmount) + parseFloat(lastRentalAmount);
            const size = finalPayment - walletAmount;
            const retval = Math.sign(walletAmount - finalPayment);

            console.log({ userWalletAmount, lastRentalAmount, walletAmount, finalPayment, retval, advanceFare, fare });

            if (retval < 0) {
              setPaymentDueModal(true);
              setWalletDiff(size.toFixed(3));
              console.log("Insufficient balance, size:", size);
            } else if (Math.abs(walletAmount - finalPayment) < 0.001) {
              setUpdateModal(false);
              await long_ride_update(req_ID);
            } else {
              setUpdateModal(false);
              await long_ride_update(req_ID);
            }
          }
        }

        // setPayInAdvanceModal(true);



        //                   console.log(
        //                     rental_hour.id,
        //                     rental_hour.hours_value,
        //                     multiple_car_fare
        //                   );

        //                   setSelectedHourId(rental_hour.id);
        //                   setSelectedValue(rental_hour.hours_value);
        //                   setSelectedHourPrice(multiple_car_fare);
        //                 } else {
        //                   setSelectedHourId(rental_hour.id);
        //                   SetAdvanceFare(updateData.car_rental_advance_payment);
        //                   setSelectedValue(rental_hour.hours_value);
        //                   setSelectedHourPrice(rental_hour.hours_price);
        //                 }
        //               }
        //             });
        //           }

        //           const userWallet = parseFloat(userProfileData.user_profile.wallet);
        //           const lastRentalAmount = parseFloat(selectedHourPrice);
        //           const finalPayment = parseFloat(advanceFare);
        //           console.log(userWallet, lastRentalAmount, finalPayment);

        //           const walletAmount = userWallet + lastRentalAmount;
        //           const retval =
        //             walletAmount === finalPayment
        //               ? 0
        //               : walletAmount < finalPayment
        //               ? -1
        //               : 1;
        //           const size = finalPayment - walletAmount;

        //           console.log(
        //             "wallet , finalamt , retval:",
        //             walletAmount,
        //             finalPayment,
        //             retval
        //           );

        //           if (retval < 0) {
        //             setPaymentDueModal(true);
        //             setWalletDiff(size.toFixed(3));
        //             console.log("no money in wallet");
        //           } else if (retval == " ") {
        //             long_ride_update(req_ID);
        //             console.log(userProfileData);
        //             console.log("...");
        //           }

        //           setPayInAdvanceModal(true);
        //         } else {
        //           console.log("User profile or wallet data is missing");
        //           // console.log(userProfileData.user_profile.wallet)
        //           // console.log(userWallet)
        //           // Uncomment the following line if you want to call long_ride_update when wallet data is missing
        //           // long_ride_update(req_ID);
      }
    } catch (error) {
      console.error("Error in handleLongRideUpdate:", error);
    }
  };



  // dhruv new code..
  // const handleLongRideUpdate = async (req_ID) => {
  //   console.log("Inside handleLongRideUpdate");

  //   try {
  //     // Check if it is a rental car
  //     if (isRentalCar !== "1") return;

  //     console.log("Rental Car Detected:", isRentalCar);

  //     // Validate selectedValue against rentalCarHour
  //     if (selectedValue === rentalCarHour) return;

  //     console.log("Selected Value Mismatch:", selectedValue, rentalCarHour);

  //     // Ensure user profile and wallet exist
  //     const wallet = userProfileData?.user_profile?.wallet;
  //     if (wallet === undefined) {
  //       console.log("User profile or wallet data is missing");
  //       return;
  //     }

  //     console.log("User Wallet Found:", wallet);

  //     // Process long rental car data
  //     if (!longRentalCarData.length) {
  //       console.log("No long rental car data available");
  //       return;
  //     }

  //     longRentalCarData.forEach((rental_hour) => {
  //       if (rental_hour.id !== selectedHourid) return;

  //       let multipleCarFare = 0;
  //       let advanceCarFare = 0;

  //       // Calculate fare for multiple cars
  //       if (updateData.multi_pickup_later_car_count > 1) {
  //         multipleCarFare =
  //           updateData.car_rental_advance_payment * updateData.multi_pickup_later_car_count;

  //         rental_hour?.multi_car_data.forEach((multi_car_data) => {
  //           if (multi_car_data.multi_car_type_id === updateData.car_selection_id) {
  //             advanceCarFare =
  //               multi_car_data.advance_payment_amount * updateData.multi_pickup_later_car_count;
  //             SetAdvanceFare(advanceCarFare);
  //           }
  //         });

  //         setSelectedHourId(rental_hour.id);
  //         setSelectedValue(rental_hour.hours_value);
  //         setSelectedHourPrice(multipleCarFare);
  //       } else {
  //         // Single car fare
  //         setSelectedHourId(rental_hour.id);
  //         SetAdvanceFare(updateData.car_rental_advance_payment);
  //         setSelectedValue(rental_hour.hours_value);
  //         setSelectedHourPrice(rental_hour.hours_price);
  //       }
  //     });

  //     // Calculate wallet and payment differences
  //     const lastRentalAmount =
  //       updateData.multi_pickup_later_car_count > 1
  //         ? parseFloat(advanceFare)
  //         : parseFloat(fare);
  //     const walletAmount = parseFloat(wallet) + lastRentalAmount;
  //     const finalPayment = parseFloat(selectedAdvanceHourPrice);
  //     const paymentDifference = finalPayment - walletAmount;

  //     console.log({
  //       wallet,
  //       lastRentalAmount,
  //       walletAmount,
  //       finalPayment,
  //       paymentDifference,
  //       advanceFare,
  //       fare,
  //     });

  //     // Handle insufficient wallet balance
  //     if (paymentDifference < 0) {
  //       setPaymentDueModal(true);
  //       setWalletDiff(paymentDifference.toFixed(3));
  //       console.log("Insufficient balance, payment difference:", paymentDifference);
  //       return;
  //     }else{
  //       await long_ride_update(req_ID);
  //     }

  //     // Proceed with the long ride update
  //   } catch (error) {
  //     console.error("Error in handleLongRideUpdate:", error.message, error.stack);
  //   }
  // };



  const cancle_riderequest = async () => {
    setCancelModal(false)
    try {
      var form = new FormData();
      //  is_update_app=1
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("cancelride_reason", "0");
      form.append("request_id", requestID);
      form.append("is_update_app", "1");


      setIsLoading(true)
      var res = await cancleRideRequestApi(form);
      var data = res.data;
      setIsLoading(false)

      if (data.status == "1") {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1500,
        });
        setCancelModal(false);
        get_past_places(0, 0, 1, 1);
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1500,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log('isRentalCar value:', isRentalCar);

  // if (isRentalCar === "0") {
  //   handleShowModal(updateUpcomingHandler);
  //   console.log('is rental 0')
  // } else if (isRentalCar === "1") {
  //   console.log('long ride')
  //   handleLongRideUpdate();
  // }



  const getButtonClass = (status, requestId) => {
    switch (status) {
      case '1': // Accepted
      case '3': // Started
        return "status_accept";
      case '2': // Cancelled
        return "btn-danger";
      case '0':
      case '5': // Unattended
        return "btn-warning"; // You can define a custom class for unattended
      default:
        return "btn-success"; // Default
    }
  };

  // console.log(carTypes)
  const get_past_places = async (
    filter_status = 0,
    filter_paid_status = 0,
    page = 1,
    pickup_later = 0
  ) => {
    try {
      const data1 = {
        user_type: userDetail?.user_type || "", // 0 android, 1 ios
        customer_id: userDetail?.user_profile_id || "",
      };

      const form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("customer_id", data1.customer_id);
      form.append("filter", filter_status);
      form.append("pickup_later", pickup_later);
      form.append("paid_key", filter_paid_status);
      form.append("page", page);
      //new line
      form.append("is_new_app", 1);

      setIsLoading(true);
      setIsLoadingPagination(true);
      const res = await getPastRidesApi(form);
      const data = res.data;
      setIsLoadingPagination(false);
      setIsLoading(false);
      if (data.status == "1") {
        // let total_page_count =  data.total_page_count
        const updatedRequests = data.requests.map(async (req) => {
          const imageUrl = req.map_image;
          const driverImage = req.driver_image;
          let updatedReq = { ...req };


          // try {
          //   await axios.get(req?.map_image || req?.driver_image);
          // } catch (error) {
          //   if (error.response && error.response.status === 404) {
          //     let updatedUrl = imageUrl.replace("https://rovemain.rovesolution.com/", "https://www.riderove.com/dev_rove/");
          //     // updatedUrl = updatedUrl.replace("https://roveimage.s3.me-south-1.amazonaws.com/images/","https://www.riderove.com/dev_rove/");
          //     let updatedDriver = driverImage.replace("https://roveimage.s3.me-south-1.amazonaws.com/images/", "https://www.riderove.com/dev_rove/uploads/");
          //     updatedReq.driver_image = updatedDriver
          //     updatedReq.map_image = updatedUrl;
          //   }
          // }
          if (req.status != "1" && req.status != "3" && req.status != "") {
            var url = req?.map_image;
            // const response = await fetch(url, { method: "get" });
            // if (response && response.status === 404) {
            let updatedUrl = imageUrl.replace(
              "https://rovemain.rovesolution.com/",
              "https://www.riderove.com/dev_rove/"
            );
            // updatedUrl = updatedUrl.replace("https://roveimage.s3.me-south-1.amazonaws.com/images/","https://www.riderove.com/dev_rove/");
            /*  let updatedDriver = driverImage.replace(
               "https://roveimage.s3.me-south-1.amazonaws.com/images/",
               "https://www.riderove.com/dev_rove/uploads/"
             ); */
            let updatedDriver = "https://www.riderove.com/dev_rove/uploads/driver/" + driverImage;
            updatedReq.driver_image = updatedDriver;
            updatedReq.map_image = updatedUrl;
            // }
          } else if (req.status == "") {
            var url = req?.map_image;
            let updatedUrl = url.replace(
              "https://rovemain.rovesolution.com/",
              "https://www.riderove.com/dev_rove/"
            );
            updatedReq.map_image = updatedUrl;

            var multi_car_image = req.multi_car_image;
            multi_car_image = multi_car_image.replace(
              "https://rovemain.rovesolution.com/",
              "https://www.riderove.com/dev_rove/"
            );
            updatedReq.multi_car_image = multi_car_image;
            // console.log(updatedReq);
          }

          var originalDate = req.created_at;
          // console.log("originalDate",originalDate)
          originalDate = TimeZone(originalDate);
          // console.log("convert date",originalDate)
          updatedReq.orignalDate = originalDate;

          var upcoming_pickup_time = req.pickup_date_time;
          upcoming_pickup_time = KuwaitTimeZone(upcoming_pickup_time, "YYYY-MM-DD HH:mm:ss");

          const pickupDate = moment(upcoming_pickup_time, "YYYY-MM-DD HH:mm:ss", true);
          // updatedReq.upcoming_pickup_time = upcoming_pickup_time;
          if (!pickupDate.isValid()) {
            updatedReq.upcoming_pickup_time = "Invalid date";
          } else {
            // Define current and tomorrow's date in Kuwait timezone
            const currentDate = moment().startOf('day');
            const tomorrowDate = moment().add(1, 'days').startOf('day');

            if (pickupDate.isSame(currentDate, 'day')) {
              // If the pickup time is today
              updatedReq.upcoming_pickup_time = `Today, ${pickupDate.format("h:mm a")}`;
            } else if (pickupDate.isSame(tomorrowDate, 'day')) {
              // If the pickup time is tomorrow
              updatedReq.upcoming_pickup_time = `Tomorrow, ${pickupDate.format("h:mm a")}`;
            } else {
              // Otherwise, use the full date format
              updatedReq.upcoming_pickup_time = pickupDate.format("Do MMM YYYY, h:mm a");
            }
          }


          // console.log(upcoming_pickup_time)
          // setPickupUpdateTime(upcoming_pickup_time)

          var update_upcoming_time = req.pickup_date_time;
          // update_upcoming_time = KuwaitTimeZone(update_upcoming_time)
          // var startingDate = new Date(update_upcoming_time)
          // console.log(startingDate)
          // setStartDate(startingDate)
          // setSta(update_upcoming_time)

          var status = updatedReq.status;
          if (status == "1" && updatedReq.is_arrived == "1") {
            setStatusText ( "Arrived")
          } else if (status == "1") {
            setStatusText ( "Accepted")
          } else if (status == "3") {
            setStatusText ( "Started")
          } else if (status == "4") {
            setStatusText ( "Finished")
          } else if (status == "2") {
            setStatusText ( "Cancelled")
          } else if (status == "0" || status == "5") {
            setStatusText ( "Unattended")
          }

          updatedReq.buttonClass = getButtonClass(status, updatedReq.request_id);


          if (updatedReq.pickup_later == "0") {
            if (updatedReq.transaction_status != null) {
              if (
                updatedReq.transaction_status == "PENDING" ||
                updatedReq.transaction_status == "0"
              ) {
                if (status == "0" || status == "1" || status == "3") {
                  // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none');
                } else {
                  // console.log(updatedReq.request_id ,"-" , status , '-' , updatedReq.transaction_status)
                  setStatusDue ( "Payment Due")
                  setTimeout(() => {
                    $("#btn-payment-due-" + updatedReq.request_id).removeClass(
                      "d-none"
                    );
                    $("#btn-payment-due-" + updatedReq.request_id).addClass(
                      "btn-danger"
                    );
                    $("#btn-payment-sucess-" + updatedReq.request_id).addClass(
                      "d-none"
                    );
                  }, 300);
                }
              } else {
                if (
                  status == "2" ||
                  status == "0" ||
                  status == "1" ||
                  status == "3"
                ) {
                  // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
                } else {
                  setStatusText ( "Paid")
                }
              }
            } else {
              if (status == "0" || status == "1" || status == "3") {
                // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
              } else {
                setStatusDue ( "Payment Due")
                $("#btn-payment-due-" + updatedReq.request_id).removeClass(
                  "d-none"
                );
                $("#btn-payment-due-" + updatedReq.request_id).addClass(
                  "btn-danger"
                );
              }
            }
          } else {
            if (
              updatedReq.transaction_status == "PENDING" ||
              updatedReq.transaction_status == "0"
            ) {
              if (status == "0" || status == "1" || status == "3") {
                // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
              } else {
                setStatusDue ( "Payment Due")
                $("#btn-payment-due-" + updatedReq.request_id).removeClass(
                  "d-none"
                );
                $("#btn-payment-due-" + updatedReq.request_id).addClass(
                  "btn-danger"
                );
              }
            } else {
              if (
                status == "2" ||
                status == "0" ||
                status == "1" ||
                status == "3"
              ) {
                // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
              } else {
                setStatusText ( "Paid")
              }
            }
          }
          let items = [];
          let activePage = 1;

          // let total_page_count = pastRideResponse?.total_page_count
          // setTotalPage(total_page_count)
          setTotalPage(data.total_page_count);
          setLimitPerPage(data.requests.length);
          // console.log(totalPage)
          return updatedReq;
        });

        // Wait for all promises to complete before updating data
        // let total_page_count =
        // if(pickup_later == 1){
        //   setUpcomingData(data)
        // }
        // console.log(upcomingData)

        // if (pickup_later) {
        //   const pickupRequest = data.requests.map(async (req) => {
        //     let updatedReq = { ...req };

        //     var ride_pickup_time = req.pickup_date_time
        //     ride_pickup_time = KuwaitTimeZone(ride_pickup_time)
        //     // console.log("convert date",originalDate)
        //     updatedReq.ride_pickup_time = ride_pickup_time;
        //     console.log(ride_pickup_time)

        //   })
        //   // var req_pickup = data.requests
        //   setUpcomingData(data.requests);
        // }
        // else{

        // }
        // console.log(updatedRequests)
        Promise.all(updatedRequests)
          .then((updatedData) => {
            data.requests = updatedData;
            if (!pickup_later) {
              setPastRideData(data.requests);
              setPastRideResponse(data);
            } else {
              setUpcomingData(data.requests);
            }
            // setPastRideData(data.requests)
          })
          .catch((error) => {
            console.error("Error updating image paths:", error);
          });
      } else {
        if (data.message == "no data available") {
          setPastRideData([]);
          setUpcomingData([]);
          setTotalPage(0);
        }
        console.log("Error", data);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handlePaginationChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const showPastRideHandler = () => {
    setShowPastRides(true);
    get_past_places(0, 0, 1, 0);
  };

  const showUpcomingRideHandler = () => {
    setShowPastRides(false);
    get_past_places(0, 0, 1, 1);
  };

  const handleCancelBtn = (requestID) => {
    setCancelModal(true);
    setRequestID(requestID);
  };

  const handlelongRentalHours = () => {
    var selected_hours_id = $("#selected_hour_id option:selected").attr(
      "data-id"
    );
    var select_hour_price = $("#selected_hour_id option:selected").attr(
      "price"
    );
    var advance_payment_amount = $("#selected_hour_id option:selected").attr("advance_payment_amount")

    var select_value = $("#selected_hour_id option:selected").attr("value");
    setSelectedHourId(selected_hours_id);

    setSelectedValue(select_value);
    if (isRentalCar == "1") {
      var fare_long_ride = noOfCarForRide * select_hour_price;
      var selected_advance_fare = advance_payment_amount * noOfCarForRide
      if (noOfCarForRide > 1) {
        setSelectedAdvanceHourPrice(selected_advance_fare)
      } else {
        setSelectedAdvanceHourPrice(selected_advance_fare)
      }
      console.log(noOfCarForRide, selected_advance_fare)
      setSelectedHourPrice(fare_long_ride);
    } else {
      setSelectedAdvanceHourPrice(advance_payment_amount)
      setSelectedHourPrice(select_hour_price);
      console.log(fare_long_ride)

    }
    //   }
  };

  const cancelUpcomingRideHandler = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      // document.getAttr(data_req)
      // console.log(Element.getAttr("data_req_id"))
      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("ride_request_id", requestID);
      // console.log(requestID)

      var res = await removeHistoryApi(form);
      var data = res.data;
      if (data.status == "1") {
        toast.success("Cancelled Ride Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setCancelModal(false);
        get_past_places(0, 0, 1, 1);
      } else {
        toast.success("Error while cancelling ride", {
          position: "top-center",
          autoClose: 1000,
        });
        console.log("Error : error while removing ride", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_long_rental_car_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getLongRentalCarApi(form);
      var data = res.data;
      console.log(data)

      if (data.status == "1") {
        setLongRentalCarData(data.rental_car_hours);
        setLongRentalData(data.rental_car)
        console.log(data)

        // var longRideCarImage = data.rental_car.multi_car_image
        // console.log(longRideCarImage)
        // setLongRideCarImage
      } else {
        console.log("Error :", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fareBreakDownModalHandler = () => {
    setFareBreakDownModal(true);
    get_fare_break_down();
  };

  if (totalPage == 0) {
    $("#pagination").addClass("d-none");
  }

  const dateInputRef = useRef(null);

  const dateOpenHandler = (e) => {
    // dateInputRef.current.showPicker();
    $("#date").removeClass("d-none");
  };

  const RebookHandler = (e, data) => {
    // setReceiptNavigate(false)

    var rebookData = {
      pickup: data.pickup_latitude + "," + data.pickup_longitude,
      destination: data.destination_latitude + "," + data.destination_longitude,
      pickup_address: data.pickup_address,
      destination_address: data.destination_address
      // :,
    };
    e.stopPropagation();
    navigate("/dashboard");
    localStorage.setItem("rebookdata", JSON.stringify(rebookData));
    // console.log(data.pickup_latitude , data.pickup_longitude)
    // console.log(data.destination_latitude , data.destination_longitude)
  };

  const inputRef = useRef();

  const pickupAddressChange = (e) => {
    if (e.target.value == "") {
      // setPickupBlank(true)
      setPickupPosition();
      setRouteDirections();
      setZoom(13);
      setPosition({
        lat: currantPosition?.lat,
        lng: currantPosition?.lng,
        text: "",
      });
    }
  };

  const destinationAddressChange = (e) => {
    if (e.target.value == "") {
      setDestinationPosition({});
      setRouteDirections();
      setPath();
      setPosition({
        lat: currantPosition?.lat,
        lng: currantPosition?.lng,
        text: "",
      });
      setZoom(13);
      setRequestRide(false);
    }
  };

  const fetchAndSetCarTypes = async () => {
    try {
      const allCarTypes = await fetchCarTypes(); // Fetch all car types without filtering
      // console.log(allCarTypes)
      setCarTypes(allCarTypes); // Set the state with all car types
      // Ensure the selected car type persists

    } catch (error) {
      console.error("Error fetching car types:", error);
    }
  };

  // console.log(pickupLocation)

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                var lat = position.coords.latitude;
                var long = position.coords.longitude;
                if (pickupPosition == "") {
                  near_search_location(lat, long).then((data) => {
                    var userLocation = data.user_location_data;
                    inputRef.current.value = userLocation.title_address;
                    setPickupPosition({
                      lat: parseFloat(userLocation.latitude),
                      lng: parseFloat(userLocation.longitude),
                      // text: userLocation.address.suburb,
                      text: near_search_condition(data),
                      /* "Closest ride to you " +
                        userLocation.time_duration +
                        " Min" */ add: userLocation?.title_address,
                    });
                    setPosition({
                      lat: parseFloat(userLocation.latitude),
                      lng: parseFloat(userLocation.longitude),
                      // text: userLocation.address.suburb,
                      // add: userLocation.display_name,
                    });
                    setCurrantPosition({
                      lat: parseFloat(userLocation.latitude),
                      lng: parseFloat(userLocation.longitude),
                      text: userLocation?.title_address,
                      add: userLocation?.title_address,
                    });
                  });
                }
              },
              (error) => {
                console.error("Error getting geolocation:", error);
              },
              { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                var lat = position.coords.latitude;
                var long = position.coords.longitude;
                near_search_location(lat, long).then((data) => {
                  var userLocation = data?.user_location_data;

                  inputRef.current.value = userLocation?.title_address;

                  setPickupPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    text: near_search_condition(data),
                    /* "Closest ride to you " +
                      userLocation.time_duration +
                      " Min" */
                    add: userLocation?.title_address,
                  });
                  setPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    // add: userLocation.display_name,
                  });
                  setCurrantPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    text: userLocation?.title_address,
                    add: userLocation?.title_address,
                  });
                });
                // var url = "http://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lang + "&sensor=true&key=AIzaSyD7ajC4AJpRr3_Yz8e48FsIxHVqvP1RNkQ";
                // var url =
                //   "https://geocode.maps.co/reverse?lat=" +
                //   lat +
                //   "&lon=" +
                //   long +
                //   "&api_key=6617797c87997345174567fer0b83a3";
                // "&api_key=AIzaSyDskRkPZZjm3j9Trw55LGhJEe4AOA1I_fg";
                // fetch(url)
                //   .then((result) => {
                //     if (result.ok) {
                //       console.log(result);
                //       return result.json();
                //     } else {
                //       // alert('something went wrong while fetching location....')
                //       var res = {};
                //       res.display_name = "";
                //       return res;
                //     }
                //   })
                //   .then((result) => {
                //     if (result !== undefined) {
                //       setPickupPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         // text: result.address.suburb,
                //         text: result.display_name,
                //         add: result.display_name,
                //       });
                //       setPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       });
                //       setCurrantPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       });
                //       // setFormData({
                //       //   ...formData,
                //       //   pick_up: result.display_name,
                //       // });
                //     }
                //   })
                //   .catch((err) => console.log(err));
              },
              (error) => {
                console.error("Error getting geolocation:", error);
              },
              { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
            );
            alert('Location permisson issue "prompt"....');
          } else if (result.state === "denied") {
            alert('Location permisson  "denied"....');
          }
          /* result.onchange = function () {
            console.log(result.state);
          }; */
        });
    } else {
      alert("Sorry location not available!");
    }
  };

  const near_search_location = async (lat, lng) => {
    try {
      var data1 = {
        isFromDestination: 0,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        // latitude: pickupPosition ? pickupPosition.lat : position.lat,
        // longitude:pickupPosition ?  pickupPosition.lng : position.lng,
        latitude: lat ? lat : pickupPosition.lat,
        longitude: lng ? lng : pickupPosition.lng,
        title_adress: "",
        car_selection_id: 0,
      };
      let form = new FormData();
      form.append("isFromDestination", data1.isFromDestination);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("latitude", data1.latitude);
      form.append("title_adress", data1.title_adress);
      form.append("longitude", data1.longitude);
      form.append("car_selection_id", data1.car_selection_id);

      var res = await nearSearchLocationApi(form);
      var data = res.data;
      if (data.status == "1") {
        setNearSearchLocation(data);
        return data;
        // pickupPosition.text =
        //   data?.user_location_data?.time_duration + " minutes";
      } else {
        setNearSearchLocation(data);
        console.log("near_search_location", data);
        return data;
      }
    } catch (error) {
      console.log("Error near_search_location", error);
    }
  };

  const handlePickupDrag = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    near_search_location(lat, lng).then((data) => {
      var userLocation = data?.user_location_data;

      inputRef.current.value = userLocation?.title_address;
      var p = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        // text: userLocation.address.suburb,
        text: near_search_condition(data),
        /* data.status == "1"
            ? "Closest ride to you " + userLocation.time_duration + " Min"
            : "" */ add: userLocation?.title_address,
      };
      setPickupPosition(p);
      get_driver();
      if (Object.keys(destinationPosition).length && p) {
        price_calculation(
          pickupPosition.lat,
          pickupPosition.lng,
          lat,
          lng
        ).then((p_r) => {
          var esti_pr =
            parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
          esti_pr = esti_pr.toFixed(3);
          setDestinationPosition({
            ...destinationPosition,
            text: "KD " + esti_pr,
          });
        });
        aftersetDestinationPosition(p, destinationPosition);
      }
    });

    if (pickupPosition && Object.keys(destinationPosition).length) {
      // near_search_location();
      // price_calculation();
    }
    try {
      // const geocoder = new window.google.maps.Geocoder();
      // const latlng = {
      //   lat: parseFloat(lat),
      //   lng: parseFloat(lng),
      // };
      /*  geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            console.log(results[0]);
            var add = results[0].formatted_address;
            inputRef.current.value = add;
            var p = { lat: lat, lng: lng, text: add, add: add };
            setPickupPosition(p);
            if (destinationPosition && p) {
              aftersetDestinationPosition(p, destinationPosition);
            }
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }); */
    } catch (error) {
      console.log(error);
    }
  };
  const near_search_condition = (data) => {
    if (data.status == "1") {
      if (data.isDisableArea == "1") {
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        return "Waiting";
      } else {
        return (
          "Closest ride to you " +
          data?.user_location_data.time_duration +
          " Min"
        );
      }
    } else if (data.status == "0") {
      if (data.isDisableArea == "1") {
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        return "Waiting";
      } else if (
        !(parseInt(data.driver_login_count) > 0) &&
        data.near_by_car_count == "0"
      ) {
        // console.log("hello",!(parseInt(data.driver_login_count) > 0))
        return "Rove doesn't operate here";
      } else if (
        !(parseInt(data.driver_login_count) > 0) &&
        data.free_driver_count == "0"
      ) {
        return "All drivers are busy";
      } else {
        return "Driver not available";
      }
    }
  };

  const DirectionsService = new google.maps.DirectionsService();
  const viewReciept = (data) => {
    // setReceiptNavigate(true)
    // if(receiptNavigate == true){
    navigate("/ride-reciept/" + data.request_id);
    // }
  };

  const handleCardClick = (data) => {
    // Check if the status is '1' (Accepted) or '3' (Started)
    if (data.status === "1" || data.status === "3") {
      // Redirect to the running ride page
      navigate(`/ride-detail/${data.request_id}`);
    } else {
      // Redirect to the trip details page
      navigate(`/ride-reciept/${data.request_id}`);
    }
  };

  // console.log(upcomingData)

  return (
    <>

      <main className="main">
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <section className="section mt-40 mb-100 " id="my-trip">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-1 col-lg-1 "></div>
              <div className="col-md-2 col-lg-2 mb-50">
                <button
                  onClick={showPastRideHandler}
                  className={`btn text-profile ${showPastRides ? "activ" : ""}`}
                >
                  Past Rides
                </button>
                <button
                  onClick={showUpcomingRideHandler}
                  className={`btn text-profile ${!showPastRides ? "activ" : ""
                    }`}
                >
                  Upcoming Rides
                </button>
              </div>
              <div className="col-md-6 col-lg-6 ">
                {showPastRides ? (
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-between mb-30 ">
                      <h2 style={{ fontWeight: "600" }}>Past Rides</h2>
                      <div>
                        <div className="dropdown-afterlogin">
                          <Link
                            className="btn btn-link-globe dashbord-selectme me-2 justify-content-center align-items-center"
                            to="/my-trips"
                          >
                            <img
                              src="/assets/imgs/ride_icons/filter-icon.png"
                              alt="filter-icon"
                            />

                            <span className="ms-3 me-1">{filterText}</span>
                            <i
                              className="fa fa-chevron-down"
                              style={{ fontSize: "12px", marginLeft: "2px" }}
                            ></i>
                          </Link>
                          <div className="dropdown-content trip-history">
                            <Link
                              onClick={() => {
                                filterSelectHandler(0, 0, "All Trips");
                              }}
                            >
                              All Trips
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(4, 0, "Completed");
                              }}
                            >
                              Completed
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(2, 0, "Cancelled");
                              }}
                            >
                              Cancelled
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(0, 1, "Paid");
                              }}
                            >
                              Paid
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(0, 2, "Unpaid");
                              }}
                            >
                              Unpaid
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div className="d-flex justify-content-center align-items-center mt-5 ">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : pastRideData?.length > 0 ? (
                      pastRideData.map((data, index) => (
                        <>
                          <div
                            className="_css-imdnMt mb-5"
                            key={index}
                            data-reqid={data.request_id}
                            onClick={() => {
                              handleCardClick(data);
                            }}
                          >
                            <img className="map_image" src={data.map_image} />

                            <div className="_css-cAseJQ d-flex justify-content-between">
                              <div className=" ">
                                <button
                                  className={`btn btn-rebook-trip btn-primary py-2 `}
                                  onClick={(e) => RebookHandler(e, data)}
                                >
                                  <i className="bi bi-arrow-clockwise "></i>
                                  {/* <i className="fa-solid fa-arrow-rotate-right" style={{ color: 'white' }}></i> */}
                                  ReBook
                                </button>
                              </div>
                              <div
                                className="d-flex w-50"
                                onClick={() => handleCardClick(data)}
                              >
                                <img
                                  src={data.driver_image}
                                  className="dropbtn"
                                  style={{ height: "55px", width: "55px" }}
                                  alt="person"
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop in case default image also fails
                                    e.target.src = "/assets/imgs/profile/default.jpg"; // Replace with the actual URL of the default image
                                  }}
                                />
                                <div className="mx-3">
                                  <h6 className="fw-bold">
                                    {data.driver_name}
                                  </h6>
                                  <p className="fw-bold">
                                    {data.driver_car_name}
                                  </p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-end w-50 mx-3">
                                <div className=" order-2 order-md-1 order-lg-1">
                                  <h6 className="fw-bold">
                                    {/* {data.orignalDate?.toLocaleString()} */}
                                    {data.orignalDate}
                                  </h6>
                                  <p className="fw-bold">KD {data.fare}</p>
                                </div>
                                {/* <div className="order-1 order-md-2  order-lg-3"> */}
                                <button
                                    className={`btn btn-payment-due py-1 d-none`}
                                        id={`btn-payment-due-${data.request_id}`}
                                      >
                                        {/* {data.status_due} */}
                                        {statusDue}
                                      </button>

                                      <button
                                        className={`btn ${data.buttonClass} btn-status-trip border-none py-1`}
                                        id={`btn-payment-sucess-${data.request_id}`}
                                      >
                                       {/* {data.status_text} */}
                                       {statusText}

                                      </button>


                                {/* </div> */}
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={viewReciept}
                            className="_css-imdnMt mb-5"
                          >
                            {/* {showPastRides ? (
                          <div className="_css-cAseJQ d-flex justify-content-between">
                            <div className="d-flex w-50">
                              <img
                                src="/assets/imgs/ride_icons/Rider-image.jpg"
                                className="dropbtn"
                                style={{ height: "55px", width: "55px" }}
                                alt="person"
                              />
                              <div className="mx-3">
                                <h6 className="fw-bold">{data.driver_name}</h6>
                                <p className="fw-bold">Range Rover Sport</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end w-50 mx-3">
                              <div className="order-2 order-md-1 order-lg-1">
                                <h6>Today 04:04 PM</h6>
                                <p>KD 18.606</p>
                              </div>
                              <div className="order-1 order-md-2 order-lg-2">
                                <button className="btn btn-danger py-1 ms-3 disabled">
                                  Cancelled
                                </button>
                              </div>
                            </div>
                          </div>) : <div></div>} */}
                          </div>
                        </>
                      ))
                    ) : (
                      <h4 className="text-center">No rides found...</h4>
                    )}
                    {/* {console.log(totalPage , pastRideData.length)} */}
                    {!isLoading && totalPage != 0 ? (

                      <div
                        style={{ display: "block" }}
                        className="d-flex justify-content-end"
                        id="pagination"
                      >

                        <Pagination
                          activePage={activePage}
                          className={activePage ? "active" : ''}
                          activeClass='active'
                          itemsCountPerPage={limitPerPage}
                          totalItemsCount={totalPage * limitPerPage}
                          pageRangeDisplayed={5}
                          itemclassName="page-item"
                          linkclassName="page-link"
                          onChange={handlePaginationChange}
                        />
                      </div>
                    ) : null
                    }
                  </div>
                ) : isLoading ? (
                  <div className="d-flex justify-content-center align-items-center mt-5 ">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) :
                  upcomingData?.length > 0 ? (
                    upcomingData.map((data, index) => (
                      <div className="row" key={data.request_id}>
                        {/* <div className="col-md-12 d-flex justify-content-between mb-30">
                        <h2 style={{ fontWeight: "600" }}>Upcoming Rides</h2>
                      </div> */}
                        <div className="_css-imdnMt mb-5">
                          <div
                            className="_css-cAseJQ  "
                            data_req_id={data.request_id}
                          >
                            <div className=" col-12 ">
                              <div className="d-flex mx-3 justify-content-between">
                                <h6 className="fw-bold">
                                  {data.upcoming_pickup_time}
                                </h6>
                                {/* <h6 className="fw-bold text-rove">{data.destination_address ? data.fare : 'No Estimated price'}{"  "}KD</h6> */}
                                <h6 className="fw-bold text-rove">
                                  {
                                    data.is_rental_car === "1"
                                      ? `${(data.fare * data.multi_pickup_later_car_count).toFixed(3)} KD`
                                      : data.destination_address
                                        ? data.fare
                                          ? calculateFinalFare(data)
                                          : "No Estimated price"
                                        : "No Estimated price"
                                  }
                                  {/* {c} */}

                                  {/* {data.destination_address
                                  ? data.is_airport_ride === "1"
                                    ? data.fare
                                      ? `${(data.fare * data.multi_pickup_later_car_count).toFixed(3)} KD`
                                      : "No Estimated price"
                                    : data.is_rental_car == "1"
                                      ? `${(data.fare * data.multi_pickup_later_car_count).toFixed(3)} KD`
                                      : `${data.fare} KD`
                                  : "No Estimated price"} */}
                                  {/* { data.multi_pickup_later_car_count , } */}
                                </h6>
                              </div>
                            </div>
                            {/* <hr /> */}
                            <div className="row mt-3 ">
                              <div className="mx-3 col-12 d-flex mb-3">
                                <div className="col-sm-1 col-md-1">
                                  <span>
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      data-baseweb="icon"
                                    >
                                      <title>search</title>
                                      <path
                                        fillRule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm5-2a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                                <div className="col-sm-11-col-md-11">
                                  <p className="mb-0 text-secondary">Pickup</p>
                                  <h6 className="fw-bold text-rove  mb-0">
                                    {data.pickup_address
                                      ? data.pickup_address
                                      : ""}
                                  </h6>
                                </div>
                              </div>
                              {data.destination_address != '' ?
                                <div className="mx-3 col-12 d-flex mb-3">
                                  <div className="col-sm-1 col-md-1">
                                    <span>
                                      <svg
                                        width="30"
                                        height="30"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        data-baseweb="icon"
                                      >
                                        <title>search</title>
                                        <path
                                          fillRule="evenodd"
                                          clip-rule="evenodd"
                                          d="M14 10h-4v4h4v-4ZM7 7v10h10V7H7Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </span>
                                  </div>

                                  <div className="col-sm-11-col-md-11">
                                    <p className="mb-0 text-secondary">
                                      Destination
                                    </p>
                                    <h6 className="fw-bold text-rove  mb-0">
                                      {data.destination_address != 'undefined' || data.destionation_address != ''
                                        ? data.destination_address
                                        : "Add destination."}
                                    </h6>
                                  </div>
                                </div>
                                : ''}
                            </div>
                            <hr />
                            {data.selected_carType_name ? (
                              <>
                                <div className="d-flex ms-4 justify-content-start">
                                  <div className="col-md-12 d-flex justify-content-between">
                                    <h6 className="fw-bold d-flex align-items-center text-rove">
                                      Selected Cars
                                    </h6>
                                    <div className="d-flex col-md-6">
                                      <img
                                        src={data.multi_car_image}
                                        className="dropbtn"
                                        style={{ height: "50px", width: "60px" }}
                                        alt="Car"
                                      />
                                      <h6 className="text-rove fw-bold ml-3 mt-2">
                                        {data.selected_carType_name}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </>
                            ) : data.selected_airport_car_name ? (
                              <>
                                <div className="d-flex ms-4 justify-content-start">
                                  <div className="col-md-12 d-flex justify-content-between ">
                                    <h6 className="fw-bold   d-flex align-items-center text-rove">
                                      Car Type
                                    </h6>
                                    <div className="vertical-line" style={{ marginLeft: '72px' }}></div>
                                    <div className="col-6 d-flex  justify-content-start">
                                      <p className="text-rove fw-bold align-items-center d-flex">
                                        {data.selected_airport_car_name != '' ? data.selected_airport_car_name : data.car_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </>
                            ) : null}

                            {data.is_rental_car == 1 ? (
                              <>
                                <div className="d-flex ms-4  justify-content-start ">
                                  <div className="col-md-12  d-flex justify-content-between">
                                    <h6 className="fw-bold d-flex align-items-center text-rove">
                                      Long Ride Duration
                                    </h6>

                                    <div className="vertical-line"></div>
                                    <div className="col-md-6 d-flex justify-content-between">
                                      <h6 className="text-rove d-flex text-rove fw-bold align-middle align-items-center">
                                        {data.rental_car_hour} hours
                                      </h6>
                                    </div>
                                  </div>
                                  {/* <h6 className="fw-bold text-center">2</h6>   */}
                                </div>
                                <hr />
                              </>
                            ) : (
                              ""
                            )}
                            {data.is_rental_car &&
                              data.multi_pickup_later_car_count > 1 ? (
                              <>
                                <div className="d-flex justify-content-start ms-4">
                                  <div className="col-md-7 d-flex justify-content-between align-items-center">
                                    <h6 className="fw-bold text-rove mb-0"> {/* Ensuring no extra spacing */}
                                      Number of Cars
                                    </h6>
                                    <div className="vertical-line mx-3"></div> {/* Added spacing around the line */}
                                    <div className="d-flex align-items-center">
                                      <i
                                        className="fa-solid fa-car-rear fa-lg"
                                        style={{ color: "#1f3c4b", marginRight: "8px" }}
                                      ></i>
                                      <h6 className="text-rove fw-bold fs-5 mb-0">
                                        {data.multi_pickup_later_car_count}
                                      </h6>
                                    </div>
                                  </div>
                                </div>

                                <hr />
                              </>
                            ) : (
                              ""
                            )}

                            <div className="row ">
                              <div className="col-md-12">
                                <div className="d-flex justify-content-end">
                                  <button
                                    className={`btn btn-primary py-1 ms-3 `}
                                    onClick={() => handleUpdateModal(data)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className={`btn  btn-cancel-trip py-1 ms-3`}
                                    onClick={() =>
                                      // cancle_riderequest(data.request_id)
                                      handleCancelBtn(data.request_id)
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="_css-imdnMt mb-5"
                        // onClick={() => {
                        //   navigate("/dashboard");
                        // }}
                        >
                          {/* <div
                    role="img"
                    aria-label="No upcoming trips"
                    className="_css-iPuekZ"
                  ></div> */}
                          {/* 
                        <MapWithAMarker
                          containerElement={<div style={{ height: `30vh` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          position={position}
                          pickupPosition={pickupPosition}
                          destinationPosition={destinationPosition}
                          routeDirections={routeDirections}
                        /> */}

                          {/* <div className="_css-cAseJQ d-flex justify-content-between">
                          <div className="d-flex w-50">
                            <img
                              src="/assets/imgs/ride_icons/car_type_economy.png"
                              className="dropbtn"
                              style={{ height: "50px", width: "60px" }}
                              alt="person"
                            />
                            <div className="mx-3">
                              <h6 className="fw-bold">Selected car</h6>
                              <p className="fw-bold">Economy</p>
                            </div>
                            <div className="mx-3">
                              <h6 className="fw-bold">Long ride duration</h6>
                              <p className="fw-bold">4 hours</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end w-50 mx-3">
                            <div className="order-2 order-md-1 order-lg-1">
                              <h6>Today 04:04 PM</h6>
                              <p>KD 18.060</p>
                            </div>
                            <div className="d-flex flex-column gap-1 order-1 order-md-2 order-lg-2">
                              <button
                                className={`btn btn-secondary py-1 ms-3 disabled`}
                              >
                                Update
                              </button>
                              <button
                                className={`btn  btn-danger py-1 ms-3 disabled`}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center fw-bold">
                      There is no upcoming ride..
                    </div>
                  )}
              </div>
              <div className="col-md-3 col-lg-3 px-5 trip-last-section">
                <img
                  // src="/assets/imgs/icons/my-trip-2.svg"
                  src="/assets/imgs/bg/cars2.jpg"
                  alt="my-trip-2"
                  className="mb-3"
                />
                <h6>Get a ride in minutes</h6>
                <p>
                  Book an Rove from a web browser, no app install necessary.
                </p>
                <div className="mt-20 w-70">
                  <Link
                    className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                    to="/dashboard"
                  >
                    Request a Ride
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* 
      <Modal show={payInAdvanceModal} onHide={showPayInAdvanceModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-rove">Long Rides</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">Payment in Advance?</div>
        </Modal.Body>
        <Modal.Footer className="">
          <Button className="col-md-3  btn-cancel-trip" onClick={showPayInAdvanceModal}>
            Reject
          </Button>
          <Button className="col-md-3" onClick={payInAdvanceHandler} >
            Accept
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={cancelModal} onHide={cancelModalHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-rove">Rove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">Are you sure you want to cancel your ride?</div>
        </Modal.Body>
        <Modal.Footer className="">
          <Button className="col-md-3 " onClick={cancelModalHandler}>
            No
          </Button>
          <Button className="col-md-3" onClick={cancle_riderequest}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={paymentDueModal} onHide={paymentModalHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Top up your wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="row d-flex justify-content-between mb-3 p-3 "
            style={{
              background: "rgb(233 192 196 / 49%)",
              borderRadius: "5px",
            }}
          >
            <div className="col-md-12  ">
              {/* <img
                  src="/assets/imgs/icons/wallet@2x.png"
                  alt="wallet@2x"
                  width={25}
                /> */}
              <p
                className=" ms-2 fw-bold fs-6 mb-0"
                style={{ color: "#e3666" }}
              >
                Please top up you wallet by KD {walletDiff} to process with this
                booking.
              </p>
            </div>
          </div>
          <div className="my-3">
            <h6 className="text-rove my-3 fw-bold">Select Payment Method</h6>

            {cardDetail ?
              <div
                className={`row d-flex justify-content-between mb-3 p-2  ${selectPayMode == 0 ? "select" : ""
                  }`}

                // style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                // onClick={() => { selectedCardTopup(); setPaymentDueModal(false); }}
                onClick={() => setSelectPayMode(0)}
              >
                <div className="col-md-6 d-flex align-items-center">
                  <img
                    src={cardDetail.card_brand == "VISA"
                      ? "/assets/imgs/icons/visa@2x.png"
                      : "/assets/imgs/icons/ic_mastercard-2.png"}
                    alt="ic_mastercard-2@2x"
                    width={30}
                  />
                  <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                    {`#### ${cardDetail.card_number}`}
                  </p>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end">
                  <p className=" me-2 fw-bold fs-6 mb-0">
                    Expires in : {cardDetail.expire_date}
                  </p>
                </div>
              </div>
              :
              <div
                className={`row d-flex justify-content-between mb-3 p-2  ${selectPayMode == 1 ? "select" : ""
                  }`}
                style={{ cursor: "pointer", border: "1px solid rgb(190 188 188)" }}
                onClick={() => setSelectPayMode(1)}

              // onClick={() => { setForCreditCardShow(true); setPaymentDueModal(false); }}
              >
                <div className="col-md-6 d-flex align-items-center">
                  <img
                    src={"/assets/imgs/icons/card@2x.png"}
                    alt="card@2x"
                    width={30}
                  />
                  <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                    {"Add Credit Card"}
                  </p>
                </div>
              </div>}
            <div
              className={`row d-flex justify-content-between mb-3 p-2 ${selectPayMode == 2 ? "select" : ""
                }`}
              onClick={() => setSelectPayMode(2)}
            >
              <div className="col-md-12 d-flex align-items-center">
                <p className="ms-2 fw-bold fs-6 mb-0">KNET</p>
              </div>
            </div>
            {/* <div className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode==2?"select":""}`}  onClick={()=>setSelectPayMode(2)}>
                <div className="col-md-12 d-flex align-items-center">
                  <p className="ms-2 fw-bold fs-6 mb-0">
                    Apple Pay
                  </p>
               </div>
            </div> */}
          </div>
          {/* <div className="my-3">
            <h6 className="fw-bold text-rove my-2">
              Select Payment method
            </h6>
            <div className="col-md-12 mt-3">
              <div className="bg-light top_up_wallet_div d-flex justify-content-between align-items-center">
                <input type="radio" id="creditCard" name="paymentMethod" value="CreditCard" />
                <label htmlFor="creditCard" className="fw-bold">Add Credit Card</label>
              </div>
              <div className="mt-3 top_up_wallet_div d-flex align-items-center">
                <input type="radio" id="knet" name="paymentMethod" value="KNET" />
                <label htmlFor="knet" className="fw-bold">KNET</label>
              </div>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer className="col-md-12">
          <button className="btn btn-primary  mx-3 w-100" onClick={() => paymentHandler(requestID)}>
            Proceed to Pay {"  "}
            KD {walletDiff}
          </button>
          <button className="btn btn-primary btn-cancel-trip mx-3 w-100" onClick={(() => setPaymentDueModal(false))}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={fareBreakDownModal} onHide={fareModal}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ">Fare Breakdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="col-md-12"> */}
          {/* <hr /> */}
          <div className="text-center my-3 mb-3">

            {isRentalCar == 1 && longRentalCarName &&
              <>
                <img className="" src={longRentalDataImage ? longRentalDataImage.replace(
                  "https://rovemain.rovesolution.com/uploads",
                  "https://www.riderove.com/dev_rove/uploads") : ''}
                  alt="car type"
                  width="100px"

                />
                <h6 className="mt-3 mb-0 fw-bold">
                  {longRentalCarName}   &nbsp;&nbsp;
                  <span title="Seating capacity ms-2">
                    <img
                      src="/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"
                      alt="Selected_SeatingCapicity_cartype"
                      width="15px"
                    />
                    &nbsp;{longRentalCarCapacity} &nbsp;
                  </span>
                </h6>
              </>}

          </div>


          {carFareBreakdown?.length > 0
            ? carFareBreakdown.map((data) => (
              <>
                <div className="d-flex justify-content-between">

                  <label className="fw-bold">{data.title}</label>

                  <span className="text-rove fw-bold">KD {data.price}</span>
                </div>
                <hr />
              </>
            ))
            : ""}
          {/* <hr /> */}
          {/* <div className="d-flex justify-content-between">
            <label className="fw-bold">Fare per 10 mins after 4 hours</label>
            <span className="text-rove fw-bold">KD 1.500</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <label className="fw-bold">Waiting Charge after 10 mins</label>
            <span className="text-rove fw-bold">KD 2.000</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <label className="fw-bold">Cancellation fee</label>
            <span className="text-rove fw-bold">KD 2.000</span>
          </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>



      {userProfileData && userProfileData.user_profile && <Modal size="lg" show={forCreditCardShow} onHide={handleForCreditCardClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TapCard
            scope={Scope.TOKEN}
            operator={{
              publicKey: tap_public
            }}
            transaction={{
              reference: '',
              paymentAgreement: {
                id: '',
                contract: {
                  id: ''
                }
              }
            }}
            order={{
              id: '',
              amount: 1,
              currency: Currencies.KWD,
              description: '',
              reference: '',
              metadata: {}
            }}
            invoice={{
              id: ''
            }}
            customer={{
              id: userProfileData?.user_profile.tap_customer_id,
              name: [
                {
                  lang: Locale.EN,
                  first: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[0] : '',
                  last: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[1] : '',
                  middle: ''
                }
              ],
              nameOnCard: userProfileData?.user_profile.name_eng,
              editable: true,
              contact: {
                email: userProfileData?.user_profile.user_email,
                phone: {
                  countryCode: userProfileData?.user_profile.country_code.replace('+', ''),
                  number: userProfileData?.user_profile.mobileno
                }
              }
            }}
            merchant={{
              id: ''
            }}
            interface={{
              locale: Locale.EN,
              cardDirection: Direction.DYNAMIC,
              edges: Edges.CURVED,
              theme: Theme.DYNAMIC,
              powered: true,
              colorStyle: ColorStyle.COLORED,
              loader: true
            }}
            features={{
              acceptanceBadge: true,
              customerCards: {
                saveCard: false,
                autoSaveCard: false,
              }
            }}
            fieldsVisibility={{
              card: {
                cardHolder: true
              }
            }}
            acceptance={{
              supportedSchemes: ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
              supportedFundSource: ['CREDIT', 'DEBIT'],
              supportedPaymentAuthentications: ['3DS']
            }}
            post={{
              url: "https://tap.company"
            }}
            onReady={() => console.log('onReady')}
            onFocus={() => console.log('onFocus')}
            onBinIdentification={(data) => console.log('onBinIdentification', data)}
            onValidInput={(data) => setCreateEnabled(data)}
            onError={(data) => console.log('onError', data)}
            onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
            onSuccess={(data) => { handleCardToken(data) }}
          />
          <button className='btn mt-3 token_button w-100' onClick={() => {
            tokenize()
            setUpdateModal()

          }
          }
            disabled={!createEnabled}>
            Save Card
          </button>
        </Modal.Body>
      </Modal>}

      {isLoadingUpdateBtn ?
        <Spinner
          as="span"
          animation="border"
          size="sm"
          style={{ background: 'white' }}
          role="status"
          aria-hidden="true"
          className="me-2"
        /> :

        <Offcanvas show={updateModal} onHide={updateModalHandler} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {isRentalCar == 0 ? "Pickup Later" : "Long Rides"}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="search-item search-from mb-3 " id="location-pickup">
              <label>
                <input
                  type="text"
                  className="form-control"
                  id="Location1"
                  placeholder="Pickup location"
                  ref={inputRef}
                  // defaultValue={pickupLocation}
                  // value={pickupLocation}
                  // value={pickupPosition.text}
                  name="pickup"
                  onChange={pickupAddressChange}
                />
              </label>
            </div>
            <div
              className={`search-item search-from ${noOfCars ? "d-none" : ""}`}
              id="location-destination"
            >
              <label>
                <input
                  type="text"
                  className="form-control"
                  id="Location2"
                  placeholder="Dropoff location"
                  ref={inputRef2}
                  name="destination"
                  onChange={destinationAddressChange}
                />
              </label>
            </div>
            <hr />

            <div className="form-group mt-3">
              {/* <i class="fa-regular fa-calendar ms-3"></i> */}
              {/* <label htmlFor="date" className="fw-bold text-rove mx-3" onClick={dateOpenHandler}>25 Jun 2024 05:00 Am</label>
              <input 
                type="date"
                name="date" 
                id="date"
                className=" w-100"
                /> */}
              <DatePicker
                showIcon
                icon="fa fa-calendar"
                toggleCalendarOnIconClick
                // isClearable
                selected={startDate}
                minDate={new Date()}
                maxDate={maxDate}
                withPortal
                className="form-control"
                showTimeSelect
                timeFormat="p"
                timeIntervals={10}
                // timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                // showTimeInput
                placeholderText="Enter date & time"
                onChange={handleDateChange}
              />
            </div>
            {isRentalCar == 1 ? (
              <>
                <hr />
                <label className="text-rove fw-bold col-md-6">
                  Select Hours
                  <i class="fa-regular ms-2 fa-clock"></i>
                </label>
                {/* <select defaultValue={rentalCarHour ? rentalCarHour : 4} className="select col-md-6"> */}
                <select
                  className="select col-md-6"
                  id="selected_hour_id"

                  onChange={handlelongRentalHours}
                >
                  {longRentalCarData.length > 0 ? (
                    longRentalCarData.map((data) => (
                      <option
                        price={data.hours_price}
                        value={data.hours_value}
                        advance_payment_amount={data.advance_payment_amount}
                        selected={rentalCarHour == data.hours_value}
                        key={data.id}
                        data-id={data.id}
                      >
                        {data.hours_name}
                      </option>
                    ))
                  ) : (
                    <div>Loading..</div>
                  )}
                </select>

                <hr />
                <label className="fw-bold col-md-6">Total Price</label>
                <span className="col-md-6 text-rove  fw-bold">
                  KD {selectedHourPrice}
                </span>
                <i
                  class="fa-solid fa-circle-info mx-2"
                  onClick={fareBreakDownModalHandler}
                  style={{ color: "#1f3c4b" }}
                ></i>
              </>
            ) : (
              ""
            )}

            {/* <DateTimePicker
            label="Controlled picker"
            value={value}
            onChange={(newValue) => setValue(newValue)}
          /> */}

            {isRentalCar == 0 && (
              <div className="form-group mt-1">

                <hr />
                <i class="fa-solid fa-car-rear  ms-3"></i>
                <label htmlFor="date" className="fw-bold text-rove mx-3">
                  Selected Cars
                </label>
                <i
                  class="fa-solid fa-minus mx-3 p-2"
                  style={{ color: "#ffffff", backgroundColor: "#1f3c4b" }}
                  onClick={decrease}
                ></i>
                <span className="fw-bold text-rove">{number}</span>
                <i
                  class="fa-solid fa-plus mx-3 p-2"
                  style={{ color: "#ffffff", backgroundColor: "#1f3c4b" }}
                  onClick={increase}
                ></i>
                <button className="" hidden></button>
              </div>
            )}

            {
              selectedCarType != '' && selectedCarType != "undefined" && isRentalCar == 1 ?
                <div className="form-group mt-1">

                  <hr />
                  <i class="fa-solid fa-car-rear  ms-3"></i>
                  <label htmlFor="date" className="fw-bold text-rove mx-3 mb-3">
                    CarType
                  </label>

                  <select
                    className="select form-control"
                    style={{ cursor: 'pointer' }}
                    // value={SelectedAirportCarName}
                    disabled={isRentalCar == "1"}
                  >
                    <option>{SelectedAirportCarName}</option>
                  </select>

                  <button className="" hidden></button>
                </div> : SelectedAirportCarName != '' ?

                  <div className="form-group mt-1">

                    <hr />
                    <i class="fa-solid fa-car-rear  ms-3"></i>
                    <label htmlFor="date" className="fw-bold text-rove mx-3 mb-3">
                      CarType
                    </label>
                    <select
                      className="select form-control"
                      style={{ cursor: 'pointer' }}
                      onChange={handleCarTypeChange}
                      value={selectedCarType}
                      disabled={isRentalCar === "1"}
                    >
                      {carTypes && carTypes.map((car) => (
                        <option key={car.id} value={car.id}>
                          {car.display_name}
                        </option>
                      ))}
                    </select>

                    <button className="" hidden></button>
                  </div>
                  : <></>
            }


            <hr />
            <div>
              <button className="btn btn-cancel-trip w-45 mx-2 " disabled={isLoadingUpdateBtn} onClick={updateModalHandler}>Cancel</button>
              {/* <button className="btn btn-primary w-45 mx-2" id="updateRideBtn" onClick={() => handleShowModal(() => updateUpcomingHandler(requestID))}>Update</button> */}
              <button
                className="btn btn-primary w-45 mx-2 "
                id="updateRideBtn"
                onClick={() => updateUpcomingHandler(requestID)}
                disabled={isLoadingUpdateBtn} // Disable button while loading
              >
                {isLoadingUpdateBtn ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    style={{ background: 'white' }}
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                ) : (
                  "Update"
                )}
                {isLoadingUpdateBtn && "Updating..."}
              </button>


              <ConfirmationModal
                show={showModal}
                handleClose={handleCloseModal}
                handleConfirm={handleConfirmAction}
                message={`As per your selection, we will assign ${number} car for this trip.`}
              />
            </div>
            <div>
              {/* <button
              className="btn btn-cancel-trip w-45 mx-2 "
              onClick={updateModalHandler}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary w-45 mx-2"
              id="updateRideBtn"
              onClick={() => updateUpcomingHandler(requestID)}
            >
              {" "}
              Update
            </button> */}
            </div>
            <div></div>

            {/* <div className="mt-3">
            {position && (
              <MapWithAMarker
                containerElement={<div style={{ height: `80vh` }} />}
                mapElement={<div style={{ height: `50%` }} />}
                zoom={zoom}
                pickupPosition={pickupPosition}
                position={position}
                destinationPosition={destinationPosition}
                handlePickupDrag={handlePickupDrag}
                routeDirections={routeDirections}
                driverPositions={
                  getDriverDetail ? getDriverDetail.drivers : null
                }
                path={path}
              />
            )}
          </div> */}
          </Offcanvas.Body>
        </Offcanvas>

      }
      <Modal show={minimumBalanceModal} onHide={handleCloseMinimumBalanceModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-rove" >Minimum Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="row d-flex justify-content-between mb-3 p-3 "
            style={{
              background: "rgb(233 192 196 / 49%)",
              borderRadius: "5px",
            }}
          >
            <div className="col-md-12  ">
              <h5
                className="d-flex justify-content-between  fw-bold  mb-0"
                style={{ color: "#a51a1a" }}
              >
                <span>Wallet Balance </span>
                <span style={{ marginLeft: "5px" }}>KD {parseFloat(userWalletAmount)}.00</span>
              </h5>
            </div>
          </div>
          <div className="">
            <p className="text-rove fs-5" style={{ color: "#e3666" }}>
              Your wallet balance is insufficient!
              You have requested to book 2 cars. You must maintain a minimum wallet balance of KD 10.000.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="col-12">
          <button className="btn btn-primary  mx-3 w-100" onClick={() => navigate("/wallet")}>
            Proceed to Pay {"  "}
            KD {walletDiff}
          </button>
          <button className="btn btn-primary btn-cancel-trip mx-3 w-100" onClick={(() => setMinimumBalanceModal(false))}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyTrips;
