import React, { useState, useEffect } from 'react'
import Sidebar from '../common/Sidebar'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { contactUsApi, createFeedback, feedbackApi, getProfileDataApi } from "../../api/api"
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';


const ContactsUs = () => {
    const location = useLocation();
    const [userDetail, setUserDetail] = useState(() => {
        var user = localStorage.getItem("user_data");
        return user ? JSON.parse(user) : null;
    }); const [userEmail, setUserEmail] = useState();
    const [userNumber, setUserNumber] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        number: "", // Optional: Add number if you plan to include it
    });
    
    const navigate = useNavigate();

    useEffect(() => {
        if (userDetail) {
            get_profile_data();
            const user_email = localStorage.getItem('user_email')
            setUserEmail(user_email)
            const user_number = localStorage.getItem('user_number')
            setUserNumber(user_number)
            //   if (ctx.enableFeaturesList) {
            //     setEnableFeaturesList((ctx.enableFeaturesList))
            //   }
            setInterval(() => {
                // get_profile_data();  // comment for dev mode only
            }, 10000)
        }
    }, [userDetail]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Run both APIs concurrently
            const [contactUsResponse, feedbackResponse] = await Promise.all([
                contact_us(),
                createFeedback(),
            ]);

            console.log("Contact Us Response:", contactUsResponse);
            console.log("Feedback Response:", feedbackResponse);
            setFormData({
                message: '', // Clear message after submission
            });
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const contact_us = async (e) => {
        try {
            var data1 = {
                user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
                user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
            };



            var form = new FormData();
            form.append("user_type", data1.user_type);
            form.append("user_id", data1.user_id);
            form.append("email", formData.email);
            form.append("message", formData.message);
            form.append("number", userNumber);
            form.append("name", formData.name);


            setIsLoading(true)
            const res = await contactUsApi(form); // Replace `contactUsApi` with actual API function
            console.log(res.data.status)
            if (res.data.status == 1) {
                toast("Thank you, We will contact you shortly.", {
                    position: "top-center",
                    type: "success",
                    autoClose: 2000,
                    closeOnClick: true,
                });
            }
            setIsLoading(false)
            return res.data;

        } catch (error) {
            console.log(error);
        }
    };

    const createFeedback = async () => {
        try {
            const title = `Name: ${userDetail.name_eng},\nmobile: ${userNumber},\nemail: ${formData.email}`;

            const feedbackData = {
                user_type: "Customer",
                user_id: "65ae288e0fe07f23a02acb4d",
                name: userDetail.name_eng || '',
                email: formData.email || '',
                message: formData.message || '',
                title: title,
                number: userNumber
            };

            console.log(feedbackData);  // Log the data for debugging

            // Send data as JSON and set the Content-Type header
            const res = await axios.post("https://api.rovesolution.com/ticket/createFeedback/", feedbackData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return res.data;  // Handle the response
        } catch (error) {
            console.error("Error in Feedback API:", error);
            throw error;  // Propagate the error for further handling
        }
    };


    const get_profile_data = async () => {
        try {
            var data1 = {
                user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
                user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
            };

            var form = new FormData();
            form.append("user_type", data1.user_type);
            form.append("user_id", data1.user_id);

            var res = await getProfileDataApi(form);
            var data = res.data;
            if (data.status == "1") {
                setUserProfileData(data);
            } else {
                if (data.message == "Invalid Auth token") {
                    localStorage.clear()
                    setUserDetail();
                    navigate("/login")
                }
                console.log("Error : get profile data", data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const generateMailLink = () => {

        const userAgent = navigator.userAgent;
        const isMac = userAgent.includes("Macintosh");
        const isWindows = userAgent.includes("Windows");

        const email = "info@riderove.com";
        const subject = "Rove Inquiry";
        const body = `Name: ${userDetail.name_eng || ""}%0AMobile: ${userNumber || ""}%0AEmail: ${userDetail.user_email || ""}%0AMessage: ${formData.message || ""}`;

        if (isMac) {
            return `mailto:${email}?subject=${subject}&body=${body}`;
        } else if (isWindows) {
            // Forcing Gmail on Windows
            return (`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`);
        } else {
            // Default fallback for other systems
            return `mailto:${email}?subject=${subject}&body=${body}`;
        }
    }


    return (
        <main className="main">
            <section className="section mt-40 mb-100" id="about-us">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-md-1 col-lg-1 "></div>
                        <div className=" col-md-2 col-lg-2 mb-50">
                            <Sidebar pathname={location.pathname}></Sidebar>
                        </div>
                        <div className="col-md-6 col-lg-6 px-5 mb-50">
                            <div
                                // style={{ height: "80vh" }}
                                className="content d-flex flex-column justify-content-between align-items-center"
                            >   
                            {isLoading ? (
                                <div className="d-flex justify-content-center align-items-center mt-5 ">
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                              </div>
                            ) :
                                <div className="container ">
                                    <h2 className="mb-4">Contact Us</h2>
                                    <div className="row">
                                        <div className="offset-md-2 col-md-8 mb-3">
                                            <div className="d-flex justify-content-between">
                                                <Link to="tel:+965181288" style={{ textDecoration: 'none' }} >
                                                    <img src='/assets/imgs/icons/call/call-user@2x.png' className="btn btn-outline-secondary mb-3 p-3" style={{ borderRadius: '50%' }} />
                                                    <p className='text-rove fw-bold text-center' >Voice Call</p>
                                                    {/* <i className="bi bi-telephone-fill me-2" /> Voice Call */}
                                                </Link>
                                                <Link
                                                    to={generateMailLink()}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <img src='/assets/imgs/icons/mail/mail@2x.png' className="btn btn-outline-secondary mb-3 p-3" style={{ borderRadius: '50%' }} />
                                                    <p className='text-rove fw-bold text-center'>Mail</p>

                                                    {/* <i className="bi bi-whatsapp me-2" /> WhatsApp */}
                                                </Link>
                                                <Link
                                                    to="https://web.whatsapp.com/send?phone=96552559653&text=Hello"
                                                    // to="https://wa.me/96552559653?text=Hello"

                                                    target='_blank'
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <img src='/assets/imgs/icons/whatsapp/whatsapp@2x.png' className="btn btn-outline-secondary mb-3 p-3" style={{ borderRadius: '50%' }} />
                                                    <p className='text-rove fw-bold text-center' >Whatsapp</p>

                                                    {/* <i className="bi bi-envelope-fill me-2" /> Email */}
                                                </Link>
                                            </div>
                                        </div>
                                        <p className='fw-bold text-gray mb-3'>
                                            Or use the form below to reach us. <br />
                                            We will get in touch as soon as possible
                                        </p>
                                        <div className="col-md-12">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Name"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            required

                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <textarea
                                                        className="form-control"
                                                        rows={3}
                                                        placeholder="Message"
                                                        name="message"
                                                        value={formData.message}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-primary w-100">
                                                    Send
                                                </button>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                           }



                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 px-5 trip-last-section">
                            <img
                                // src="/assets/imgs/icons/my-trip-2.svg"
                                src="/assets/imgs/bg/cars2.jpg"
                                alt="my-trip-2"
                                className="mb-3"
                            />
                            <h6>Get a ride in minutes</h6>
                            <p>Book an Rove from a web browser, no app install necessary.</p>
                            <div className="mt-20 w-70">
                                <Link
                                    className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                                    to="/dashboard"
                                >
                                    Request a Ride
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </main>
    )
}

export default ContactsUs